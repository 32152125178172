import { defineSystem, getComponentValue, getComponentValueStrict, Has, UpdateType } from "@latticexyz/recs";
import { StructureTypes } from "../../../../Network";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

/**
 * The Appearance system handles setting textures of phaser game objects based on their Appearance component
 */
export function createAppearanceSystem(layer: PhaserLayer) {
  const {
    world,
    components: { Appearance },
    scenes: {
      Main: { objectPool, config },
    },
    parentLayers: {
      local: {
        components: { LocalPosition },
      },
      network: {
        components: { StructureType },
      },
    },
  } = layer;

  defineSystem(world, [Has(Appearance), Has(LocalPosition)], (update) => {
    const { entity, type } = update;
    if (type === UpdateType.Enter) {
      const appearance = getComponentValueStrict(Appearance, entity);
      const sprite = config.sprites[appearance.value as Sprites];
      const embodiedEntity = objectPool.get(entity, "Sprite");

      const structureType = getComponentValue(StructureType, entity)?.value;

      // Render the actual item sprites on the ground
      if (structureType === StructureTypes.Container) {
        return;
      }

      embodiedEntity.setComponent({
        id: Appearance.id,
        once: (gameObject) => {
          gameObject.setTexture(sprite.assetKey, sprite.frame);
        },
      });
    }
  });
}
