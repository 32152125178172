import { PhaserLayer } from "../../types";
import {
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  HasValue,
  Not,
  runQuery,
} from "@latticexyz/recs";
import { WorldCoord } from "@latticexyz/phaserx/dist/types";
import { registerCameraControls } from "./registerCameraControls";
import { registerClicks } from "./registerClicks";
import { registerHotkeys } from "./registerHotkeys";
import { registerHoverIcon } from "./registerHoverIcon";
import { registerDevModeToggle } from "./registerDevModeToggle";

export type InputUtils = {
  getSelectedEntity: () => EntityIndex;
  getHighlightedEntity: () => EntityIndex;
  getHoverPosition: () => WorldCoord | undefined;
};

export function createInputSystem(layer: PhaserLayer) {
  const {
    components: { HoverHighlight },
    parentLayers: {
      network: {
        components: { TerrainType, Zone },
      },
      local: {
        singletonEntity,
        components: { Selected, LocalPosition },
      },
    },
  } = layer;

  const inputUtils: InputUtils = {
    getSelectedEntity: () => [...runQuery([Has(Selected)])][0],
    getHighlightedEntity: () => {
      const hoverHighlight = getComponentValueStrict(HoverHighlight, singletonEntity);
      const highlightedEntity = [
        ...runQuery([
          HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }),
          Not(TerrainType),
          Not(Zone),
        ]),
      ][0];

      return highlightedEntity;
    },
    getHoverPosition: () => {
      const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
      if (!hoverHighlight) return;
      if (!hoverHighlight.x || !hoverHighlight.y) return;

      return {
        x: hoverHighlight.x,
        y: hoverHighlight.y,
      };
    },
  };

  registerDevModeToggle(layer);

  registerCameraControls(layer);
  registerClicks(layer, inputUtils);
  registerHotkeys(layer, inputUtils);
  registerHoverIcon(layer, inputUtils);
}
