import {
  Component,
  ComponentValue,
  createIndexer,
  createWorld,
  defineComponent,
  defineRxSystem,
  EntityID,
  EntityIndex,
  getComponentEntities,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  runQuery,
  Schema,
  Type,
} from "@latticexyz/recs";
import {
  definePositionComponent,
  defineMovableComponent,
  defineOwnedByComponent,
  defineUntraversableComponent,
} from "./components";
import { setupContracts } from "./setup";
import { BigNumber } from "ethers";
import { keccak256 } from "@latticexyz/utils";
import { WorldCoord } from "../../types";
import { SetupContractConfig } from "./setup/setupContracts";
import { LOCAL_CHAIN_ID } from "../../constants";
import { defineStringComponent, getGameConfig } from "@latticexyz/std-client";
import { DecodedSystemCall, ItemTypes } from "./types";
import { merge } from "rxjs";
import { Coord } from "@latticexyz/phaserx";
import { manhattan } from "../../utils/distance";

export type NetworkLayerConfig = {
  worldAddress: string;
  privateKey: string;
  chainId: number;
  jsonRpc: string;
  wsRpc?: string;
  checkpointUrl?: string;
  devMode: boolean;
  initialBlockNumber: number;
};

/**
 * The Network layer is the lowest layer in the client architecture.
 * Its purpose is to synchronize the client components with the contract components.
 */
export async function createNetworkLayer(config: NetworkLayerConfig) {
  // World
  const world = createWorld();

  //Config
  console.log("Network config", config);

  // Components
  const components = {
    GameConfig: defineComponent(
      world,
      { startTime: Type.String, turnLength: Type.String, actionCooldownLength: Type.String, freeSpawn: Type.Number },
      { id: "GameConfig", metadata: { contractId: "mudwar.component.GameConfig" } }
    ),
    Loading: defineComponent(
      world,
      { state: Type.Number, msg: Type.String, percentage: Type.Number },
      { id: "Loading", metadata: { contractId: "component.LoadingState" } }
    ),
    Components: defineStringComponent(world, {
      id: "Components",
      metadata: { contractId: "world.component.components" },
    }),
    Systems: defineStringComponent(world, {
      id: "Systems",
      metadata: { contractId: "world.component.systems" },
    }),
    UnitType: defineComponent(
      world,
      { value: Type.Number },
      { id: "UnitType", metadata: { contractId: "mudwar.component.UnitType" } }
    ),
    StructureType: defineComponent(
      world,
      { value: Type.Number },
      { id: "StructureType", metadata: { contractId: "mudwar.component.StructureType" } }
    ),
    ItemType: defineComponent(
      world,
      { value: Type.Number },
      { id: "ItemType", metadata: { contractId: "mudwar.component.ItemType" } }
    ),
    TerrainType: defineComponent(
      world,
      { value: Type.Number },
      { id: "TerrainType", metadata: { contractId: "mudwar.component.TerrainType" } }
    ),
    Position: createIndexer(definePositionComponent(world, "mudwar.component.Position")),
    Movable: defineMovableComponent(world, "mudwar.component.Movable"),
    MovementDifficulty: defineComponent(
      world,
      { value: Type.Number },
      {
        id: "MovementDifficulty",
        metadata: { contractId: "mudwar.component.MovementDifficulty" },
      }
    ),
    OwnedBy: createIndexer(defineOwnedByComponent(world, "mudwar.component.OwnedBy")),
    PoweredBy: defineComponent(
      world,
      { value: Type.Entity },
      { metadata: { contractId: "mudwar.component.PoweredBy" } }
    ),
    Untraversable: defineUntraversableComponent(world, "mudwar.component.Untraversable"),
    Player: defineComponent(
      world,
      { value: Type.Number },
      { id: "Player", metadata: { contractId: "mudwar.component.Player" } }
    ),
    Stamina: defineComponent(
      world,
      { current: Type.Number, max: Type.Number, regeneration: Type.Number },
      { id: "Stamina", metadata: { contractId: "mudwar.component.Stamina" } }
    ),
    StaminaRegenerationCap: defineComponent(
      world,
      { totalRegenerated: Type.Number, cap: Type.Number },
      { id: "StaminaRegenerationCap", metadata: { contractId: "mudwar.component.StaminaRegenerationCap" } }
    ),
    LastAction: defineComponent(
      world,
      { value: Type.String },
      { id: "LastAction", metadata: { contractId: "mudwar.component.LastAction" } }
    ),
    Combat: defineComponent(
      world,
      {
        _type: Type.Number,
        strength: Type.Number,
        health: Type.Number,
        counterStrength: Type.Number,
      },
      { id: "Combat", metadata: { contractId: "mudwar.component.Combat" } }
    ),
    RangedCombat: defineComponent(
      world,
      {
        strength: Type.Number,
        minRange: Type.Number,
        maxRange: Type.Number,
      },
      { id: "RangedCombat", metadata: { contractId: "mudwar.component.RangedCombat" } }
    ),
    CombatStrength: defineComponent(
      world,
      {
        combatTypeStrengthBonuses: Type.NumberArray,
      },
      { id: "CombatStrength", metadata: { contractId: "mudwar.component.CombatStrength" } }
    ),
    CombatStrengthModifier: defineComponent(
      world,
      {
        value: Type.Number,
      },
      { id: "CombatStrengthModifier", metadata: { contractId: "mudwar.component.CombatStrengthModifier" } }
    ),
    PrototypeCopy: defineComponent(
      world,
      { value: Type.Entity },
      { id: "PrototypeCopy", metadata: { contractId: "mudwar.component.PrototypeCopy" } }
    ),
    Prototype: defineComponent(
      world,
      { value: Type.StringArray },
      { id: "Prototype", metadata: { contractId: "mudwar.component.Prototype" } }
    ),
    Factory: defineComponent(
      world,
      { prototypeIds: Type.StringArray, staminaCosts: Type.NumberArray },
      { id: "Factory", metadata: { contractId: "mudwar.component.Factory" } }
    ),
    Capturable: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "Capturable", metadata: { contractId: "mudwar.component.Capturable" } }
    ),
    SpawnPoint: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "SpawnPoint", metadata: { contractId: "mudwar.component.SpawnPoint" } }
    ),
    Inventory: defineComponent(
      world,
      { value: Type.Number },
      { id: "Inventory", metadata: { contractId: "mudwar.component.Inventory" } }
    ),
    ResourceGenerator: defineComponent(
      world,
      { value: Type.String },
      { id: "ResourceGenerator", metadata: { contractId: "mudwar.component.ResourceGenerator" } }
    ),
    EscapePortal: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "EscapePortal", metadata: { contractId: "mudwar.component.EscapePortal" } }
    ),
    Winner: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "Winner", metadata: { contractId: "mudwar.component.Winner" } }
    ),
    Portal: defineComponent(
      world,
      { targetIds: Type.StringArray, radius: Type.Number },
      { id: "Portal", metadata: { contractId: "mudwar.component.Portal" } }
    ),
    SummonRecipe: defineComponent(
      world,
      {
        sacrificedPrototypeIds: Type.StringArray,
        summonedPrototypeId: Type.String,
        optionalResourceItemType: Type.Number,
      },
      { id: "SummonRecipe", metadata: { contractId: "mudwar.component.SummonRecipe" } }
    ),
    Summoner: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "Summoner", metadata: { contractId: "mudwar.component.Summoner" } }
    ),
    Tier: defineComponent(
      world,
      { value: Type.Number },
      { id: "Tier", metadata: { contractId: "mudwar.component.Tier" } }
    ),
    Charger: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "Charger", metadata: { contractId: "mudwar.component.Charger" } }
    ),
    ChargedBy: defineComponent(
      world,
      { value: Type.Entity },
      { id: "ChargedBy", metadata: { contractId: "mudwar.component.ChargedBy" } }
    ),
    ChargedByStartedAt: defineComponent(
      world,
      { value: Type.String },
      { id: "ChargedByStartedAt", metadata: { contractId: "mudwar.component.ChargedByStartedAt" } }
    ),
    Name: defineComponent(
      world,
      { value: Type.String },
      { id: "Name", metadata: { contractId: "mudwar.component.Name" } }
    ),
    Zone: defineComponent(
      world,
      { value: Type.Number },
      { id: "Zone", metadata: { contractId: "mudwar.component.Zone" } }
    ),
    Admin: defineComponent(
      world,
      { value: Type.Boolean },
      { id: "Admin", metadata: { contractId: "mudwar.component.Admin" } }
    ),
  };

  const contractConfig: SetupContractConfig = {
    clock: {
      period: 1000,
      initialTime: 0,
      syncInterval: 5000,
    },
    provider: {
      chainId: config.chainId,
      jsonRpcUrl: config.jsonRpc,
      wsRpcUrl: config.wsRpc,
      options: {
        batch: false,
      },
    },
    privateKey: config.privateKey,
    chainId: config.chainId,
    checkpointServiceUrl: config.checkpointUrl,
    initialBlockNumber: config.initialBlockNumber,
  };

  const DEV_CHAIN = contractConfig.chainId === LOCAL_CHAIN_ID || config?.devMode;

  // Instantiate contracts and set up mappings
  const { txQueue, systems, txReduced$, encoders, network, startSync, systemCallStreams } = await setupContracts(
    config.worldAddress,
    contractConfig,
    world,
    components.Systems,
    components.Components,
    components,
    DEV_CHAIN
  );

  async function joinGame(spawnEntity: EntityID, name: string) {
    console.log(`Joining game at position ${spawnEntity}`);
    return systems["mudwar.system.PlayerJoin"].executeTyped(BigNumber.from(spawnEntity), name);
  }

  async function move(entity: string, path: WorldCoord[]) {
    console.log(`Moving entity ${entity} to position (${path[path.length - 1].x}, ${path[path.length - 1].y})}`);
    // For some reason if we don't specify a gas limit here it will incorrectly estimate in some circumstances.
    // Just putting a big number for now so we don't have reverts, but will need to figure this out eventually.
    return systems["mudwar.system.Move"].executeTyped(BigNumber.from(entity), path, { gasLimit: 5_000_000 });
  }

  async function attack(attacker: EntityID, defender: EntityID) {
    console.log(`Entity ${attacker} attacking ${defender}.`);
    return systems["mudwar.system.Combat"].executeTyped(BigNumber.from(attacker), BigNumber.from(defender));
  }

  async function rangedAttack(attacker: EntityID, defender: EntityID) {
    console.log(`Entity ${attacker} attacking ${defender}.`);
    return systems["mudwar.system.RangedCombat"].executeTyped(BigNumber.from(attacker), BigNumber.from(defender));
  }

  async function buildAt(builderId: EntityID, prototypeId: string, position: WorldCoord) {
    console.log(`Building entity ${prototypeId} from factory ${builderId} at coord ${JSON.stringify(position)}`);
    return systems["mudwar.system.Factory"].executeTyped(
      BigNumber.from(builderId),
      BigNumber.from(prototypeId),
      position
    );
  }

  async function transferInventory(inventoryOwnerEntity: EntityID, receiverEntity: EntityID) {
    console.log(`transfering inventory from  ${inventoryOwnerEntity} to ${receiverEntity}.`);
    return systems["mudwar.system.TransferInventory"].executeTyped(
      BigNumber.from(inventoryOwnerEntity),
      BigNumber.from(receiverEntity)
    );
  }

  async function dropInventory(ownedEntity: EntityID, targetPosition: WorldCoord) {
    console.log(`Drop Inventory at position ${JSON.stringify(targetPosition)}`);
    return systems["mudwar.system.DropInventory"].executeTyped(BigNumber.from(ownedEntity), targetPosition);
  }

  async function gatherResource(generator: EntityID, gatherer: EntityID) {
    console.log(`Gathering resource`);
    return systems["mudwar.system.GatherResource"].executeTyped(BigNumber.from(generator), BigNumber.from(gatherer));
  }

  async function escapePortal(entity: EntityID, escapePortalEntity: EntityID) {
    console.log(`Entity ${entity} taking escapePortal ${escapePortalEntity}`);
    return systems["mudwar.system.EscapePortal"].executeTyped(
      BigNumber.from(entity),
      BigNumber.from(escapePortalEntity)
    );
  }

  async function teleport(portal: EntityID, target: EntityID, teleportee: EntityID, destination: Coord) {
    console.log(`Teleporting`);
    return systems["mudwar.system.Portal"].executeTyped(portal, target, teleportee, destination);
  }

  async function rest(rester: EntityID) {
    console.log(`Resting`);
    return systems["mudwar.system.Rest"].executeTyped(rester);
  }

  async function summon(summonerEntity: EntityID, summonID: EntityID, sacrificedEntities: EntityID[], position: Coord) {
    console.log(`Summoning Entity of type ${summonID}`);
    return systems["mudwar.system.Summon"].executeTyped(
      BigNumber.from(summonerEntity),
      BigNumber.from(summonID),
      sacrificedEntities.map((ent) => BigNumber.from(ent)),
      position
    );
  }

  async function charge(charger: EntityID, chargee: EntityID) {
    console.log(`Charging`);
    return systems["mudwar.system.Charge"].executeTyped(BigNumber.from(charger), BigNumber.from(chargee));
  }

  async function setContractComponentValue<T extends Schema>(
    entity: EntityIndex,
    component: Component<T, { contractId: string }>,
    newValue: ComponentValue<T>
  ) {
    if (!DEV_CHAIN) throw new Error("Not allowed to directly edit Component values outside DEV_CHAIN");

    if (!component.metadata.contractId)
      throw new Error(
        `Attempted to set the contract value of Component ${component.id} without a deployed contract backing it.`
      );

    const data = (await encoders)[keccak256(component.metadata.contractId)](newValue);
    const entityId = world.entities[entity];

    console.log(`Sent transaction to edit networked Component ${component.id} for Entity ${entityId}`);
    await systems["mudwar.system.ComponentDev"].executeTyped(
      keccak256(component.metadata.contractId),
      BigNumber.from(entityId),
      data
    );
  }

  async function spawnPrototypeAt(prototypeId: EntityID, position: WorldCoord) {
    console.log(`Spawning prototype ${prototypeId} at ${JSON.stringify(position)}`);
    await systems["mudwar.system.SpawnPrototypeDev"].executeTyped(BigNumber.from(prototypeId), position);
  }

  // Constants (load from contract later)
  const constants = {
    mapSize: 50,
  };

  const checkOwnEntity = (entity: EntityIndex) => {
    const entityOwner = getComponentValue(components.OwnedBy, entity)?.value;
    return entityOwner && entityOwner === network.connectedAddress.get();
  };

  const getMoveSpeed = (entity: EntityIndex) => {
    let moveSpeed = getComponentValue(components.Movable, entity)?.value;
    if (!moveSpeed) return;

    if (hasComponent(components.Inventory, entity)) {
      if (getItems(entity).length > 0) {
        moveSpeed /= 2;
      }
    }

    return moveSpeed;
  };

  const findClosest = (entity: EntityIndex, searchEntities: EntityIndex[]) => {
    const closestEntity: {
      distance: number;
      entityIndex: EntityIndex | null;
    } = {
      distance: Infinity,
      entityIndex: null,
    };

    const entityPosition = getComponentValue(components.Position, entity);
    if (!entityPosition) return closestEntity;

    for (const searchEntity of searchEntities) {
      const searchPosition = getComponentValue(components.Position, searchEntity);
      if (!searchPosition) continue;

      const distance = manhattan(entityPosition, searchPosition);
      if (distance < closestEntity.distance) {
        closestEntity.distance = distance;
        closestEntity.entityIndex = searchEntity;
      }
    }

    return closestEntity;
  };

  const getTurnAtTime = (time: number) => {
    const gameConfig = getGameConfig(world, components.GameConfig);
    if (!gameConfig) return -1;

    const startTime = BigNumber.from(gameConfig.startTime);
    const turnLength = BigNumber.from(gameConfig.turnLength);

    let atTime = BigNumber.from(time);
    if (atTime < startTime) atTime = startTime;

    return atTime.sub(startTime).div(turnLength).toNumber();
  };

  const getItems = (entity: EntityIndex) => {
    return [...runQuery([HasValue(components.OwnedBy, { value: world.entities[entity] }), Has(components.ItemType)])];
  };

  const hasItem = (entity: EntityIndex, itemType: ItemTypes) => {
    const ownedItems = getItems(entity);
    let hasItem = false;
    for (const ownedItem of ownedItems) {
      const ownedItemType = getComponentValueStrict(components.ItemType, ownedItem).value;
      if (ownedItemType === itemType) hasItem = true;
    }
    return hasItem;
  };

  const getAllSummons = () => {
    const summonRecipes = [];
    for (const summonEntity of getComponentEntities(components.SummonRecipe)) {
      summonRecipes.push(summonEntity);
    }
    return summonRecipes;
  };

  function getCombatants(args: Record<string, unknown>): [EntityIndex, EntityIndex] {
    const { attacker: attackerBn, defender: defenderBn } = args as { attacker: BigNumber; defender: BigNumber };

    const attackerId = attackerBn._hex as EntityID;
    const defenderId = defenderBn._hex as EntityID;

    const attacker = world.getEntityIndexStrict(attackerId);
    const defender = world.getEntityIndexStrict(defenderId);

    return [attacker, defender];
  }

  const onCombat = (
    callback: (
      combatData: {
        combatants: [EntityIndex, EntityIndex];
        ranged: boolean;
      } & DecodedSystemCall
    ) => void
  ) => {
    defineRxSystem(
      world,
      merge(systemCallStreams["mudwar.system.Combat"], systemCallStreams["mudwar.system.RangedCombat"]),
      (systemCall) => {
        const { args, systemId } = systemCall;
        const combatants = getCombatants(args);

        callback({ ...systemCall, ranged: systemId === "mudwar.system.RangedCombat", combatants });
      }
    );
  };

  return {
    world,
    components,
    constants,
    txQueue,
    systems,
    txReduced$,
    systemCallStreams,
    startSync,
    network,
    api: {
      joinGame,
      move,
      attack,
      rangedAttack,
      buildAt,
      transferInventory,
      dropInventory,
      gatherResource,
      escapePortal,
      teleport,
      rest,
      summon,
      charge,
      systemDecoders: {
        onCombat,
      },
      dev: {
        spawnPrototypeAt,
        setContractComponentValue,
      },
    },
    utils: {
      checkOwnEntity,
      getItems,
      hasItem,
      getAllSummons,
      getMoveSpeed,
      findClosest,
      getTurnAtTime,
    },
    DEV_CHAIN,
  };
}
