import React from "react";
import { registerUIComponent } from "../engine";
import { fromEvent, merge, of } from "rxjs";

export function registerFullscreenButton() {
  registerUIComponent(
    "Fullscreen",
    {
      rowStart: 1,
      rowEnd: 1,
      colStart: 12,
      colEnd: 12,
    },
    () => {
      return merge(of(1), fromEvent(document, "fullscreenchange"));
    },
    () => {
      const isFullscreen = !!document.fullscreenElement;
      const body = document.getElementsByTagName("body")[0];
      return (
        <a
          style={{ color: "white" }}
          onClick={() => {
            isFullscreen ? document.exitFullscreen() : body.requestFullscreen({ navigationUI: "hide" });
          }}
        >
          {isFullscreen ? "Exit Fullscreen (ESC)" : "Enter Fullscreen (F)"}
        </a>
      );
    }
  );
}
