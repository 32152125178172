import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineSystem, getComponentValue, getComponentValueStrict, Has, UpdateType } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";

export function createDrawChargingSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    components: { Appearance },
    parentLayers: {
      local: {
        components: { LocalPosition },
        api: { getOwnerColor },
      },
      network: {
        components: { ChargedBy },
      },
    },
  } = layer;

  defineSystem(world, [Has(ChargedBy), Has(LocalPosition), Has(Appearance)], ({ type, entity }) => {
    const id = `${entity}-charging-indicator`;

    if (type === UpdateType.Exit) {
      objectPool.remove(id);
      return;
    }

    const position = getComponentValueStrict(LocalPosition, entity);
    const color = getOwnerColor(entity);

    const chargedBy = getComponentValueStrict(ChargedBy, entity);
    const chargedByEntity = world.entityToIndex.get(chargedBy.value);
    if (!chargedByEntity) return;

    const chargedByPosition = getComponentValue(LocalPosition, chargedByEntity);
    if (!chargedByPosition) return;

    const object = objectPool.get(id, "Line");
    object.setComponent({
      id: "charging",
      once: (line) => {
        const entityPixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
        const chargedByPixelCoord = tileCoordToPixelCoord(chargedByPosition, tileWidth, tileHeight);

        line.setPosition(entityPixelCoord.x, entityPixelCoord.y);
        line.setTo(
          tileWidth / 2,
          tileHeight / 2,
          chargedByPixelCoord.x - entityPixelCoord.x + tileWidth / 2,
          chargedByPixelCoord.y - entityPixelCoord.y + tileHeight / 2
        );
        line.setStrokeStyle(4, color);
        line.setDepth(1);
      },
    });
  });
}
