import {
  getComponentValue,
  EntityIndex,
  EntityID,
  Type,
  Component,
  World,
  runQuery,
  HasValue,
  Has,
} from "@latticexyz/recs";
import { ActionSystem, getPlayerEntity } from "@latticexyz/std-client";
import { NetworkLayer } from "../../Network";
import { WorldCoord } from "../../../types";
import { aStar } from "../../../utils/pathfinding";
import { curry } from "lodash";

export function move(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    LocalStamina: Component<{ current: Type.Number }>;
    world: World;
    getMovementDifficulty: (
      positionComponent: Component<{
        x: Type.Number;
        y: Type.Number;
      }>,
      player: EntityIndex,
      position: WorldCoord,
      targetPosition: WorldCoord
    ) => number;
    isUntraversable: (
      positionComponent: Component<{
        x: Type.Number;
        y: Type.Number;
      }>,
      playerEntity: EntityIndex,
      isFinalPosition: boolean,
      position: WorldCoord
    ) => boolean;
  },
  entity: EntityIndex,
  targetPosition: WorldCoord
) {
  const {
    network: {
      components: { Position, Untraversable, OwnedBy, Stamina, LastAction, Combat, Player },
      network: { connectedAddress, clock },
      api: networkApi,
      utils: { getMoveSpeed },
    },
    world,
    LocalStamina,
    actions,
    getMovementDifficulty,
    isUntraversable,
  } = context;

  const moveSpeed = getMoveSpeed(entity);
  if (!moveSpeed) return;

  // Entity must be owned by the player
  const movingEntityOwner = getComponentValue(OwnedBy, entity)?.value;
  if (movingEntityOwner == null) {
    console.warn("Entity has no owner");
    return;
  }

  if (movingEntityOwner !== connectedAddress.get()) {
    console.warn("Can only move entities you own", movingEntityOwner, connectedAddress.get());
    return;
  }

  const blockingEntities = runQuery([HasValue(Position, targetPosition), Has(Untraversable)]);
  const foundBlockingEntity = blockingEntities.size > 0;
  if (foundBlockingEntity) return;

  const actionID = `move ${Date.now()}` as EntityID; // Date.now to have the actions ordered in the component browser

  actions.add<
    // Need to debug why typescript can't automatically infer these in this case, but for now manually typing removes the error
    {
      Position: typeof Position;
      Untraversable: typeof Untraversable;
      LocalStamina: typeof LocalStamina;
      Stamina: typeof Stamina;
      LastAction: typeof LastAction;
      Combat: typeof Combat;
      Player: typeof Player;
    },
    { targetPosition: WorldCoord; path: WorldCoord[]; netStamina: number }
  >({
    id: actionID,
    components: {
      Position,
      Untraversable,
      LocalStamina,
      Stamina,
      LastAction,
      Combat,
      Player,
    },
    on: entity,
    requirement: ({ LocalStamina, Position, Player }) => {
      const stamina = getComponentValue(Stamina, entity);
      if (!stamina) {
        console.warn("no stamina");
        actions.cancel(actionID);
        return null;
      }

      const localStamina = getComponentValue(LocalStamina, entity);
      if (!localStamina) {
        console.warn("no local stamina");
        actions.cancel(actionID);
        return null;
      }

      const netStamina = stamina.current + localStamina.current - 1_000;
      if (netStamina < 0) {
        console.warn("net stamina below 0");
        actions.cancel(actionID);
        return null;
      }
      const currentPosition = getComponentValue(Position, entity);
      if (!currentPosition) {
        console.warn("no current position");
        actions.cancel(actionID);
        return null;
      }

      const playerEntity = getPlayerEntity(connectedAddress.get(), world, Player);
      if (playerEntity == null) return null;

      const path = aStar(
        currentPosition,
        targetPosition,
        moveSpeed,
        curry(getMovementDifficulty)(Position, playerEntity),
        curry(isUntraversable)(Position, playerEntity)
      );

      if (path.length == 0) {
        console.warn("no path found from", currentPosition, "to", targetPosition);
        actions.cancel(actionID);
        return null;
      }
      return {
        targetPosition,
        path,
        netStamina,
      };
    },
    updates: (_, { targetPosition, netStamina }) => [
      {
        component: "Position",
        entity,
        value: targetPosition,
      },
      {
        component: "Stamina",
        entity,
        value: { current: netStamina },
      },
      {
        component: "LastAction",
        entity,
        value: { value: (clock.currentTime / 1000 + 1).toString(16) },
      },
    ],
    execute: async ({ path }) => {
      return networkApi.move(world.entities[entity], path);
    },
  });
}
