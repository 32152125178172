import styled from "styled-components";
import Color from "color";

const TITLE_YELLOW = "#ffd46b";
const BODY_TEXT = "#845945";
const BODY_ACCENT = "#8a5e3b";
const BACKGROUND = "#24131a";

export const COLORS = {
  BODY_TEXT,
  BODY_ACCENT,
  TITLE_YELLOW,
  BACKGROUND,
};

export const Section = styled.div`
  padding: 8px;
  overflow: auto;

  max-width: 100%;
  max-height: 100%;

  font-size: 1.2em;

  background: ${BACKGROUND};
  border: 3px ${BODY_ACCENT} solid;
  color: ${BODY_TEXT};
`;

export const SectionTitle = styled.h3`
  color: ${TITLE_YELLOW};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const Button = styled.button<{ active?: boolean; disabled?: boolean }>`
  background-color: ${({ active }) => (active ? Color(TITLE_YELLOW).darken(0.4).toString() : TITLE_YELLOW)};
  color: ${BODY_TEXT};
  border: 3px ${BODY_ACCENT} solid;
  border-radius: 4px;
  padding: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    color: ${Color(BODY_TEXT).darken(0.7).toString()};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  &:active {
    transform: translateY(4px);
  }
`;

export const Input = styled.input`
  background-color: ${BACKGROUND};
  color: ${TITLE_YELLOW};
  border: 3px ${BODY_ACCENT} solid;
  border-radius: 4px;
  padding: 4px;
  padding-left: 8px;
  margin: 8px 0;
  font-size: 2em;

  &:focus {
    outline: none;
  }
  ::selection {
    color: white;
    background: #d07e1a;
  }
  ::placeholder {
    color: ${() => Color("white").darken(0.6).toString()};
  }
`;
