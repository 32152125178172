import {
  defineRxSystem,
  defineSystem,
  EntityIndex,
  getComponentValueStrict,
  Has,
  removeComponent,
  runQuery,
  setComponent,
} from "@latticexyz/recs";
import { getGameConfig } from "@latticexyz/std-client";
import { HeadlessLayer } from "../../types";

export function createCooldownSystem(layer: HeadlessLayer) {
  const {
    world,
    components: { OnCooldown },
    parentLayers: {
      network: {
        components: { LastAction, GameConfig },
        network: { clock },
      },
    },
    actions,
  } = layer;

  const OptimisticLastAction = actions.withOptimisticUpdates(LastAction);

  const setCooldown = (atTime: number, entity: EntityIndex) => {
    const gameConfig = getGameConfig(world, GameConfig);
    if (!gameConfig) return null;
    const cooldownTime = parseInt(gameConfig.actionCooldownLength, 16);

    const lastAction = getComponentValueStrict(OptimisticLastAction, entity);
    const lastActionTime = parseInt(lastAction.value, 16);

    if (lastActionTime + cooldownTime > atTime) {
      setComponent(OnCooldown, entity, { value: true });
    } else {
      removeComponent(OnCooldown, entity);
    }
  };

  defineRxSystem(world, clock.time$, (time) => {
    const currentTime = time / 1000;
    for (const entity of runQuery([Has(LastAction)])) {
      setCooldown(currentTime, entity);
    }
  });

  defineSystem(world, [Has(OptimisticLastAction)], ({ entity }) => {
    setCooldown(clock.currentTime / 1000, entity);
  });
}
