import {
  createEntity,
  defineRxSystem,
  EntityIndex,
  getComponentValue,
  hasComponent,
  removeComponent,
  withValue,
} from "@latticexyz/recs";
import { getOwningPlayer } from "@latticexyz/std-client";
import { LocalLayer } from "../..";

export function createGenerateAlertsSystem(layer: LocalLayer) {
  const {
    world,
    api: { onPlayerLoaded, onEmberCrownOwnerChanged },
    components: { Alert },
    parentLayers: {
      network: {
        components: { Player, OwnedBy, StructureType, Name },
        api: {
          systemDecoders: { onCombat },
        },
      },
      headless: { turn$ },
    },
  } = layer;

  function createAlert(entity: EntityIndex, message: string) {
    const entityId = world.entities[entity];

    const alert = createEntity(world, [
      withValue(Alert, {
        on: entityId,
        message,
        type: 0,
      }),
    ]);

    setTimeout(() => {
      removeComponent(Alert, alert);
    }, 5_000);
  }

  function getOwner(entity: EntityIndex) {
    return getOwningPlayer(entity, world, Player, OwnedBy);
  }

  onPlayerLoaded(({ player }) => {
    onCombat(({ combatants }) => {
      const [, defender] = combatants;

      if (hasComponent(StructureType, defender)) return;

      const defenderOwner = getOwner(defender);
      if (defenderOwner !== player) return;

      createAlert(defender, "You are under attack!");
    });
  });

  defineRxSystem(world, turn$, (turnNumber) => {
    createAlert(0 as EntityIndex, `Turn ${turnNumber} begins!`);
  });

  onEmberCrownOwnerChanged(({ newOwner }) => {
    const newOwnerName = getComponentValue(Name, newOwner)?.value;
    createAlert(newOwner, `${newOwnerName} picked up the Ember Crown!`);
  });
}
