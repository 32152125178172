import React, { useState } from "react";
import { EntityID, getComponentValue, hasComponent } from "@latticexyz/recs";
import { computedToStream } from "@latticexyz/utils";
import { map, merge } from "rxjs";
import { registerUIComponent } from "../../engine";
import { Layers } from "../../engine/types";
import { Button, Row } from "../Theme";
import styled from "styled-components";

const Tutorial = ({ layers }: { layers: Layers }) => {
  const {
    phaser: {
      ui: { isLargeScreen },
    },
    local: {
      api: { getPreferences, persistPreferences },
    },
  } = layers;

  const largeScreen = isLargeScreen();

  const TutorialItemRow = styled(Row)`
    justify-content: space-around;
    align-items: center;
    margin-bottom: ${largeScreen ? "16px" : "8px"};
  `;

  const TutorialItemText = styled.span`
    width: 65%;
  `;

  const TutorialItemImage = styled.img`
    height: ${largeScreen ? "100px" : "70px"};
    width: ${largeScreen ? "100px" : "70px"};
  `;

  const TutorialPageItems = [
    <>
      <TutorialItemRow>
        <TutorialItemText>Grab the Ember Crown and enter an Escape Portal to win.</TutorialItemText>
        <TutorialItemImage src="assets/ui/tutorial/crown.png" />
      </TutorialItemRow>
      <TutorialItemRow>
        <TutorialItemImage src="assets/ui/tutorial/escape-portal.png" />
        <TutorialItemText>
          The Ember Crown sits on the center island. Escape Portals are on the edges of your starting island.
        </TutorialItemText>
      </TutorialItemRow>
      <TutorialItemRow>
        <TutorialItemText>
          Every unit has Action Points that refill every time there is an Energy Surge. Managing your Action Points well
          is the key to victory.
        </TutorialItemText>
        <TutorialItemImage src="assets/ui/tutorial/action-points.png" />
      </TutorialItemRow>
      <TutorialItemRow>
        <TutorialItemImage src="assets/ui/tutorial/settlement.png" />
        <TutorialItemText>
          Build armies from Settlements. Settlements have a maximum amount of Action Points they regenerate in their
          lifetime.
        </TutorialItemText>
      </TutorialItemRow>
    </>,
    <>
      <TutorialItemRow>
        <TutorialItemText>Portals allow you to jump from island to island.</TutorialItemText>
        <TutorialItemImage src="assets/ui/tutorial/portal.png" />
      </TutorialItemRow>
      <TutorialItemRow>
        <TutorialItemImage src="assets/ui/tutorial/summoning-altar.png" />
        <TutorialItemText>Summoning Altars allow you sacrifice two units to gain a stronger unit.</TutorialItemText>
      </TutorialItemRow>
      <TutorialItemRow>
        <TutorialItemText>
          Blazing Hearts are needed to summon the most powerful units in the game. They are found in Blazing Heart
          Shrines.
        </TutorialItemText>
        <TutorialItemImage src="assets/ui/tutorial/blazing-heart-shrine.png" />
      </TutorialItemRow>
    </>,
  ];

  const preferences = getPreferences();

  const [page, setPage] = useState(0);

  return (
    <div
      style={{
        backgroundImage: "url(assets/ui/big-dialogue.png)",
        height: "min(600px, calc(100vh - 100px))",
        width: "min(600px, 50vw)",
        backgroundSize: "min(600px, 50vw) min(600px, calc(100vh - 100px))",
        color: "#ffd56a",
        display: "flex",
        flexDirection: "column",
        paddingTop: "6px",
        paddingBottom: "16px",
      }}
    >
      <h1
        style={{
          margin: "0 auto",
          fontSize: largeScreen ? "2.5em" : "1.8em",
        }}
      >
        Tutorial
      </h1>

      <div
        style={{
          margin: "0 auto",
          padding: "16px 24px",
          paddingBottom: "0px",
          fontSize: largeScreen ? "1.2em" : "0.9em",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100%",
        }}
      >
        {TutorialPageItems[page]}
      </div>

      <Row style={{ justifyContent: "space-around" }}>
        <Button style={{ fontSize: "2em" }} onClick={() => persistPreferences({ ...preferences, hideTutorial: true })}>
          Go to game
        </Button>

        {page !== TutorialPageItems.length - 1 && (
          <Button style={{ fontSize: "2em" }} onClick={() => setPage((page) => page + 1)}>
            Next Page
          </Button>
        )}
      </Row>
    </div>
  );
};

export function registerTutorial() {
  registerUIComponent(
    "Tutorial",
    {
      colStart: 5,
      colEnd: 9,
      rowStart: 2,
      rowEnd: 7,
    },
    (layers) => {
      const {
        network: {
          world,
          network: { connectedAddress },
          components: { Admin, Player },
        },
        local: {
          components: { Preferences },
          singletonEntity,
        },
      } = layers;

      return merge(computedToStream(connectedAddress), Preferences.update$, Admin.update$, Player.update$).pipe(
        map(() => connectedAddress.get()),
        map((address) => {
          if (!address) return;

          const userPreferences = getComponentValue(Preferences, singletonEntity);
          if (userPreferences?.hideTutorial) return;

          const playerEntity = world.entityToIndex.get(address as EntityID);

          if (playerEntity != undefined) {
            if (hasComponent(Player, playerEntity)) return;
            if (hasComponent(Admin, playerEntity)) return;
          }

          return {
            layers,
          };
        })
      );
    },
    (props) => {
      return <Tutorial {...props} />;
    }
  );
}
