import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineSystem, EntityID, getComponentValue, getComponentValueStrict, Has, UpdateType } from "@latticexyz/recs";
import { getStringColor } from "@latticexyz/std-client";
import { PhaserLayer } from "../../types";

export function createDrawPortalTargetSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      local: {
        components: { LocalPosition, Selected },
      },
      network: {
        components: { Portal },
      },
    },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  defineSystem(world, [Has(Selected), Has(Portal), Has(LocalPosition)], ({ type, entity }) => {
    const id = `${entity}-portal-exit`;

    if (type === UpdateType.Exit) {
      objectPool.remove(id);
      return;
    }

    const portal = getComponentValueStrict(Portal, entity);
    if (portal.targetIds.length === 0) return;

    const target = world.getEntityIndexStrict(portal.targetIds[0] as EntityID);
    const targetPosition = getComponentValue(LocalPosition, target);
    if (!targetPosition) return;

    const position = getComponentValueStrict(LocalPosition, entity);
    const color = getStringColor(portal.targetIds[0]);

    const object = objectPool.get(id, "Line");
    object.setComponent({
      id: "portal-exit",
      once: (line) => {
        const entityPixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
        const exitPixelCoord = tileCoordToPixelCoord(targetPosition, tileWidth, tileHeight);

        line.setPosition(entityPixelCoord.x, entityPixelCoord.y);
        line.setTo(
          tileWidth / 2,
          tileHeight / 2,
          exitPixelCoord.x - entityPixelCoord.x + tileWidth / 2,
          exitPixelCoord.y - entityPixelCoord.y + tileHeight / 2
        );
        line.setStrokeStyle(4, color);
        line.setDepth(1);
      },
    });
  });
}
