// Autogenerated using mud system-types
import BulkSetStateSystem from "../abi/BulkSetStateSystem.json";
import ChargeSystem from "../abi/ChargeSystem.json";
import CombatSystem from "../abi/CombatSystem.json";
import ComponentDevSystem from "../abi/ComponentDevSystem.json";
import DropInventorySystem from "../abi/DropInventorySystem.json";
import EscapePortalSystem from "../abi/EscapePortalSystem.json";
import FactorySystem from "../abi/FactorySystem.json";
import GameConfigSystem from "../abi/GameConfigSystem.json";
import GatherResourceSystem from "../abi/GatherResourceSystem.json";
import Init1System from "../abi/Init1System.json";
import Init2System from "../abi/Init2System.json";
import Init3System from "../abi/Init3System.json";
import MoveSystem from "../abi/MoveSystem.json";
import PlayerJoinSystem from "../abi/PlayerJoinSystem.json";
import PortalSystem from "../abi/PortalSystem.json";
import PrototypeDevSystem from "../abi/PrototypeDevSystem.json";
import RangedCombatSystem from "../abi/RangedCombatSystem.json";
import RestSystem from "../abi/RestSystem.json";
import SpawnPrototypeDevSystem from "../abi/SpawnPrototypeDevSystem.json";
import StartGameSystem from "../abi/StartGameSystem.json";
import SummonSystem from "../abi/SummonSystem.json";
import TransferInventorySystem from "../abi/TransferInventorySystem.json";

export const SystemAbis = {
  "system.BulkSetState": BulkSetStateSystem.abi,
  "mudwar.system.Charge": ChargeSystem.abi,
  "mudwar.system.Combat": CombatSystem.abi,
  "mudwar.system.ComponentDev": ComponentDevSystem.abi,
  "mudwar.system.DropInventory": DropInventorySystem.abi,
  "mudwar.system.EscapePortal": EscapePortalSystem.abi,
  "mudwar.system.Factory": FactorySystem.abi,
  "mudwar.system.GameConfig": GameConfigSystem.abi,
  "mudwar.system.GatherResource": GatherResourceSystem.abi,
  "mudwar.system.Init1": Init1System.abi,
  "mudwar.system.Init2": Init2System.abi,
  "mudwar.system.Init3": Init3System.abi,
  "mudwar.system.Move": MoveSystem.abi,
  "mudwar.system.PlayerJoin": PlayerJoinSystem.abi,
  "mudwar.system.Portal": PortalSystem.abi,
  "mudwar.system.PrototypeDev": PrototypeDevSystem.abi,
  "mudwar.system.RangedCombat": RangedCombatSystem.abi,
  "mudwar.system.Rest": RestSystem.abi,
  "mudwar.system.SpawnPrototypeDev": SpawnPrototypeDevSystem.abi,
  "mudwar.system.StartGame": StartGameSystem.abi,
  "mudwar.system.Summon": SummonSystem.abi,
  "mudwar.system.TransferInventory": TransferInventorySystem.abi,
};
