import React from "react";
import { registerUIComponent } from "../engine";
import { getGameConfig } from "@latticexyz/std-client";
import { map } from "rxjs";
import { COLORS, Section } from "./Theme";
import { Has, HasValue, runQuery } from "@latticexyz/recs";

export function registerTurnTimer() {
  registerUIComponent(
    "TurnTimer",
    {
      rowStart: 1,
      rowEnd: 1,
      colStart: 6,
      colEnd: 9,
    },
    (layers) => {
      const {
        world,
        network: { clock, connectedAddress },
        components: { GameConfig, SpawnPoint, OwnedBy },
      } = layers.network;

      return clock.time$.pipe(
        map((time) => {
          const gameConfig = getGameConfig(world, GameConfig);
          if (!gameConfig) return { secondsUntilNextTurn: 0 };

          const gameStartTime = parseInt(gameConfig.startTime);
          const turnLength = parseInt(gameConfig.turnLength);

          const currentTime = time / 1000;
          const timeElapsed = currentTime - gameStartTime;
          const secondsUntilNextTurn = turnLength - (timeElapsed % turnLength);

          const address = connectedAddress.get();
          if (!address) return;

          const ownedSpawn = runQuery([Has(SpawnPoint), HasValue(OwnedBy, { value: address })]);
          if(ownedSpawn.size === 0) return;

          return {
            layers,
            secondsUntilNextTurn,
            turnLength,
            address,
          };
        })
      );
    },
    ({ secondsUntilNextTurn, turnLength }) => {
      if (!turnLength) return null;

      return (
        <Section style={{}}>
          <div
            style={{
              marginBottom: "4px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontSize: "1em" }}>Energy Surge in</h3>
            {secondsUntilNextTurn} seconds
          </div>
          <div
            style={{
              height: "24px",
            }}
          >
            <div
              style={{
                transition: "width 1s linear",
                backgroundColor: COLORS.TITLE_YELLOW,
                height: "100%",
                width: `calc(100% * ${(turnLength - secondsUntilNextTurn) / (turnLength - 1)})`,
              }}
            ></div>
          </div>
        </Section>
      );
    }
  );
}
