import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { filter, map, merge } from "rxjs";
import {
  defineQuery,
  EntityID,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  Not,
  runQuery,
  setComponent,
} from "@latticexyz/recs";
import { WorldCoord } from "../../../../../types";
import { getOwningPlayer, isOwnedByCaller } from "@latticexyz/std-client";
import { InputUtils } from "./createInputSystem";

export function registerHoverIcon(layer: PhaserLayer, { getSelectedEntity }: InputUtils) {
  const {
    scenes: {
      Main: { input, maps, phaserScene },
    },
    components: { HoverHighlight, HoverIcon },
    api: {
      highlightCoord,
      mapInteraction: { mapInteractionEnabled },
    },
    parentLayers: {
      network: {
        world,
        components: { TerrainType, OwnedBy, Player, Position, Untraversable },
        network: { connectedAddress },
      },
      headless: {
        components: { OnCooldown },
        api: {
          canAttack,
          canEscapePortal,
          canGatherResource,
          canGiveInventory,
          canRangedAttack,
          canTakeInventory,
          canTeleport,
          canCharge,
          canRest,
        },
      },
      local: {
        singletonEntity,
        components: { Selected, LocalPosition, PotentialPath },
        api: { getOwnerColor, onPlayerLoaded },
      },
    },
  } = layer;

  onPlayerLoaded(({ player }) => {
    const canMoveTo = (entity: EntityIndex, targetPosition: WorldCoord) => {
      if (hasComponent(OnCooldown, entity)) return false;

      const blockingEntities = runQuery([HasValue(LocalPosition, targetPosition), Has(Untraversable)]);
      const foundBlockingEntity = blockingEntities.size > 0;
      if (foundBlockingEntity) return false;

      const paths = getComponentValue(PotentialPath, entity);
      if (!paths || paths.x.length === 0) {
        return false;
      }

      for (let i = 0; i < paths.x.length; i++) {
        if (paths.x[i] == targetPosition.x && paths.y[i] == targetPosition.y) {
          return true;
        }
      }
      return false;
    };

    const setHoverIcon = function (hoveredPosition: WorldCoord) {
      const hoverHighlight = getComponentValueStrict(HoverHighlight, singletonEntity);
      const highlightedEntity = [
        ...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType)]),
      ][0];

      let highlightColor = 0xffff00;
      if (highlightedEntity != null) {
        highlightColor = getOwnerColor(highlightedEntity);
      }

      highlightCoord(hoveredPosition, highlightColor);

      const selectedEntity = getSelectedEntity();
      if (!selectedEntity) {
        setComponent(HoverIcon, singletonEntity, { icon: "default" });
        return;
      }

      const owningPlayer = getOwningPlayer(selectedEntity, world, Player, OwnedBy);
      if (owningPlayer !== player) {
        setComponent(HoverIcon, singletonEntity, { icon: "default" });
        return;
      }

      if (selectedEntity == highlightedEntity) {
        if (canRest(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/rest.png), pointer" });
          return;
        }
        setComponent(HoverIcon, singletonEntity, { icon: "default" });
        return;
      }

      const playerEntity = world.entityToIndex.get(connectedAddress.get() as EntityID);

      if (!playerEntity) return;
      if (!hasComponent(Player, playerEntity)) return;
      if (!isOwnedByCaller(OwnedBy, selectedEntity, playerEntity, world.entityToIndex)) return;

      if (highlightedEntity != null) {
        if (canTeleport(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/transfer.png), pointer" });
          return;
        } else if (canCharge(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/transfer.png), pointer" });
          return;
        } else if (canEscapePortal(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/move.png), pointer" });
          return;
        } else if (canGatherResource(highlightedEntity, selectedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/pickup.png), pointer" });
          return;
        } else if (canTakeInventory(highlightedEntity, selectedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/pickup.png), pointer" });
          return;
        } else if (canRangedAttack(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/attack.png), pointer" });
          return;
        } else if (canAttack(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/attack.png), pointer" });
          return;
        }

        if (isOwnedByCaller(OwnedBy, highlightedEntity, playerEntity, world.entityToIndex)) {
          if (canGiveInventory(selectedEntity, highlightedEntity)) {
            setComponent(HoverIcon, singletonEntity, { icon: "url(assets/transfer.png), pointer" });
            return;
          }
        }
      }

      if (canMoveTo(selectedEntity, hoveredPosition)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/move.png), pointer" });
        return;
      }

      setComponent(HoverIcon, singletonEntity, { icon: "default" });
    };

    const selectedEntityPositionQuery = defineQuery([Has(Selected), Has(Position)]);
    merge(selectedEntityPositionQuery.update$, input.pointermove$)
      .pipe(
        filter(() => mapInteractionEnabled()),
        map(() => {
          const pointer = phaserScene.input.activePointer;
          return { x: pointer.worldX, y: pointer.worldY };
        }), // Map pointer to pointer pixel cood
        map((pixel) => pixelToWorldCoord(maps.Main, pixel)) // Map pixel coord to tile coord
      )
      .subscribe((coord) => {
        setHoverIcon(coord);
      });
  });
}
