import {
  createNetwork,
  createContracts,
  Mappings,
  createTxQueue,
  createSyncWorker,
  createEncoder,
  SyncWorkerConfig,
  NetworkConfig,
  createSystemExecutor,
  isNetworkComponentUpdateEvent,
  isSystemCallEvent,
  NetworkEvent,
  SystemCall,
  NetworkComponentUpdate,
} from "@latticexyz/network";
import { World as WorldContract } from "contracts/types/ethers-contracts/World";
import { abi as WorldAbi } from "contracts/abi/World.json";
import { BehaviorSubject, bufferTime, filter, Observable, of, Subject } from "rxjs";
import {
  Component,
  Components,
  EntityID,
  EntityIndex,
  getComponentEntities,
  getComponentValue,
  getComponentValueStrict,
  removeComponent,
  Schema,
  setComponent,
  Type,
  World,
} from "@latticexyz/recs";
import { computed, IComputedValue } from "mobx";
import { keccak256, stretch, toEthAddress } from "@latticexyz/utils";
import ComponentAbi from "@latticexyz/solecs/abi/Component.json";
import { BigNumber, Contract, ContractInterface, ethers, Signer } from "ethers";
import { Component as SolecsComponent } from "@latticexyz/solecs";
import { SystemTypes } from "contracts/types/SystemTypes";
import { SystemAbis } from "contracts/types/SystemAbis.mjs";
import { JsonRpcProvider } from "@ethersproject/providers";
import { compact, keys, toLower } from "lodash";
import { DecodedNetworkComponentUpdate, DecodedSystemCall } from "../types";

export type ContractComponents = {
  [key: string]: Component<Schema, { contractId: string }>;
};

export type SetupContractConfig = NetworkConfig & Omit<SyncWorkerConfig, "worldContract" | "mappings">;

export async function setupContracts<C extends ContractComponents>(
  worldAddress: string,
  config: SetupContractConfig,
  world: World,
  systemsComponent: Component<{ value: Type.String }>,
  componentsComponent: Component<{ value: Type.String }>,
  components: C,
  devMode?: boolean
) {
  const mappings: Mappings<C> = {};
  for (const key of Object.keys(components)) {
    const { contractId } = components[key].metadata;
    mappings[keccak256(contractId)] = key;
  }
  const network = await createNetwork(config);
  world.registerDisposer(network.dispose);

  console.log(
    "Initial block",
    config.initialBlockNumber,
    await network.providers.get().json.getBlock(config.initialBlockNumber)
  );

  const signerOrProvider = computed(() => network.signer.get() || network.providers.get().json);

  const { contracts, config: contractsConfig } = await createContracts<{ World: WorldContract }>({
    config: { World: { abi: WorldAbi, address: worldAddress } },
    signerOrProvider,
  });

  const gasPrice$ = new BehaviorSubject(0);
  const { txQueue, dispose: disposeTxQueue } = createTxQueue(contracts, network, gasPrice$, { devMode });
  world.registerDisposer(disposeTxQueue);

  const decodeNetworkComponentUpdate = createDecodeNetworkComponentUpdate(world, components, mappings);

  // Create sync worker
  const { ecsEvent$, config$, dispose } = createSyncWorker();
  world.registerDisposer(dispose);
  function startSync() {
    config$.next({
      provider: config.provider,
      worldContract: contractsConfig.World,
      initialBlockNumber: config.initialBlockNumber ?? 0,
      chainId: config.chainId,
      disableCache: devMode, // Disable cache on hardhat
      checkpointServiceUrl: config.checkpointServiceUrl,
      fetchSystemCalls: true,
    });
  }

  const systems = createSystemExecutor<SystemTypes>(world, network, systemsComponent, SystemAbis, gasPrice$, {
    devMode,
  });
  const { systemCallStreams, decodeAndEmitSystemCall } = createSystemCallStreams(
    world,
    systemsComponent,
    SystemAbis,
    decodeNetworkComponentUpdate
  );

  const { txReduced$ } = applyNetworkUpdates(world, ecsEvent$, decodeNetworkComponentUpdate, decodeAndEmitSystemCall);

  const encoders = createEncoders(world, componentsComponent, signerOrProvider);

  return { txQueue, txReduced$, encoders, network, startSync, systems, systemCallStreams };
}

async function createEncoders(
  world: World,
  components: Component<{ value: Type.String }>,
  signerOrProvider: IComputedValue<JsonRpcProvider | Signer>
) {
  const encoders = {} as Record<string, ReturnType<typeof createEncoder>>;

  async function fetchAndCreateEncoder(entity: EntityIndex) {
    const componentAddress = toEthAddress(world.entities[entity]);
    const componentId = getComponentValueStrict(components, entity).value;
    const componentContract = new Contract(
      componentAddress,
      ComponentAbi.abi,
      signerOrProvider.get()
    ) as SolecsComponent;
    const [componentSchemaPropNames, componentSchemaTypes] = await componentContract.getSchema();
    encoders[componentId] = createEncoder(componentSchemaPropNames, componentSchemaTypes);
  }

  // Initial setup
  for (const entity of getComponentEntities(components)) fetchAndCreateEncoder(entity);

  // Keep up to date
  const subscription = components.update$.subscribe((update) => fetchAndCreateEncoder(update.entity));
  world.registerDisposer(() => subscription?.unsubscribe());

  return encoders;
}

/**
 * Sets up synchronization between contract components and client components
 */
function applyNetworkUpdates<C extends Components>(
  world: World,
  ecsEvent$: Observable<NetworkEvent<C>>,
  decodeNetworkComponentUpdate: ReturnType<typeof createDecodeNetworkComponentUpdate>,
  decodeAndEmitSystemCall: (event: SystemCall<C>) => void
) {
  const txReduced$ = new Subject<string>();

  const ecsEventSub = ecsEvent$
    .pipe(
      // We throttle the client side event processing to 1000 events every 16ms, so 62.500 events per second.
      // This means if the chain were to emit more than 62.500 events per second, the client would not keep up.
      // The only time we get close to this number is when initializing from a checkpoint/cache.
      bufferTime(16, null, 1000),
      filter((events) => events.length > 0),
      stretch(16)
    )
    .subscribe((events) => {
      // Running this in a mobx action would result in only one system update per frame (should increase performance)
      // but it currently breaks defineUpdateAction (https://linear.app/latticexyz/issue/LAT-594/defineupdatequery-does-not-work-when-running-multiple-component)
      for (const event of events) {
        if (isNetworkComponentUpdateEvent(event)) {
          const update = decodeNetworkComponentUpdate(event);
          if (!update) continue;

          const { component, entity } = update;

          if (event.value === undefined) {
            // undefined value means component removed
            removeComponent(component, entity);
          } else {
            setComponent(component, entity, event.value);
          }

          if (event.lastEventInTx) txReduced$.next(event.txHash);
        } else if (isSystemCallEvent(event)) {
          decodeAndEmitSystemCall(event);
        }
      }
    });

  world.registerDisposer(() => ecsEventSub?.unsubscribe());
  return { txReduced$: txReduced$.asObservable() };
}

function createSystemCallStreams<C extends Components, T extends { [key: string]: Contract }>(
  world: World,
  systemsComponent: Component<{ value: Type.String }>,
  systemInterfaces: { [key in keyof T]: ContractInterface },
  decodeNetworkComponentUpdate: ReturnType<typeof createDecodeNetworkComponentUpdate>
) {
  const systemIdPreimages: { [key: string]: keyof SystemTypes } = Object.keys(systemInterfaces).reduce((acc, curr) => {
    return { ...acc, [keccak256(curr)]: curr };
  }, {});
  const systemCallStreams = keys(systemInterfaces).reduce(
    (streams, systemId) => ({ ...streams, [systemId]: new Subject<DecodedSystemCall>() }),
    {} as Record<keyof SystemTypes, Subject<DecodedSystemCall<C>>>
  );

  return {
    systemCallStreams,
    decodeAndEmitSystemCall: (systemCall: SystemCall<C>) => {
      const { tx } = systemCall;

      const systemEntityIndex = world.entityToIndex.get(toLower(BigNumber.from(tx.to).toHexString()) as EntityID);
      if (!systemEntityIndex) return;

      const hashedSystemId = getComponentValue(systemsComponent, systemEntityIndex)?.value;
      if (!hashedSystemId) return;

      const systemId = systemIdPreimages[hashedSystemId];

      const iface = new ethers.utils.Interface(systemInterfaces[systemId] as unknown as string);
      const decodedTx = iface.parseTransaction({ data: tx.data, value: tx.value });

      systemCallStreams[systemId].next({
        ...systemCall,
        updates: compact(systemCall.updates.map(decodeNetworkComponentUpdate)),
        systemId,
        args: decodedTx.args,
      });
    },
  };
}

function createDecodeNetworkComponentUpdate<C extends Components>(
  world: World,
  components: C,
  mappings: Mappings<C>
): (update: NetworkComponentUpdate) => DecodedNetworkComponentUpdate | undefined {
  return (update: NetworkComponentUpdate) => {
    const entityIndex = world.entityToIndex.get(update.entity) ?? world.registerEntity({ id: update.entity });
    const componentKey = mappings[update.component];
    const component = components[componentKey] as Component<Schema>;

    if (!componentKey) {
      console.error(`Component mapping not found for component ID ${update.component} ${JSON.stringify(update.value)}`);
      return undefined;
    }

    return {
      ...update,
      entity: entityIndex,
      component,
    };
  };
}
