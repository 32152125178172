import { HueTintAndOutlineFXPipeline, pixelCoordToTileCoord, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  EntityIndex,
  getComponentValueStrict,
  getEntitiesWithValue,
  Has,
  hasComponent,
  removeComponent,
  UpdateType,
} from "@latticexyz/recs";
import { getStringColor } from "@latticexyz/std-client";
import { Coord } from "@latticexyz/utils";
import { merge, Subscription } from "rxjs";
import { BFS } from "../../../../../utils/pathfinding";
import { UnitTypeAnimations } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawTeleportSystem(layer: PhaserLayer) {
  const {
    world,
    api: {
      drawTileHighlight,
      selectAndView,
      mapInteraction: { disableMapInteraction, enableMapInteraction },
    },
    parentLayers: {
      network: {
        components: { Portal, UnitType, Position, Untraversable },
      },
      headless: {
        api: { teleport },
      },
      local: {
        components: { Selected, LocalPosition, ChoosingTeleportLocation },
        singletonEntity,
        api: { getOwnerColor },
      },
    },
    components: { HoverHighlight },
    scenes: {
      Main: {
        objectPool,
        input,
        phaserScene,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  let teleportClickSubscription: Subscription | undefined;
  let teleportPreviewSubscription: Subscription | undefined;
  let teleportPreviewSprite: Phaser.GameObjects.Sprite | undefined;

  function disableMapInput() {
    disableMapInteraction();
    removeComponent(HoverHighlight, singletonEntity);
  }

  function clearTeleportPreviewTiles() {
    for (let i = 0; i < 50; i++) {
      objectPool.remove(`teleport-position-${i}`);
    }

    teleportClickSubscription?.unsubscribe();
    teleportPreviewSubscription?.unsubscribe();
    teleportPreviewSprite?.destroy();
    teleportPreviewSprite = undefined;
  }

  const shouldDrawTeleportTile = (_isFinalPosition: boolean, position: Coord) => {
    const entitiesAtPosition = getEntitiesWithValue(Position, position);
    const blockingEntity = [...entitiesAtPosition].find((e) => hasComponent(Untraversable, e));
    if (!blockingEntity) return false;

    const isUnit = hasComponent(UnitType, blockingEntity);
    if (isUnit) return false;

    return true;
  };

  function renderTeleportExitLocationPreview(entrance: EntityIndex, exit: EntityIndex, teleportee: EntityIndex) {
    selectAndView(exit);
    clearTeleportPreviewTiles();

    const position = getComponentValueStrict(LocalPosition, exit);
    const exitPortal = getComponentValueStrict(Portal, entrance);
    disableMapInput();

    const [teleportPositions] = BFS(position, exitPortal.radius, () => 1, shouldDrawTeleportTile);
    teleportPositions.push(position);

    for (let i = 0; i < teleportPositions.length; i++) {
      drawTileHighlight(`teleport-position-${i}`, teleportPositions[i], 0xffff00);
    }

    // TODO Without putting it in a timeout the subscription fires immediately.
    // Why.
    setTimeout(() => {
      teleportClickSubscription = merge(input.click$, input.rightClick$).subscribe((pointer) => {
        const tile = pixelCoordToTileCoord(
          {
            x: pointer.worldX,
            y: pointer.worldY,
          },
          tileWidth,
          tileHeight
        );
        for (const teleportPosition of teleportPositions) {
          if (tile.x === teleportPosition.x && tile.y === teleportPosition.y) {
            teleport(entrance, world.entities[exit], teleportee, teleportPosition);
          }
        }

        removeComponent(ChoosingTeleportLocation, exit);
        clearTeleportPreviewTiles();
        enableMapInteraction();
      });

      teleportPreviewSubscription = input.pointermove$.subscribe(({ pointer }) => {
        const tile = pixelCoordToTileCoord(
          {
            x: pointer.worldX,
            y: pointer.worldY,
          },
          tileWidth,
          tileHeight
        );
        const previewPosition = tileCoordToPixelCoord(tile, tileWidth, tileHeight);
        const color = getOwnerColor(teleportee);
        const unitType = getComponentValueStrict(UnitType, teleportee).value;
        const spriteAnimation = UnitTypeAnimations[unitType];

        if (!teleportPreviewSprite) {
          teleportPreviewSprite = phaserScene.add.sprite(tile.x, tile.y, "");
          teleportPreviewSprite.play(spriteAnimation);
          teleportPreviewSprite.setDepth(130);
          teleportPreviewSprite.setOrigin(0, 0);
          teleportPreviewSprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
          teleportPreviewSprite.setPipelineData("hueTint", color);
        }

        teleportPreviewSprite.setPosition(previewPosition.x, previewPosition.y);
        if (!teleportPositions.find((bp) => bp.x === tile.x && bp.y === tile.y)) {
          teleportPreviewSprite.setTint(0x666666);
        } else {
          teleportPreviewSprite.clearTint();
        }
      });
    }, 0);
  }

  defineSystem(world, [Has(Selected), Has(Portal), Has(LocalPosition)], ({ type, entity }) => {
    if (type === UpdateType.Exit) {
      // no good way to know how many tiles we've spawned
      // 40 seems like a good max number...
      for (let i = 0; i < 40; i++) {
        objectPool.remove(`portal-radius-tile-${entity}-${i}`);
      }
      return;
    }

    const portal = getComponentValueStrict(Portal, entity);
    const color = getStringColor(portal.targetIds[0]);
    const portalPosition = getComponentValueStrict(LocalPosition, entity);
    const [paths] = BFS(portalPosition, portal.radius, () => 1, shouldDrawTeleportTile);

    for (let i = 0; i < paths.length; i++) {
      const coord = paths[i];
      drawTileHighlight(`portal-radius-tile-${entity}-${i}`, coord, color);
    }
  });

  // ChoosingTeleportLocation is set as part of startTeleport.
  // Triggered when right-clicking a Portal while in range.
  defineSystem(world, [Has(ChoosingTeleportLocation)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      clearTeleportPreviewTiles();
      return;
    }

    const teleportData = getComponentValueStrict(ChoosingTeleportLocation, entity);

    renderTeleportExitLocationPreview(
      world.getEntityIndexStrict(teleportData.entrance),
      entity,
      world.getEntityIndexStrict(teleportData.teleportee)
    );
  });
}
