import {
  defineComponent,
  Type,
  namespaceWorld,
  EntityIndex,
  getComponentValue,
  hasComponent,
  runQuery,
  Has,
  HasValue,
  Not,
  getComponentValueStrict,
  Component,
  EntityID,
} from "@latticexyz/recs";
import {
  joinGame,
  move,
  attack,
  escapePortal,
  buildAt,
  gatherResource,
  teleport,
  transferInventory,
  dropInventory,
  rest,
  summon,
  charge,
} from "./api";
import { calculateCombatResult, isPassive, isNeutralStructure, canRetaliate } from "./utils";

import { NetworkLayer, StructureTypes } from "../Network";
import { createCurrentStaminaSystem } from "./systems";
import { curry } from "lodash";
import { createTurnStream } from "./setup";
import { manhattan } from "../../utils/distance";
import { createActionSystem, isOwnedByCaller } from "@latticexyz/std-client";
import { WorldCoord } from "../../types";
import { createCooldownSystem } from "./systems/CooldownSystem";
import { BFS, getZOIPositions } from "../../utils/pathfinding";

/**
 * The Headless layer is the second layer in the client architecture and extends the Network layer.
 * Its purpose is to provide an API that allows the game to be played programatically.
 */

export async function createHeadlessLayer(network: NetworkLayer) {
  const world = namespaceWorld(network.world, "headless");
  const {
    components: {
      Charger,
      Combat,
      EscapePortal,
      GameConfig,
      Inventory,
      MovementDifficulty,
      OwnedBy,
      Portal,
      Position,
      PrototypeCopy,
      RangedCombat,
      ResourceGenerator,
      Stamina,
      StructureType,
      Summoner,
      SummonRecipe,
      TerrainType,
      Untraversable,
      UnitType,
    },
    utils: { getItems, hasItem },
    network: { clock },
  } = network;

  const LocalStamina = defineComponent(world, { current: Type.Number }, { id: "LocalStamina" });
  const OnCooldown = defineComponent(world, { value: Type.Boolean }, { id: "OnCooldown" });
  const components = { LocalStamina, OnCooldown };

  const actions = createActionSystem(world, network.txReduced$);

  const turn$ = createTurnStream(world, GameConfig, clock);

  const getCurrentStamina = (entity: EntityIndex) => {
    const contractStamina = getComponentValue(Stamina, entity)?.current;
    if (contractStamina == undefined) return 0;

    const localStamina = getComponentValue(LocalStamina, entity)?.current;
    if (localStamina == undefined) return 0;

    return contractStamina + localStamina;
  };

  const isUntraversable = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    playerEntity: EntityIndex,
    isFinalPosition: boolean,
    position: WorldCoord
  ) => {
    const blockingEntities = runQuery([HasValue(positionComponent, position), Has(Untraversable)]);

    const foundBlockingEntity = blockingEntities.size > 0;
    if (!foundBlockingEntity) return false;
    if (isFinalPosition) return true;

    const blockingEntity = [...blockingEntities][0];

    if (hasComponent(StructureType, blockingEntity)) {
      return getComponentValueStrict(StructureType, blockingEntity).value !== StructureTypes.Container;
    }

    if (!isOwnedByCaller(OwnedBy, blockingEntity, playerEntity, world.entityToIndex)) return true;

    return false;
  };

  const getZOIValue = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    player: EntityIndex,
    checkPositions: WorldCoord[]
  ) => {
    let ZOIValue = 0;
    let entity: EntityIndex;
    for (let i = 0; i < checkPositions.length; i++) {
      entity = [...runQuery([HasValue(positionComponent, checkPositions[i]), Has(Combat), Has(OwnedBy)])][0];
      const exertsZone =
        entity && !isPassive(network, entity) && getComponentValueStrict(OwnedBy, entity).value !== "0";

      if (exertsZone && !isOwnedByCaller(OwnedBy, entity, player, world.entityToIndex)) {
        ZOIValue += 1;
      }
    }
    return ZOIValue;
  };

  const getMovementDifficulty = (
    positionComponent: Component<{ x: Type.Number; y: Type.Number }>,
    player: EntityIndex,
    position: WorldCoord,
    targetPosition: WorldCoord
  ) => {
    const checkPositions = getZOIPositions(position, targetPosition);

    const enemyZone = getZOIValue(positionComponent, player, checkPositions);
    const entity = [...runQuery([HasValue(positionComponent, targetPosition), Has(MovementDifficulty)])][0];
    if (entity == null) return Infinity;

    return getComponentValueStrict(MovementDifficulty, entity).value + enemyZone;
  };

  function distanceBetween(entity1: EntityIndex, entity2: EntityIndex) {
    const position1 = getComponentValue(Position, entity1);
    const position2 = getComponentValue(Position, entity2);

    if (!position1 || !position2) return undefined;

    return manhattan(position1, position2);
  }

  function unitSort(a: EntityIndex, b: EntityIndex) {
    const aOutOfStamina = getCurrentStamina(a) < 1000;
    const bOutOfStamina = getCurrentStamina(b) < 1000;

    if (aOutOfStamina && !bOutOfStamina) return 1;
    if (bOutOfStamina && !aOutOfStamina) return -1;

    const aUnitType = getComponentValue(UnitType, a)?.value;
    const bUnitType = getComponentValue(UnitType, b)?.value;

    if (aUnitType && bUnitType && aUnitType !== bUnitType) {
      return bUnitType - aUnitType;
    }

    const aStructureType = getComponentValue(StructureType, a)?.value;
    const bStructureType = getComponentValue(StructureType, b)?.value;

    if (aStructureType && bStructureType && aStructureType !== bStructureType) {
      return bStructureType - aStructureType;
    }

    if (aUnitType && bStructureType) {
      return -1;
    }

    if (aStructureType && bUnitType) {
      return 1;
    }

    return b - a;
  }
  function withinRange(entity1: EntityIndex, entity2: EntityIndex, maxDistance = 1) {
    const distance = distanceBetween(entity1, entity2);
    return distance && distance <= maxDistance;
  }

  const getInventory = (entity: EntityIndex) => {
    const capacity = getComponentValue(Inventory, entity)?.value;
    if (capacity == null) return;

    const items = getItems(entity);
    const isFull = () => items.length >= capacity;

    return {
      capacity,
      items,
      isFull,
    };
  };

  const getSacrificableNeighbors = (
    owner: EntityID,
    summonerEntity: EntityIndex,
    PositionComponent: Component<{ x: Type.Number; y: Type.Number }>
  ) => {
    const currentPosition = getComponentValue(PositionComponent, summonerEntity);
    if (!currentPosition) return [];

    const [possiblePositions] = BFS(
      currentPosition,
      2,
      () => 1,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_isFinalPosition: boolean, _position: WorldCoord) => false
    );
    const sacrificableEntities: EntityIndex[] = [];

    for (const pos of possiblePositions) {
      const entity = [
        ...runQuery([
          HasValue(PositionComponent, pos),
          HasValue(OwnedBy, { value: owner }),
          Not(TerrainType),
          Has(PrototypeCopy),
        ]),
      ][0];
      if (entity) {
        sacrificableEntities.push(entity);
      }
    }
    return sacrificableEntities;
  };

  const getSummonInputs = (summonerEntity: EntityIndex, summonIndex: EntityIndex, neighbors: EntityIndex[]) => {
    const summonID = world.entities[summonIndex];
    if (!summonID) return undefined;

    const summonerValue = getComponentValue(Summoner, summonerEntity);
    if (!summonerValue) return undefined;

    const summonerPosition = getComponentValue(Position, summonerEntity);
    if (!summonerPosition) return undefined;

    const summonValue = getComponentValue(SummonRecipe, summonIndex);
    if (!summonValue) return undefined;

    const foundIndices: number[] = [];
    const sacrificedEntities = neighbors.filter((entity) => {
      const entityPrototypeVal = getComponentValueStrict(PrototypeCopy, entity).value;
      const index = summonValue.sacrificedPrototypeIds.findIndex(
        (prototype, i) => entityPrototypeVal === prototype && !foundIndices.includes(i)
      );

      if (index !== -1) {
        foundIndices.push(index);
        return true;
      }
      return false;
    });

    if (sacrificedEntities.length != summonValue.sacrificedPrototypeIds.length) return undefined;

    if (summonValue.optionalResourceItemType != 0) {
      let foundItem = false;
      if (hasItem(summonerEntity, summonValue.optionalResourceItemType)) foundItem = true;
      for (const e of sacrificedEntities) {
        if (hasItem(e, summonValue.optionalResourceItemType)) foundItem = true;
      }

      if (!foundItem) return undefined;
    }

    return { summonId: summonID, sacrificedEntities: sacrificedEntities.map((ent) => world.entities[ent]) };
  };

  const canEscapePortal = (entity: EntityIndex, portalEntity: EntityIndex) => {
    if (!withinRange(entity, portalEntity)) return false;
    const escapePortalValue = getComponentValue(EscapePortal, portalEntity);
    if (!escapePortalValue) return false;

    return true;
  };

  const canTeleport = (entity: EntityIndex, portalEntity: EntityIndex) => {
    if (!hasComponent(UnitType, entity)) return false;
    if (hasComponent(OnCooldown, entity)) return false;

    const stamina = getCurrentStamina(entity);
    if (stamina < 1_000) return false;

    const portal = getComponentValue(Portal, portalEntity);
    if (!portal) return false;

    if (portal.targetIds.length == 0) return false;

    if (!withinRange(entity, portalEntity, portal.radius)) return false;

    return true;
  };

  const canGatherResource = (generator: EntityIndex, gatherer: EntityIndex) => {
    const gathererStamina = getCurrentStamina(gatherer);
    if (gathererStamina < 1_000) return false;

    const generatorStamina = getCurrentStamina(generator);
    if (generatorStamina < 1_000) return false;

    if (!withinRange(generator, gatherer)) return false;

    const inventory = getComponentValue(Inventory, gatherer);
    if (inventory == null) return false;

    const resourceGenerator = getComponentValue(ResourceGenerator, generator);
    if (!resourceGenerator) return false;

    return true;
  };

  const canGiveInventory = (inventoryOwnerEntity: EntityIndex, receiverEntity: EntityIndex) => {
    if (inventoryOwnerEntity === receiverEntity) return false;
    if (!withinRange(inventoryOwnerEntity, receiverEntity)) return false;

    const inventoryOwnerItems = getItems(inventoryOwnerEntity);
    if (inventoryOwnerItems.length === 0) return false;

    const receiverInventory = getInventory(receiverEntity);
    if (!receiverInventory || receiverInventory.isFull()) return false;

    return true;
  };

  const canTakeInventory = (inventoryOwnerEntity: EntityIndex, receiverEntity: EntityIndex) => {
    if (hasComponent(OwnedBy, inventoryOwnerEntity)) return false;
    return canGiveInventory(inventoryOwnerEntity, receiverEntity);
  };

  const canCharge = (charger: EntityIndex, chargee: EntityIndex) => {
    const stamina = getCurrentStamina(charger);
    if (stamina < 1_000) return false;

    const chargerOwnedBy = getComponentValue(OwnedBy, charger)?.value;
    const chargeeOwnedBy = getComponentValue(OwnedBy, chargee)?.value;
    if (chargeeOwnedBy != chargerOwnedBy) return false;

    const hasCharger = getComponentValue(Charger, charger);
    if (!hasCharger) return false;

    const chargerStamina = getComponentValue(Stamina, charger);
    if (!chargerStamina) return false;

    const chargeeStamina = getComponentValue(Stamina, charger);
    if (!chargeeStamina) return false;

    return true;
  };

  const canRangedAttack = (attacker: EntityIndex, defender: EntityIndex) => {
    const stamina = getCurrentStamina(attacker);
    if (stamina < 1_000) return false;

    const rangedCombat = getComponentValue(RangedCombat, attacker);
    if (!rangedCombat) return false;

    const distanceToDefender = distanceBetween(attacker, defender);
    if (!distanceToDefender) return;

    if (distanceToDefender > rangedCombat.maxRange || distanceToDefender < rangedCombat.minRange) return;

    const combat = getComponentValue(Combat, defender);
    if (!combat) return false;

    const attackerOwner = getComponentValue(OwnedBy, attacker);
    const defenderOwner = getComponentValue(OwnedBy, defender);

    if (!attackerOwner) return false;
    if (attackerOwner.value === defenderOwner?.value) return false;

    return true;
  };

  const canAttack = (attacker: EntityIndex, defender: EntityIndex) => {
    const stamina = getCurrentStamina(attacker);
    if (stamina < 1_000) return false;

    const OptimisticPosition = actions.withOptimisticUpdates(Position);

    const attackerOwner = getComponentValue(OwnedBy, attacker);
    const defenderOwner = getComponentValue(OwnedBy, defender);

    if (!attackerOwner) return false;
    if (attackerOwner.value === defenderOwner?.value) return false;

    const combat = getComponentValue(Combat, defender);
    if (!combat) return false;

    const attackerPosition = getComponentValue(OptimisticPosition, attacker);
    if (!attackerPosition) return;

    const defenderPosition = getComponentValue(OptimisticPosition, defender);
    if (!defenderPosition) return;

    const distanceToTarget = manhattan(attackerPosition, defenderPosition);

    const attackerRangedCombat = getComponentValue(RangedCombat, attacker);
    if (attackerRangedCombat) {
      if (distanceToTarget > attackerRangedCombat.maxRange || distanceToTarget < attackerRangedCombat.minRange)
        return false;
    } else {
      if (distanceToTarget > 1) return false;
    }

    return true;
  };

  const canRest = (entity: EntityIndex, entity2: EntityIndex) => {
    // hack to only have this happen when selecting and hovering over same unit
    if (entity !== entity2) return false;

    const stamina = getCurrentStamina(entity);
    if (stamina < 1_000) return false;

    const combat = getComponentValue(Combat, entity);
    if (!combat) return false;

    if (combat.health >= 100_000) return false;

    return true;
  };

  function getEntitiesInRange(from: WorldCoord, minRange: number, maxRange: number) {
    const entities = [];
    for (let y = from.y - maxRange; y <= from.y + maxRange; y++) {
      for (let x = from.x - maxRange; x <= from.x + maxRange; x++) {
        const distanceTo = manhattan(from, { x, y });
        if (distanceTo >= minRange && distanceTo <= maxRange) {
          const entity = [...runQuery([HasValue(Position, { x: x, y: y }), Not(TerrainType)])][0];
          if (entity) entities.push(entity);
        }
      }
    }
    return entities;
  }

  const getAttackableEntities = (attacker: EntityIndex) => {
    const attackerOwner = getComponentValue(OwnedBy, attacker);
    if (!attackerOwner) return false;

    const attackerPosition = getComponentValue(Position, attacker);
    if (!attackerPosition) return;

    const attackerRangedCombat = getComponentValue(RangedCombat, attacker);
    let entities;
    if (attackerRangedCombat) {
      entities = getEntitiesInRange(attackerPosition, attackerRangedCombat.minRange, attackerRangedCombat.maxRange);
    } else {
      entities = getEntitiesInRange(attackerPosition, 1, 1);
    }

    const attackableEntities: EntityIndex[] = [];
    for (const defender of entities) {
      const combat = getComponentValue(Combat, defender);
      if (!combat) continue;

      const defenderOwner = getComponentValue(OwnedBy, defender);
      if (attackerOwner.value === defenderOwner?.value) continue;

      attackableEntities.push(defender);
    }
    return attackableEntities;
  };

  const layer = {
    world,
    actions,
    parentLayers: { network },
    components,
    turn$,
    api: {
      joinGame: curry(joinGame)(network, actions),
      move: curry(move)({ world, actions, network, LocalStamina, getMovementDifficulty, isUntraversable }),
      attack: curry(attack)({ network, actions }),
      escapePortal: curry(escapePortal)({ network, actions, world }),
      buildAt: curry(buildAt)({ network, actions, world }),
      gatherResource: curry(gatherResource)({ network, actions, world }),
      charge: curry(charge)({ network, actions, world }),
      teleport: curry(teleport)({ network, actions, world }),
      dropInventory: curry(dropInventory)({ network, actions, world }),
      transferInventory: curry(transferInventory)({ network, actions, world }),
      rest: curry(rest)({ network, actions, world }),
      summon: curry(summon)({ network, actions, world }),

      canCharge,
      canGatherResource,
      canTakeInventory,
      canGiveInventory,
      canAttack,
      canRangedAttack,
      canRest,
      canEscapePortal,
      canTeleport,
      isUntraversable,
      getMovementDifficulty,
      getZOIValue,
      getSacrificableNeighbors,
      getSummonInputs,
      getAttackableEntities,

      unitSort,
      getCurrentStamina,

      combat: { calculateCombatResult, isPassive, isNeutralStructure, canRetaliate },
    },
  };

  createCurrentStaminaSystem(layer);
  createCooldownSystem(layer);

  return layer;
}
