import {
  ComponentValue,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  removeComponent,
  SchemaOf,
  setComponent,
} from "@latticexyz/recs";
import { findLast } from "lodash";
import { PhaserLayer } from "../..";
import { DecodedNetworkComponentUpdate } from "../../../../Network/types";
import { UnitTypeAttackAnimations, UnitTypeDeathAnimations } from "../../phaserConstants";

export function createCombatSystem(layer: PhaserLayer) {
  const {
    parentLayers: {
      network: {
        components: { UnitType, StructureType, Combat, Position },
        api: {
          systemDecoders: { onCombat },
        },
      },
      local: {
        components: { LocalHealth, LocalPosition },
      },
      headless: {
        api: {
          combat: { canRetaliate },
        },
      },
    },
    scenes: {
      Main: { objectPool },
    },
    animations: { triggerBloodSplatter, flashRed },
  } = layer;

  function playAttackAnimation(
    entity: EntityIndex,
    {
      onStart,
      onContact,
      onComplete,
    }: {
      onStart?: (sprite: Phaser.GameObjects.Sprite) => void;
      onContact?: (sprite: Phaser.GameObjects.Sprite) => void;
      onComplete?: (sprite: Phaser.GameObjects.Sprite) => void;
    }
  ) {
    const unitType = getComponentValueStrict(UnitType, entity).value;
    const attackAnimation = UnitTypeAttackAnimations[unitType];

    const embodiedObject = objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "attack-animation",
      now: (sprite) => {
        const previousAnim = sprite.anims.currentAnim;
        sprite.play(attackAnimation);

        let started = false;
        const onAttackUpdate = (anim: Phaser.Animations.Animation, frame: Phaser.Animations.AnimationFrame) => {
          if (anim.key !== attackAnimation) return;

          if (!started && onStart) {
            onStart(sprite);
            started = true;
          }

          if (frame.progress >= 1) sprite.play(previousAnim.key);
          if (onContact && frame.index === 5) onContact(sprite);
          if (onComplete && frame.progress >= 1) {
            onComplete(sprite);
            sprite.removeListener("animationupdate", onAttackUpdate);
          }
        };

        sprite.on(`animationupdate`, onAttackUpdate);
      },
    });
  }

  function playDeathAnimation(entity: EntityIndex, onDeath: () => void) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      onDeath();
      return;
    }

    const deathAnimation = UnitTypeDeathAnimations[unitType];

    const embodiedObject = objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "death-animation",
      now: (sprite) => {
        sprite.play(deathAnimation);
        sprite.on(`animationcomplete-${deathAnimation}`, () => {
          onDeath();
        });
      },
    });
  }

  function getCombatUpdate(updates: DecodedNetworkComponentUpdate[], entity: EntityIndex) {
    return findLast(updates, (update) => {
      return update.entity === entity && update.component.id === Combat.id;
    });
  }

  function entityDied(updates: DecodedNetworkComponentUpdate[], entity: EntityIndex) {
    const positionUpdate = findLast(updates, (u) => u.entity === entity && u.component.id === Position.id);
    return !!positionUpdate;
  }

  onCombat(({ combatants, updates, ranged }) => {
    const [attacker, defender] = combatants;

    const attackerPosition = getComponentValueStrict(LocalPosition, attacker);
    const defenderPosition = getComponentValueStrict(LocalPosition, defender);

    const attackerCombatUpdate = getCombatUpdate(updates, attacker);
    const defenderCombatUpdate = getCombatUpdate(updates, defender);

    const attackerTookDamage = attackerCombatUpdate && attackerCombatUpdate.value;
    const defenderTookDamage = defenderCombatUpdate && defenderCombatUpdate.value;

    const attackerDied = entityDied(updates, attacker);
    const defenderDied = entityDied(updates, defender);

    const defenderIsStructure = getComponentValue(StructureType, defender);
    const flipAttacker = defenderPosition.x < attackerPosition.x;
    const flipDefender = attackerPosition.x < defenderPosition.x;

    playAttackAnimation(attacker, {
      onStart: (sprite) => {
        sprite.flipX = flipAttacker;
      },
      onContact: () => {
        if (attackerDied) {
          setComponent(LocalHealth, attacker, { value: 0 });
        } else if (attackerTookDamage) {
          const attackerHealth = (attackerCombatUpdate.value as ComponentValue<SchemaOf<typeof Combat>>).health;
          setComponent(LocalHealth, attacker, { value: attackerHealth });
        }

        if (attackerDied || attackerTookDamage) {
          triggerBloodSplatter(attackerPosition);
          flashRed(attacker);
        }

        if (defenderDied) {
          setComponent(LocalHealth, defender, { value: 0 });
        } else if (defenderTookDamage) {
          const defenderHealth = (defenderCombatUpdate.value as ComponentValue<SchemaOf<typeof Combat>>).health;
          setComponent(LocalHealth, defender, { value: defenderHealth });
        }

        if (defenderDied || defenderTookDamage) {
          if (!defenderIsStructure) triggerBloodSplatter(defenderPosition);
          flashRed(defender);
        }
      },
      onComplete: (sprite) => {
        if (flipAttacker) sprite.flipX = false;

        if (attackerDied) {
          playDeathAnimation(attacker, () => {
            removeComponent(LocalHealth, attacker);
            removeComponent(LocalPosition, attacker);
          });
        }

        if (defenderDied) {
          playDeathAnimation(defender, () => {
            removeComponent(LocalHealth, defender);
            removeComponent(LocalPosition, defender);
          });
        }
      },
    });
    if (!ranged && canRetaliate(layer.parentLayers.network, defender))
      playAttackAnimation(defender, {
        onStart: (sprite) => {
          sprite.flipX = flipDefender;
        },
        onComplete: (sprite) => {
          if (flipDefender) sprite.flipX = false;
        },
      });
  });
}
