import { defineSystem, getComponentValueStrict, Has, setComponent, UpdateType } from "@latticexyz/recs";
import { Assets } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createConstrainCameraSystem(layer: PhaserLayer) {
  const {
    world,
    components: { MapBounds },
    parentLayers: {
      local: {
        components: { LocalPosition },
        singletonEntity,
      },
    },
    scenes: {
      Main: {
        camera,
        phaserScene,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  let maxX = -Infinity;
  let maxY = -Infinity;
  let minX = Infinity;
  let minY = Infinity;

  const edgeBufferPixels = 300;

  let backgroundTiles: Phaser.GameObjects.Group | undefined;
  let clouds: Phaser.GameObjects.Group | undefined;
  const BACKGROUND_TILE_SIZE = {
    x: 1500,
    y: 1000,
  };

  defineSystem(world, [Has(LocalPosition)], ({ type, entity }) => {
    if (type === UpdateType.Exit) return;

    const position = getComponentValueStrict(LocalPosition, entity);

    let boundsChanged = false;
    if (position.x > maxX) {
      boundsChanged = true;
      maxX = position.x;
    }
    if (position.y > maxY) {
      boundsChanged = true;
      maxY = position.y;
    }
    if (position.x < minX) {
      boundsChanged = true;
      minX = position.x;
    }
    if (position.y < minY) {
      boundsChanged = true;
      minY = position.y;
    }

    if (boundsChanged) {
      backgroundTiles?.clear(true);
      backgroundTiles = phaserScene.add.group();

      clouds?.clear(true);
      clouds = phaserScene.add.group();

      const leftEdge = minX * tileWidth - edgeBufferPixels;
      const topEdge = minY * tileHeight - edgeBufferPixels;
      const bottomEdge = (maxX + Math.abs(minX)) * tileWidth + edgeBufferPixels * 2;
      const rightEdge = (maxY + Math.abs(minY)) * tileWidth + edgeBufferPixels * 2;

      const cloudImage = phaserScene.add.image(leftEdge, topEdge, Assets.CloudBackground, 0);
      clouds.add(cloudImage, false);
      cloudImage.setX(leftEdge - cloudImage.width);
      cloudImage.setY(topEdge - cloudImage.height);
      cloudImage.setDepth(-8);
      cloudImage.setScrollFactor(0.5);
      phaserScene.add.tween({
        targets: cloudImage,
        x: {
          from: cloudImage.x + cloudImage.width,
          to: rightEdge,
        },
        y: {
          from: cloudImage.y + cloudImage.height,
          to: bottomEdge,
        },
        repeat: -1,
        yoyo: false,
        duration: 100_000,
        ease: Phaser.Math.Easing.Linear,
      });

      const cloudImage2 = phaserScene.add.image(leftEdge + 500, topEdge, Assets.CloudBackground, 0);
      clouds.add(cloudImage2, false);
      cloudImage2.setScale(0.6);
      cloudImage2.setX(leftEdge - cloudImage2.width);
      cloudImage2.setY(topEdge - cloudImage2.height);
      cloudImage2.setDepth(-9);
      cloudImage2.setScrollFactor(0.4);
      phaserScene.add.tween({
        targets: cloudImage2,
        x: {
          from: cloudImage2.x + cloudImage2.width,
          to: rightEdge,
        },
        y: {
          from: cloudImage2.y + cloudImage2.height,
          to: bottomEdge,
        },
        repeat: -1,
        yoyo: false,
        duration: 130_000,
        ease: Phaser.Math.Easing.Linear,
      });

      const cloudImage3 = phaserScene.add.image(leftEdge, topEdge + 500, Assets.CloudBackground, 0);
      clouds.add(cloudImage3, false);
      cloudImage3.setScale(0.3);
      cloudImage3.setX(leftEdge - cloudImage3.width);
      cloudImage3.setY(topEdge - cloudImage3.height);
      cloudImage3.setDepth(-10);
      cloudImage3.setScrollFactor(0.3);
      phaserScene.add.tween({
        targets: cloudImage3,
        x: {
          from: cloudImage3.x + cloudImage3.width,
          to: rightEdge,
        },
        y: {
          from: cloudImage3.y + cloudImage3.height,
          to: bottomEdge,
        },
        repeat: -1,
        yoyo: false,
        duration: 160_000,
        ease: Phaser.Math.Easing.Linear,
      });

      const constraintSize = {
        x: rightEdge - leftEdge,
        y: bottomEdge - topEdge,
      };
      const numXTiles = Math.ceil(constraintSize.x / BACKGROUND_TILE_SIZE.x) + 1;
      const numYTiles = Math.ceil(constraintSize.y / BACKGROUND_TILE_SIZE.y) + 1;

      for (let i = 0; i < numXTiles; i++) {
        for (let j = 0; j < numYTiles; j++) {
          const bgTile = phaserScene.add.image(
            i * BACKGROUND_TILE_SIZE.x - BACKGROUND_TILE_SIZE.x * 2,
            j * BACKGROUND_TILE_SIZE.y - BACKGROUND_TILE_SIZE.y * 2,
            Assets.TiledBackground,
            0
          );
          bgTile.setDepth(-12);
          bgTile.setScrollFactor(0.3);
          backgroundTiles.add(bgTile, false);
        }
      }

      camera.phaserCamera.setBounds(
        minX * tileWidth - edgeBufferPixels,
        minY * tileHeight - edgeBufferPixels,
        (maxX + Math.abs(minX)) * tileWidth + edgeBufferPixels * 2,
        (maxY + Math.abs(minY)) * tileWidth + edgeBufferPixels * 2
      );

      setComponent(MapBounds, singletonEntity, { top: topEdge, bottom: bottomEdge, left: leftEdge, right: rightEdge });
    }
  });
}
