import { EntityIndex, EntityID, World } from "@latticexyz/recs";
import { ActionSystem } from "@latticexyz/std-client";
import { NetworkLayer } from "../../Network";

export function gatherResource(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    world: World;
  },
  generator: EntityIndex,
  gatherer: EntityIndex
) {
  const {
    network: { api: networkApi },
    world,
    actions,
  } = context;

  const actionID = `gather resource ${Date.now()}` as EntityID; // Date.now to have the actions ordered in the component browser

  actions.add({
    id: actionID,
    components: {},
    on: generator,
    requirement: () => true,
    updates: () => [],
    execute: async () => {
      return networkApi.gatherResource(world.entities[generator], world.entities[gatherer]);
    },
  });
}
