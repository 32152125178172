import { HueTintAndOutlineFXPipeline, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { Has, getComponentValueStrict, UpdateType, defineSystem } from "@latticexyz/recs";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawHighlightCoordSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      local: { singletonEntity },
    },
    scenes: {
      Main: {
        objectPool,
        config,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    components: { HoverHighlight },
  } = layer;

  defineSystem(world, [Has(HoverHighlight)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      objectPool.remove(`${entity}-hover-highlight`);
      return;
    }

    const hoverHighlght = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlight = objectPool.get(`${entity}-hover-highlight`, "Sprite");
    if (hoverHighlght.x === undefined || hoverHighlght.y === undefined) return;
    const position = { x: hoverHighlght.x, y: hoverHighlght.y };

    const sprite = config.sprites[Sprites.SelectUI];

    highlight.setComponent({
      id: `highlight`,
      once: (box) => {
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

        box.setTexture(sprite.assetKey, sprite.frame);
        box.setSize(tileWidth, tileHeight);
        box.setPosition(pixelCoord.x, pixelCoord.y);
        box.setDepth(12);

        box.setPipeline(HueTintAndOutlineFXPipeline.KEY);
        box.setPipelineData("hueTint", hoverHighlght.color);
      },
    });
  });
}
