import { setComponent } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";

export function registerDevModeToggle(layer: PhaserLayer) {
  const {
    parentLayers: {
      local: {
        components: { DevMode },
        singletonEntity,
        api: { devModeEnabled },
      },
    },
    scenes: {
      Main: { input },
    },
  } = layer;

  input.onKeyPress(
    (keys) => keys.has("BACKTICK"),
    () => {
      setComponent(DevMode, singletonEntity, { value: !devModeEnabled() });
    }
  );
}
