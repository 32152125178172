import { NetworkComponentUpdate, SystemCall } from "@latticexyz/network";
import { Component, Components, EntityIndex, Schema } from "@latticexyz/recs";
import { SystemTypes } from "contracts/types/SystemTypes";
import { createNetworkLayer } from "./createNetworkLayer";

export type NetworkLayer = Awaited<ReturnType<typeof createNetworkLayer>>;
export type NetworkComponents = NetworkLayer["components"];

// Contract types
export enum ContractWorldEvent {
  ComponentValueSet = "ComponentValueSet",
  ComponentValueRemoved = "ComponentValueRemoved",
}

export type DecodedNetworkComponentUpdate = Omit<Omit<NetworkComponentUpdate, "entity">, "component"> & {
  entity: EntityIndex;
  component: Component<Schema>;
};

export type DecodedSystemCall<C extends Components = Components> = Omit<SystemCall<C>, "updates"> & {
  systemId: keyof SystemTypes;
  args: Record<string, unknown>;
  updates: DecodedNetworkComponentUpdate[];
};

export enum UnitTypes {
  Unknown,

  Swordsman,
  Pikeman,
  Golem,

  Rider,
  Knight,
  Dragon,

  Archer,
  Catapult,
  Wizard,
}

export const UnitTypeNames: Record<number, string> = {
  [UnitTypes.Unknown]: "Unknown",

  [UnitTypes.Swordsman]: "Swordsman",
  [UnitTypes.Pikeman]: "Pikeman",
  [UnitTypes.Golem]: "Golem",

  [UnitTypes.Archer]: "Archer",
  [UnitTypes.Catapult]: "Catapult",
  [UnitTypes.Wizard]: "Wizard",

  [UnitTypes.Rider]: "Rider",
  [UnitTypes.Knight]: "Knight",
  [UnitTypes.Dragon]: "Dragon",
};

export enum CombatTypes {
  Unknown,
  Neutral,
  Structure,

  Melee,
  Cavalry,
  Ranged,
}

export const CombatTypeNames: Record<number, string> = {
  [CombatTypes.Neutral]: "Neutral",
  [CombatTypes.Structure]: "Structure",
  [CombatTypes.Melee]: "Melee",
  [CombatTypes.Cavalry]: "Cavalry",
  [CombatTypes.Ranged]: "Ranged",
};

export enum StructureTypes {
  Unknown,
  Settlement,
  SpawnSettlement,
  GoldShrine,
  EscapePortal,
  Portal,
  Container,
  SummoningAltar,
  BlazingHeartShrine,
  WoodenWall,
  ChargingCrystal,
}

export const StructureTypeNames: Record<number, string> = {
  [StructureTypes.SpawnSettlement]: "Spawn Settlement",
  [StructureTypes.Settlement]: "Settlement",
  [StructureTypes.GoldShrine]: "Gold Shrine",
  [StructureTypes.EscapePortal]: "Escape Portal",
  [StructureTypes.Portal]: "Portal",
  [StructureTypes.Container]: "Container",
  [StructureTypes.SummoningAltar]: "Summoning Altar",
  [StructureTypes.BlazingHeartShrine]: "Blazing Heart Shrine",
  [StructureTypes.WoodenWall]: "Wooden Wall",
  [StructureTypes.ChargingCrystal]: "Charging Crystal",
};

export enum ItemTypes {
  Unknown,
  Gold,
  EmberCrown,
  BlazingHeart,
}

export const ItemTypeNames: Record<number, string> = {
  [ItemTypes.Gold]: "Gold",
  [ItemTypes.EmberCrown]: "Ember Crown",
  [ItemTypes.BlazingHeart]: "Blazing Heart",
};

export enum TerrainTypes {
  Unknown,
  Grass,
  Mountain,
  Water,
  Wall,
  Forest,
  StoneWall,
}

export const TerrainTypeNames: Record<number, string> = {
  [TerrainTypes.Grass]: "Grass",
  [TerrainTypes.Mountain]: "Mountain",
  [TerrainTypes.Water]: "Water",
  [TerrainTypes.Wall]: "Wall",
  [TerrainTypes.Forest]: "Forest",
};
