import React from "react";
import { registerUIComponent } from "../engine";
import { getComponentValue, Has, runQuery } from "@latticexyz/recs";
import { map, merge } from "rxjs";

export function registerGameOutcome() {
  registerUIComponent(
    "GameOutcomeWindow",
    {
      colStart: 5,
      colEnd: 12,
      rowStart: 6,
      rowEnd: 9,
    },
    (layers) => {
      const {
        network: {
          components: { Winner },
        },
      } = layers;

      return merge(Winner.update$).pipe(
        map(() => {
          const winner = [...runQuery([Has(Winner)])][0];
          if (!winner) return;

          return {
            layers,
          };
        })
      );
    },
    ({ layers }) => {
      const {
        network: {
          components: { Winner, OwnedBy, Name },
          world,
        },
        local: {
          api: { getOwnerColor },
        },
      } = layers;

      const winner = [...runQuery([Has(Winner)])][0];
      const owner = getComponentValue(OwnedBy, winner)?.value;
      if (!owner) return null;

      const ownerIndex = world.entityToIndex.get(owner);
      if (!ownerIndex) return null;

      const winnerName = getComponentValue(Name, ownerIndex)?.value;

      return (
        <div>
          <h1 style={{ fontSize: "5em", color: getOwnerColor(winner).toString(16) }}>{winnerName} wins!</h1>
        </div>
      );
    }
  );
}
