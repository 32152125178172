import { EntityIndex, EntityID, World } from "@latticexyz/recs";
import { ActionSystem } from "@latticexyz/std-client";
import { Coord } from "@latticexyz/utils";
import { NetworkLayer } from "../../Network";

export function summon(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    world: World;
  },
  summonerEntity: EntityIndex,
  summonID: EntityID,
  sacrificedEntities: EntityID[],
  summonPosition: Coord
) {
  const {
    network: { api: networkApi },
    world,
    actions,
  } = context;

  const actionID = `summon ${Date.now()}` as EntityID; // Date.now to have the actions ordered in the component browser

  actions.add({
    id: actionID,
    components: {},
    on: summonerEntity,
    requirement: () => true,
    updates: () => [],
    execute: async () => {
      return networkApi.summon(world.entities[summonerEntity], summonID, sacrificedEntities, summonPosition);
    },
  });
}
