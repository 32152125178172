import { createPhaserEngine, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { EntityIndex, getComponentValue } from "@latticexyz/recs";
import { Coord } from "@latticexyz/utils";
import { LocalLayer } from "../../../Local";
import { Sprites, UnitTypeDeathAnimations } from "../phaserConstants";

type Scenes = Awaited<ReturnType<typeof createPhaserEngine>>["scenes"];

function createTriggerBloodSplatter(scenes: Scenes) {
  const {
    Main: {
      config,
      phaserScene,
      maps: {
        Main: { tileHeight, tileWidth },
      },
    },
  } = scenes;

  const bloodSplatterSprite = config.sprites[Sprites.OrangeTick];
  const particleManager = phaserScene.add.particles(bloodSplatterSprite.assetKey, bloodSplatterSprite.frame);
  particleManager.setDepth(100);

  return (coord: Coord) => {
    const emitter = particleManager.createEmitter({
      scale: {
        start: 1.5,
        end: 0.3,
      },
      speed: {
        min: -200,
        max: 200,
      },
      gravityY: 400,
      lifespan: 200,
      blendMode: Phaser.BlendModes.SCREEN,
      deathCallback: () => particleManager.removeEmitter(emitter),
    });

    const pos = tileCoordToPixelCoord(coord, tileWidth, tileHeight);
    emitter.setBounds(pos.x - 6, pos.y, tileWidth + 12, tileHeight);
    emitter.explode(8, pos.x + tileWidth / 2, pos.y + tileHeight / 2);
  };
}

function createFlashRed(scenes: Scenes) {
  const {
    Main: { objectPool },
  } = scenes;

  return (entity: EntityIndex) => {
    const embodiedObject = objectPool.get(entity, "Sprite");

    embodiedObject.setComponent({
      id: "flash-red",
      now: (sprite) => {
        sprite.setTint(0xb00b1e);

        setTimeout(() => sprite.clearTint(), 125);
      },
    });
  };
}

function createPlayDeathAnimation(scenes: Scenes, localLayer: LocalLayer) {
  const { UnitType } = localLayer.parentLayers.network.components;

  return function (entity: EntityIndex, onDeath: () => void) {
    const unitType = getComponentValue(UnitType, entity)?.value;
    if (!unitType) {
      onDeath();
      return;
    }

    const deathAnimation = UnitTypeDeathAnimations[unitType];

    const embodiedObject = scenes.Main.objectPool.get(entity, "Sprite");
    embodiedObject.setComponent({
      id: "death-animation",
      now: (sprite) => {
        sprite.play(deathAnimation);
        sprite.on(`animationcomplete-${deathAnimation}`, () => {
          onDeath();
        });
      },
    });
  };
}

export function createAnimations(scenes: Scenes, localLayer: LocalLayer) {
  return {
    flashRed: createFlashRed(scenes),
    playDeathAnimation: createPlayDeathAnimation(scenes, localLayer),
    triggerBloodSplatter: createTriggerBloodSplatter(scenes),
  };
}
