import React from "react";
import { registerUIComponent } from "../engine";
import {
  EntityID,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  runQuery,
} from "@latticexyz/recs";
import { getOwningPlayer, getPlayerEntity } from "@latticexyz/std-client";
import { map, merge } from "rxjs";
import { Row, Section, COLORS, Button } from "./Theme";
import styled from "styled-components";
import { StructureTypes, TerrainTypeNames } from "../../../Network/types";
import { getCombatStrengthModiferAtPosition, getCombatStrengthModiferFromItems } from "../../../Headless/utils";
import { computedToStream } from "@latticexyz/utils";

const StatLabel = styled.span`
  text-transform: uppercase;
  font-size: 0.8em;
`;

const StatSection = styled.div`
  flex-basis: 26%;
`;

export function registerSelection() {
  registerUIComponent(
    "SelectedCoords",
    {
      rowStart: 10,
      rowEnd: 13,
      colStart: 8,
      colEnd: 9,
    },
    (layers) => {
      const {
        local: {
          components: { Selection, Selected, LocalName, LocalPosition },
          api: { getOwnerColor },
          singletonEntity,
        },
        headless: {
          components: { LocalStamina },
          actions: { withOptimisticUpdates },
        },
        network: {
          world,
          components: {
            OwnedBy,
            ItemType,
            Stamina,
            StaminaRegenerationCap,
            Combat,
            Player,
            RangedCombat,
            Portal,
            ResourceGenerator,
            Position,
            TerrainType,
            CombatStrengthModifier,
            Name,
            StructureType,
            ChargedBy,
          },
          network: {
            connectedAddress,
          },
        },
        phaser: {
          components: { HoverHighlight },
        },
      } = layers;

      const OptimisticStamina = withOptimisticUpdates(Stamina);

      return merge(
        Selected.update$,
        Selection.update$,
        OwnedBy.update$,
        OptimisticStamina.update$,
        StaminaRegenerationCap.update$,
        Combat.update$,
        HoverHighlight.update$,
        Player.update$,
        computedToStream(connectedAddress),
      ).pipe(
        map(() => {
          if(!connectedAddress.get()) return;
          const address = connectedAddress.get();
          const playerEntity = getPlayerEntity(address, world, Player);
          if(playerEntity == undefined) return;

          const selection = getComponentValue(Selection, singletonEntity);
          const selectedEntity = [...runQuery([Has(Selected)])][0];
          const owningPlayer = getOwningPlayer(selectedEntity, world, Player, OwnedBy);

          let ownerName: string | undefined;
          let ownerColor = 0xffffff;
          if (owningPlayer != undefined) {
            ownerName = getComponentValue(Name, owningPlayer)?.value;
            ownerColor = getOwnerColor(selectedEntity);
          }

          const selectedEntityPosition = getComponentValue(LocalPosition, selectedEntity);

          let name = getComponentValue(LocalName, selectedEntity)?.value;
          if (hasComponent(StructureType, selectedEntity)) {
            const structureType = getComponentValueStrict(StructureType, selectedEntity).value;
            if (structureType === StructureTypes.Container) {
              const inventoryItems = [
                ...runQuery([HasValue(OwnedBy, { value: world.entities[selectedEntity] }), Has(ItemType)]),
              ];
              name = getComponentValue(LocalName, inventoryItems[0])?.value;
            }
          }

          const stamina = getComponentValue(OptimisticStamina, selectedEntity);
          const localStamina = getComponentValue(LocalStamina, selectedEntity);
          const hasStamina = stamina && localStamina;
          let currentStamina = 0;
          if (hasStamina) {
            currentStamina = localStamina.current + stamina.current;

            if (hasComponent(ChargedBy, selectedEntity)) {
              stamina.regeneration += 500;
            }
          }

          const staminaCap = getComponentValue(StaminaRegenerationCap, selectedEntity);
          let totalStaminaRegenerated = 0;
          if (hasStamina && staminaCap) {
            totalStaminaRegenerated = staminaCap.totalRegenerated + localStamina.current;
          }

          const combat = getComponentValue(Combat, selectedEntity);
          const rangedCombat = getComponentValue(RangedCombat, selectedEntity);

          const portal = getComponentValue(Portal, selectedEntity);
          const portalExitId = portal?.targetIds[0];
          let portalExit: EntityIndex | undefined;
          if (portalExitId) portalExit = world.entityToIndex.get(portalExitId as EntityID);

          const resourceGenerator = getComponentValue(ResourceGenerator, selectedEntity);

          const hoverPosition = getComponentValue(HoverHighlight, singletonEntity);

          const terrainPosition = selectedEntityPosition || hoverPosition;
          const terrain = [
            ...runQuery([HasValue(Position, { x: terrainPosition?.x, y: terrainPosition?.y }), Has(TerrainType)]),
          ][0];
          let terrainName: string | undefined;
          let terrainStrengthModifier: number | undefined;
          if (terrain) {
            const terrainType = getComponentValueStrict(TerrainType, terrain);
            terrainName = TerrainTypeNames[terrainType.value];
            terrainStrengthModifier = getComponentValue(CombatStrengthModifier, terrain)?.value;
          }

          return {
            layers,

            selection,
            selectedEntity,

            owningPlayer,
            ownerName,
            ownerColor,

            name,
            hasStamina,
            stamina: {
              ...stamina,
              current: currentStamina,
            },
            staminaCap: {
              ...staminaCap,
              totalRegenerated: totalStaminaRegenerated,
            },
            terrain: {
              name: terrainName,
              strengthModifier: terrainStrengthModifier,
            },
            combat,
            rangedCombat,
            hoverPosition,
            resourceGenerator,
            portalExit,
          };
        })
      );
    },
    ({
      layers,
      selectedEntity,
      ownerName,
      ownerColor,
      name,
      hasStamina,
      stamina,
      staminaCap,
      combat,
      rangedCombat,
      portalExit,
      terrain,
    }) => {
      const {
        local: {
          components: { LocalPosition },
        },
        phaser: {
          api: { selectAndView },
          ui: {isLargeScreen}
        },
      } = layers;
      const bigScreen = isLargeScreen();
      const height = bigScreen ? 200 : 150;
      const width = bigScreen ? 400 : 220;
      const horizontalOffset = bigScreen ? 100 : 130;
      const fontSize = bigScreen ? "1.2em" : "0.7em";

      const strBonusAtPosition =
        selectedEntity && hasComponent(LocalPosition, selectedEntity)
          ? getCombatStrengthModiferAtPosition(layers.network, getComponentValueStrict(LocalPosition, selectedEntity))
          : 0;
      const strBonusFromItems = selectedEntity ? getCombatStrengthModiferFromItems(layers.network, selectedEntity) : 0;

      return (
        <Section
          style={{
            height: `${height}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: "16px",
            margin: 0,
            position: 'absolute',
            bottom: 0,
            left: `calc(50vw + ${horizontalOffset}px)`,
            width: `${width}px`,
            fontSize: fontSize,
          }}
        >
          <Row style={{ marginBottom: "8px", alignItems: "center" }}>
            <div style={{maxWidth: "60%"}}>
              {ownerName && <span style={{ color: ownerColor.toString(16), lineHeight: "0.2em" }}>{ownerName}'s</span>}
              <h3 style={{ fontSize: "2em", color: COLORS.TITLE_YELLOW }}>{name}</h3>
            </div>

            {terrain.name && (
              <div>
                <StatLabel>Terrain</StatLabel>
                <p style={{ color: COLORS.TITLE_YELLOW }}>
                  {" "}
                  {terrain.name}{" "}
                  {terrain.strengthModifier && (
                    <span style={{ color: "green" }}>+{terrain.strengthModifier / 1000}</span>
                  )}
                </p>
              </div>
            )}
          </Row>

          <Row style={{ justifyContent: "space-between" }}>
            {portalExit && (
              <StatSection>
                <Button
                  onClick={() => {
                    selectAndView(portalExit);
                  }}
                >
                  View Exit
                </Button>
              </StatSection>
            )}

            <div>
              {combat && (
                <StatSection>
                  <StatLabel>Health Points</StatLabel>
                  <p style={{ color: COLORS.TITLE_YELLOW }}>{Math.round(combat.health / 1000)} / 100</p>
                </StatSection>
              )}
              {hasStamina && stamina.regeneration && (
                <StatSection>
                  <StatLabel>Action Points</StatLabel>
                  <p style={{ color: COLORS.TITLE_YELLOW }}>
                    {stamina.current / 1000} / {stamina.max && stamina.max / 1000} (+{stamina.regeneration / 1000})
                  </p>
                </StatSection>
              )}
            </div>

            <div>
              {combat && (
                <StatSection>
                  <StatLabel>Strength</StatLabel>
                  <p style={{ color: COLORS.TITLE_YELLOW }}>
                    {combat.strength / 1000}

                    {strBonusAtPosition !== 0 && (
                      <span style={{ color: "green" }}>{` + ${strBonusAtPosition / 1000}`}</span>
                    )}
                    {strBonusFromItems !== 0 && (
                      <span style={{ color: "yellow" }}>{` + ${strBonusFromItems / 1000}`}</span>
                    )}
                  </p>
                </StatSection>
              )}

              {rangedCombat && (
                <StatSection>
                  <StatLabel>Ranged Strength</StatLabel>
                  <p style={{ color: COLORS.TITLE_YELLOW }}>
                    {rangedCombat.strength / 1000}

                    {strBonusAtPosition !== 0 && (
                      <span style={{ color: "green" }}>{` + ${strBonusAtPosition / 1000}`}</span>
                    )}
                    {strBonusFromItems !== 0 && (
                      <span style={{ color: "yellow" }}>{` + ${strBonusFromItems / 1000}`}</span>
                    )}
                  </p>
                </StatSection>
              )}
            </div>

            {hasStamina && staminaCap.cap && (
              <StatSection>
                <StatLabel>Action Points Remaining</StatLabel>
                <p style={{ color: COLORS.TITLE_YELLOW }}>{(staminaCap.cap - staminaCap.totalRegenerated) / 1000}</p>
              </StatSection>
            )}
          </Row>
        </Section>
      );
    }
  );
}
