import { HueTintAndOutlineFXPipeline } from "@latticexyz/phaserx";
import {
  defineQuery,
  defineRxSystem,
  getComponentValue,
  getComponentValueStrict,
  Has,
  HasValue,
  NotValue,
  runQuery,
} from "@latticexyz/recs";
import { from, fromEvent, merge } from "rxjs";
import { StructureTypes } from "../../../../Network";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawUnitManager(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { OwnedBy, StructureType },
      },
      local: {
        components: { LocalPosition, Selected, Alert },
        api: { onPlayerLoaded },
      },
      headless: {
        api: { getCurrentStamina, unitSort },
      },
    },
    components: { Appearance },
    scenes: {
      Main: { config },
      UI: { phaserScene },
    },
    api: {
      selectAndView,
      mapInteraction: { disableMapInteraction, enableMapInteraction },
    },
    ui: { isLargeScreen },
  } = layer;

  onPlayerLoaded(({ playerId, playerColor }) => {
    const spriteGroup = phaserScene.add.group();
    let backgroundRectangle: Phaser.GameObjects.Rectangle | undefined;
    let highlightRectangle: Phaser.GameObjects.Rectangle | undefined;

    const reset = () => {
      spriteGroup.clear(true);
      backgroundRectangle?.destroy();
      highlightRectangle?.destroy();
    };

    const query = defineQuery([HasValue(OwnedBy, { value: playerId }), Has(LocalPosition)]);
    const update$ = merge(
      from([1]),
      query.update$,
      Selected.update$,
      Alert.update$,
      fromEvent(window, "resize"),
      fromEvent(window, "fullscreenchange")
    );

    defineRxSystem(world, update$, () => {
      reset();

      const gameCanvas = phaserScene.sys.game.canvas;
      const HEIGHT = isLargeScreen() ? 200 : 150;
      const WIDTH = isLargeScreen() ? 400 : 260;
      const HORIZONTAL_OFFSET = isLargeScreen() ? 100 : 0;

      const UNIT_SPRITE_SIZE = isLargeScreen() ? 40 : 28;
      const UNIT_PADDING = isLargeScreen() ? 8 : 4;
      const UNIT_SIZE = UNIT_SPRITE_SIZE + UNIT_PADDING * 2;
      const UNITS_PER_ROW = 7;
      const MAX_UNITS_DISPLAYED = UNITS_PER_ROW * 4;
      const position = {
        x: gameCanvas.width / 2 - WIDTH / 2,
        y: gameCanvas.height - HEIGHT,
      };

      const allAlerts = [...runQuery([Has(Alert)])];

      const allUnits = [
        ...runQuery([
          HasValue(OwnedBy, { value: playerId }),
          Has(LocalPosition),
          NotValue(StructureType, { value: StructureTypes.SummoningAltar }),
        ]),
      ];
      allUnits.sort(unitSort);

      backgroundRectangle = phaserScene.add.rectangle(
        position.x + WIDTH / 2 - HORIZONTAL_OFFSET,
        position.y + HEIGHT / 2,
        WIDTH,
        HEIGHT,
        0x24131a
      );
      backgroundRectangle.setStrokeStyle(3, 0x8a5e3b, 0.8);
      backgroundRectangle.setScrollFactor(0);
      backgroundRectangle.setInteractive();
      fromEvent(backgroundRectangle, Phaser.Input.Events.GAMEOBJECT_POINTER_OVER).subscribe(() =>
        disableMapInteraction()
      );
      fromEvent(backgroundRectangle, Phaser.Input.Events.GAMEOBJECT_POINTER_OUT).subscribe(() =>
        enableMapInteraction()
      );

      for (let i = 0; i < allUnits.length; i++) {
        if (i > MAX_UNITS_DISPLAYED) break;

        const currentRow = Math.floor(i / UNITS_PER_ROW);
        const currentColumn = i % UNITS_PER_ROW;

        const unit = allUnits[i];
        const appearance = getComponentValue(Appearance, unit);
        if (!appearance) continue;

        const spriteConfig = config.sprites[appearance.value as Sprites];
        const sprite = phaserScene.add.sprite(
          position.x + currentColumn * UNIT_SIZE + UNIT_SIZE / 2 - HORIZONTAL_OFFSET,
          position.y + currentRow * UNIT_SIZE + UNIT_SIZE / 2,
          spriteConfig.assetKey,
          spriteConfig.frame
        );
        spriteGroup.add(sprite);

        sprite.setScrollFactor(0);
        sprite.setScale(UNIT_SPRITE_SIZE / sprite.width);
        sprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
        sprite.setPipelineData("hueTint", playerColor);
        sprite.setDepth(36);
        sprite.setInteractive();
        merge(fromEvent(sprite, Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN)).subscribe(() => {
          selectAndView(unit);
        });

        const foundAlert = allAlerts.find((e) => getComponentValueStrict(Alert, e).on === world.entities[unit]);
        if (foundAlert) {
          const swordSpriteConfig = config.sprites[Sprites.CrossedSwords];
          const swordSprite = phaserScene.add.sprite(
            position.x + currentColumn * UNIT_SIZE + UNIT_SIZE / 2,
            position.y + currentRow * UNIT_SIZE + UNIT_SIZE / 2 + UNIT_PADDING,
            swordSpriteConfig.assetKey,
            swordSpriteConfig.frame
          );
          swordSprite.setDepth(42);
          swordSprite.setScrollFactor(0);
          swordSprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
          swordSprite.setPipelineData("hueTint", 0xb00b1e);
          phaserScene.add.tween({
            targets: swordSprite,
            alpha: {
              from: 0.6,
              to: 1,
            },
            duration: 300,
            yoyo: true,
            repeat: -1,
          });
          spriteGroup.add(swordSprite);
        }

        const currentStamina = getCurrentStamina(unit);
        if (currentStamina < 1000) {
          sprite.setTint(0x696969);
        }

        const selected = getComponentValue(Selected, unit);
        if (selected) {
          highlightRectangle = phaserScene.add.rectangle(
            position.x + currentColumn * UNIT_SIZE + UNIT_SIZE / 2 - HORIZONTAL_OFFSET,
            position.y + currentRow * UNIT_SIZE + UNIT_SIZE / 2,
            UNIT_SPRITE_SIZE,
            UNIT_SPRITE_SIZE,
            0xffff00,
            0.3
          );
          highlightRectangle.setDepth(24);
          highlightRectangle.setScrollFactor(0);
        }
      }
    });
  });
}
