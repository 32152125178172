import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { WorldCoord } from "@latticexyz/phaserx/src/types";
import {
  defineRxSystem,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  Not,
  runQuery,
} from "@latticexyz/recs";
import { reverse } from "lodash";
import { isPassive } from "../../../../Headless/utils";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawCombatPreviewSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { TerrainType, Zone, Combat, OwnedBy },
      },
      headless: {
        api: {
          canAttack,
          canRangedAttack,
          combat: { calculateCombatResult },
        },
      },
      local: {
        components: { LocalPosition, Selected },
        singletonEntity,
      },
    },
    components: { HoverHighlight },
    scenes: {
      Main: {
        objectPool,
        config,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  function drawPercent(entity: EntityIndex, percent: number, x: number, y: number, color: "green" | "red") {
    const percentSprite = config.sprites[color === "red" ? Sprites.PercentRed : Sprites.PercentGreen];
    const percentObject = objectPool.get(`combat-preview-percent-${color}`, "Sprite");
    percentObject.setComponent({
      id: "percent",
      once: (sprite) => {
        sprite.setTexture(percentSprite.assetKey, percentSprite.frame);
        sprite.setPosition(x, y);
        sprite.setDepth(86);
      },
    });

    const percentText = reverse(percent.toString().split(""));
    for (let i = percentText.length - 1; i >= 0; i--) {
      const digit = percentText[i];

      let sprite = config.sprites[Sprites.ZeroGreen];
      switch (digit) {
        case "0":
          sprite = config.sprites[color === "red" ? Sprites.ZeroRed : Sprites.ZeroGreen];
          break;
        case "1":
          sprite = config.sprites[color === "red" ? Sprites.OneRed : Sprites.OneGreen];
          break;
        case "2":
          sprite = config.sprites[color === "red" ? Sprites.TwoRed : Sprites.TwoGreen];
          break;
        case "3":
          sprite = config.sprites[color === "red" ? Sprites.ThreeRed : Sprites.ThreeGreen];
          break;
        case "4":
          sprite = config.sprites[color === "red" ? Sprites.FourRed : Sprites.FourGreen];
          break;
        case "5":
          sprite = config.sprites[color === "red" ? Sprites.FiveRed : Sprites.FiveGreen];
          break;
        case "6":
          sprite = config.sprites[color === "red" ? Sprites.SixRed : Sprites.SixGreen];
          break;
        case "7":
          sprite = config.sprites[color === "red" ? Sprites.SevenRed : Sprites.SevenGreen];
          break;
        case "8":
          sprite = config.sprites[color === "red" ? Sprites.EightRed : Sprites.EightGreen];
          break;
        case "9":
          sprite = config.sprites[color === "red" ? Sprites.NineRed : Sprites.NineGreen];
          break;
      }

      const digitObject = objectPool.get(`combat-preview-digit-${color}-${i}`, "Sprite");
      digitObject.setComponent({
        id: `digit`,
        once: (digitSprite) => {
          digitSprite.setTexture(sprite.assetKey, sprite.frame);
          digitSprite.setPosition(x - (i + 1) * 5 - 1, y);
          digitSprite.setDepth(86);
        },
      });
    }
  }

  defineRxSystem(world, HoverHighlight.update$, () => {
    const attacker = [...runQuery([Has(Selected)])][0];

    const hoverHighlight = getComponentValueStrict(HoverHighlight, singletonEntity);
    const defender = [
      ...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType), Not(Zone)]),
    ][0];

    const attackerOwner = getComponentValue(OwnedBy, attacker)?.value;
    const defenderOwner = getComponentValue(OwnedBy, defender)?.value;

    if (
      (isPassive(layer.parentLayers.network, attacker) && !canRangedAttack(attacker, defender)) ||
      attackerOwner === defenderOwner ||
      attacker === defender ||
      !hasComponent(Combat, attacker) ||
      !hasComponent(Combat, defender) ||
      (!canAttack(attacker, defender) && !canRangedAttack(attacker, defender))
    ) {
      objectPool.remove(`combat-preview-background`);
      objectPool.remove(`combat-preview-digit-green-0`);
      objectPool.remove(`combat-preview-digit-green-0`);
      objectPool.remove(`combat-preview-digit-green-1`);
      objectPool.remove(`combat-preview-digit-green-2`);
      objectPool.remove(`combat-preview-percent-green`);
      objectPool.remove(`combat-preview-digit-red-0`);
      objectPool.remove(`combat-preview-digit-red-1`);
      objectPool.remove(`combat-preview-digit-red-2`);
      objectPool.remove(`combat-preview-percent-red`);
      return;
    }

    const defenderPosition = getComponentValueStrict(LocalPosition, defender);
    const defenderPixelCoord = tileCoordToPixelCoord(defenderPosition, tileWidth, tileHeight);

    const background = objectPool.get("combat-preview-background", "Sprite");
    background.setComponent({
      id: "combat-preview-background",
      once: (obj) => {
        const sprite = config.sprites[Sprites.CombatPreview];
        obj.setTexture(sprite.assetKey, sprite.frame);
        obj.setPosition(defenderPixelCoord.x - tileWidth / 2, defenderPixelCoord.y - tileHeight - 12);
        obj.setDepth(80);
      },
    });

    const combatResult = calculateCombatResult(
      layer.parentLayers.network,
      attacker,
      defender,
      canRangedAttack(attacker, defender)
    );

    drawPercent(
      attacker,
      Math.round(combatResult.attackerDamage),
      defenderPixelCoord.x + tileWidth + 4,
      defenderPixelCoord.y - tileHeight - 7,
      "green"
    );

    drawPercent(
      defender,
      Math.round(combatResult.defenderDamage),
      defenderPixelCoord.x + tileWidth + 4,
      defenderPixelCoord.y - tileHeight + 6,
      "red"
    );
  });
}
