import React from "react";
import { registerUIComponent } from "../engine";
import { map, merge } from "rxjs";
import { Section, SectionTitle } from "./Theme";
import { getComponentValue, getComponentValueStrict, Has, HasValue, runQuery } from "@latticexyz/recs";
import Color from "color";

export function registerLeaderboard() {
  registerUIComponent(
    "Leaderboard",
    {
      colStart: 1,
      colEnd: 3,
      rowStart: 1,
      rowEnd: 2,
    },
    (layers) => {
      const {
        network: {
          components: { Player, Combat, OwnedBy },
        },
      } = layers;

      return merge(Player.update$, OwnedBy.update$, Combat.update$).pipe(
        map(() => {
          return {
            layers,
            world: layers.network.world,
          };
        })
      );
    },
    ({ layers, world }) => {
      const {
        network: {
          components: { Player, Combat, OwnedBy, Name, UnitType },
          network: { connectedAddress },
        },
        local: {
          api: {
            getOwnerColor,
          },
        },
      } = layers;

      const allPlayers = runQuery([Has(Player)]);
      const playerStrengths = [];
      for (const player of allPlayers) {
        const units = [...runQuery([HasValue(OwnedBy, { value: world.entities[player] }), Has(UnitType), Has(Combat)])];
        const numberOfUnits = units.length;
        const combinedStrength = units.reduce((total, unit) => {
          return total + getComponentValueStrict(Combat, unit).strength;
        }, 0);
        const isSelf = connectedAddress.get() === world.entities[player];

        playerStrengths.push({
          player,
          combinedStrength,
          numberOfUnits,
          isSelf,
        });
      }

      playerStrengths.sort((p1, p2) => p2.combinedStrength - p1.combinedStrength);

      return (
        <Section style={{
          maxWidth: "100%",
          maxHeight: "400%",
        }}>
          <SectionTitle style={{ marginBottom: "8px" }}>Leaderboard</SectionTitle>
          {playerStrengths.map((p) => {
            const playerName = getComponentValue(Name, p.player)?.value;
            if(!playerName) return;
            const playerColor = getOwnerColor(p.player);

            return (
              <div
                key={`${p.player}`}
                style={{
                  fontWeight: `${p.isSelf ? "bolder" : "normal"}`,
                  backgroundColor: Color(playerColor).darken(0.8).toString(),
                  padding: '4px',
                  display: "flex",
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                }}
              >
                <span style={{ color: playerColor.toString(16) }}>{playerName}:{" "}</span>
                <span style={{ color:`${p.isSelf ? "white" : Color("white").darken(0.3).toString()}`, }}>{p.combinedStrength / 1000} Strength - {p.numberOfUnits} Army Size</span>
              </div>
            );
          })}
        </Section>
      );
    }
  );
}
