import { callWithRetry, keccak256 } from "@latticexyz/utils";
import { BigNumber } from "ethers";
import { NetworkLayer } from "../types";
import { defaultAbiCoder as abi } from "ethers/lib/utils";
import { chunk, uniq } from "lodash";

interface Component {
  name: string;
  unhashedId: string;
  encoding: string[];
}

interface ECSEvent {
  componentIndex: number; // index into component array passed into bulk function, NOT component ID
  entityIndex: number; // index into entity array passed into bulk function, NOT entity ID
  unencodedValue: unknown[] | string;
}

export interface ECSMap {
  width: number;
  height: number;
  components: Component[];
  state: ECSEvent[];
}

const STATE_UPDATES_PER_TX = 25;

export async function bulkUploadMap(layer: NetworkLayer, map: ECSMap, onStatusUpdate: (msg: string) => void) {
  const components = [];
  const entities = [];
  const state = [];

  let uploadEntityIndex = -1;

  for (let stateIndex = 0; stateIndex < map.state.length; stateIndex++) {
    const { componentIndex, entityIndex, unencodedValue } = map.state[stateIndex];
    const component = map.components[componentIndex];
    const componentId = BigNumber.from(keccak256(component.unhashedId));
    let uploadComponentIndex = components.findIndex((obj) => obj._hex === componentId._hex);
    if (uploadComponentIndex === -1) {
      uploadComponentIndex = components.push(componentId) - 1;
    }

    // we assume that states are sorted by entity index, they can only ascend or stay the same
    // and since entities must be unique, we only add new ones when the index increments
    if (entityIndex > uploadEntityIndex) {
      uploadEntityIndex = entities.push(BigNumber.from(entityIndex)) - 1;
    }
    const value =
      typeof unencodedValue === "string"
        ? abi.encode(["uint256"], [keccak256(unencodedValue)])
        : abi.encode(component.encoding, unencodedValue);
    state.push({
      component: uploadComponentIndex,
      entity: uploadEntityIndex,
      value,
    });
  }

  const chunkedState = chunk(state, STATE_UPDATES_PER_TX);
  for (const stateChunk of chunkedState) {
    const uniqueEntities = uniq(stateChunk.map((s) => s.entity));

    const relevantEntities = [];
    for (let i = 0; i < uniqueEntities.length; i++) {
      relevantEntities[i] = entities[uniqueEntities[i]];
    }

    for (let i = 0; i < stateChunk.length; i++) {
      const index = uniqueEntities.findIndex((uniqueIndex) => uniqueIndex === stateChunk[i].entity);
      stateChunk[i].entity = index;
    }

    await callWithRetry(
      layer.systems["system.BulkSetState"].executeTyped,
      [components, relevantEntities, stateChunk, { gasLimit: 15_000_000 }],
      3
    );
  }
}
