import React, { useState } from "react";
import { registerUIComponent } from "../../engine";
import { EntityID, EntityIndex, getComponentValue, Has, hasComponent, HasValue, Not, runQuery } from "@latticexyz/recs";
import { map, merge } from "rxjs";
import { computedToStream } from "@latticexyz/utils";
import { Button, Input, Row } from "../Theme";
import { Layers } from "../../engine/types";
import { compact, groupBy } from "lodash";
import { getGameConfig } from "@latticexyz/std-client";

const JoinGameContainer = ({ layers }: { layers: Layers }) => {
  const {
    headless: {
      api: { joinGame },
      actions: { Action },
    },
    network: {
      world,
      components: { Player, SpawnPoint, OwnedBy, Zone, Position, Name, GameConfig },
      utils: { findClosest },
    },
  } = layers;

  const spawnAction = [...runQuery([Has(Action)])].find((i) => world.entities[i].includes("spawn"));

  const gameConfig = getGameConfig(world, GameConfig);
  const freeSpawn = gameConfig?.freeSpawn === 1;

  const [playerName, setPlayerName] = useState("");
  const allPlayers = runQuery([Has(Player)]);
  const allPlayerNames = compact([...allPlayers.values()].map((player) => getComponentValue(Name, player)?.value));
  const availableSpawns = runQuery([Has(SpawnPoint), Not(OwnedBy), Has(Position)]);

  const nameTaken = allPlayerNames.includes(playerName);

  const allZones = runQuery([Has(Zone)]);
  const playersGroupedByZone = groupBy([...allPlayers.values()], (player) => {
    const playerSpawn = [...runQuery([Has(SpawnPoint), HasValue(OwnedBy, { value: world.entities[player] })])][0];
    if (playerSpawn == undefined) return -1;

    const closestZoneCenter = findClosest(playerSpawn, [...allZones]);

    return closestZoneCenter.entityIndex;
  });
  delete playersGroupedByZone[-1];

  for (const zone of allZones) {
    if (playersGroupedByZone[zone] == undefined) playersGroupedByZone[zone] = [];
  }

  const findSpawnButtonDisabled = playerName.length === 0 || nameTaken;

  return (
    <div
      style={{
        backgroundImage: "url(assets/ui/dialogue-1.png)",
        height: "262px",
        width: "600px",
        backgroundSize: "600px 262px",
        color: "#ffd56a",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1
        style={{
          margin: "0 auto",
          marginTop: "10px",
          fontSize: "3em",
        }}
      >
        {freeSpawn ? "Join Game" : "Join Next Round"}
      </h1>

      <div
        style={{
          height: "100%",
          color: "#d07e1a",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {freeSpawn && (
          <Row style={{ fontSize: "1.5em" }}>
            <p>{allPlayers.size} players have already spawned!</p>
            <p>{availableSpawns.size} spawns available.</p>
          </Row>
        )}
        <Row>
          <Input
            type={"text"}
            placeholder="Name..."
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
            style={{ width: "200px" }}
          ></Input>
          {!spawnAction ? (
            <Button
              disabled={findSpawnButtonDisabled}
              style={{
                fontSize: "2em",
              }}
              onClick={() => {
                let closestSpawnPointEntity = "0x0" as EntityID;

                if (freeSpawn) {
                  const spawnZone = parseInt(
                    Object.entries(playersGroupedByZone).sort((a, b) => a[1].length - b[1].length)[0][0]
                  ) as EntityIndex;
                  const closestSpawnPoint = findClosest(spawnZone, [...availableSpawns]);
                  if (closestSpawnPoint.entityIndex == undefined) return;

                  closestSpawnPointEntity = world.entities[closestSpawnPoint.entityIndex];
                }

                joinGame(closestSpawnPointEntity, playerName);
              }}
            >
              {freeSpawn ? "Find a Spawn" : "Register"}
            </Button>
          ) : (
            <Button
              style={{
                fontSize: "2em",
              }}
            >
              Joining...
            </Button>
          )}
        </Row>
      </div>
    </div>
  );
};

export function registerJoinGame() {
  registerUIComponent(
    "JoinGameWindow",
    {
      colStart: 5,
      colEnd: 9,
      rowStart: 4,
      rowEnd: 9,
    },
    (layers) => {
      const {
        network: {
          network: { connectedAddress },
          components: { Player, SpawnPoint, OwnedBy, Zone, Admin },
          world,
        },
        local: {
          components: { Selected, Preferences },
          api: { getPreferences },
        },
        headless: {
          actions: { Action },
        },
      } = layers;

      return merge(
        computedToStream(connectedAddress),
        Selected.update$,
        Player.update$,
        SpawnPoint.update$,
        OwnedBy.update$,
        Zone.update$,
        Action.update$,
        Preferences.update$
      ).pipe(
        map(() => connectedAddress.get()),
        map((address) => {
          if (!address) return;

          const playerEntity = world.entityToIndex.get(address as EntityID);

          if (playerEntity != undefined) {
            if (hasComponent(Player, playerEntity)) return;
            if (hasComponent(Admin, playerEntity)) return;
          }

          const preferences = getPreferences();
          if (!preferences?.hideTutorial) return;

          return {
            layers,
            playerEntity,
          };
        })
      );
    },
    (props) => {
      return <JoinGameContainer {...props} />;
    }
  );
}
