import { defineSystem, getComponentValue, Has, Not, removeComponent, setComponent, UpdateType } from "@latticexyz/recs";
import { getOwningPlayer } from "@latticexyz/std-client";
import { curry } from "lodash";
import { BFS } from "../../../../utils/pathfinding";
import { LocalLayer } from "../../types";

export function createPotentialPathSystem(layer: LocalLayer) {
  const {
    world,
    components: { Selected, PotentialPath, LocalPosition, Path },
    parentLayers: {
      headless: {
        components: { LocalStamina },
        actions: { withOptimisticUpdates },
        api: { getMovementDifficulty, isUntraversable },
      },
      network: {
        components: { Stamina, Player, OwnedBy },
        utils: { getMoveSpeed },
      },
    },
  } = layer;

  const OptimisticStamina = withOptimisticUpdates(Stamina);

  defineSystem(
    world,
    [Has(Selected), Has(LocalPosition), Has(LocalStamina), Has(OptimisticStamina), Not(Path)],
    ({ type, entity }) => {
      const localStamina = getComponentValue(LocalStamina, entity);
      if (!localStamina) return;

      const stamina = getComponentValue(OptimisticStamina, entity);
      if (!stamina) return;

      const currentStamina = localStamina.current + stamina.current;

      if (type === UpdateType.Exit || currentStamina < 1_000) {
        removeComponent(PotentialPath, entity);
      } else if ([UpdateType.Enter, UpdateType.Update].includes(type)) {
        const moveSpeed = getMoveSpeed(entity);
        if (!moveSpeed) return;

        const localPosition = getComponentValue(LocalPosition, entity);
        if (!localPosition) return;

        const playerEntity = getOwningPlayer(entity, world, Player, OwnedBy);
        if (playerEntity == null) return;

        const xArray: number[] = [];
        const yArray: number[] = [];

        const [paths, costs] = BFS(
          localPosition,
          moveSpeed,
          curry(getMovementDifficulty)(LocalPosition, playerEntity),
          curry(isUntraversable)(LocalPosition, playerEntity)
        );

        for (const coord of paths) {
          xArray.push(coord.x);
          yArray.push(coord.y);
        }

        const potentialPaths = {
          x: xArray,
          y: yArray,
          costs: costs,
        };
        setComponent(PotentialPath, entity, potentialPaths);
      }
    }
  );
}
