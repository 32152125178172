import { HueTintAndOutlineFXPipeline, pixelCoordToTileCoord, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  EntityID,
  EntityIndex,
  getComponentValueStrict,
  getEntitiesWithValue,
  Has,
  hasComponent,
  HasValue,
  removeComponent,
  UpdateType,
} from "@latticexyz/recs";
import { Coord } from "@latticexyz/utils";
import { fromEvent, merge, Subscription } from "rxjs";
import { BFS } from "../../../../../utils/pathfinding";
import { UnitTypeAnimations, UnitTypeSprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawSummonUISystem(layer: PhaserLayer) {
  const {
    world,
    api: {
      drawTileHighlight,
      mapInteraction: { disableMapInteraction, enableMapInteraction },
    },
    parentLayers: {
      network: {
        components: { SummonRecipe, OwnedBy, Summoner, UnitType, Position, Untraversable },
        utils: { getAllSummons },
      },
      headless: {
        api: { summon, getSacrificableNeighbors, getSummonInputs },
      },
      local: {
        components: { Selected, LocalPosition },
        singletonEntity,
        api: { onPlayerLoaded },
      },
    },
    components: { HoverHighlight },
    scenes: {
      Main: {
        objectPool,
        input,
        phaserScene,
        config,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  function disableMapInput() {
    disableMapInteraction();
    removeComponent(HoverHighlight, singletonEntity);
  }

  function fadeIn(gameObject: Phaser.GameObjects.GameObject, to: number, alpha = 1) {
    phaserScene.add.tween({
      targets: gameObject,
      alpha: {
        from: 0,
        to: alpha,
      },
      y: {
        from: to + 8,
        to,
      },
      repeat: 0,
      duration: 150,
      ease: Phaser.Math.Easing.Sine,
    });
  }

  onPlayerLoaded(({ playerColor, playerId }) => {
    let summonClickSubscription: Subscription | undefined;
    let summonPreviewSubscription: Subscription | undefined;
    let summonPreviewSprite: Phaser.GameObjects.Sprite | undefined;

    function clearSummonTiles() {
      for (let i = 0; i < 20; i++) {
        objectPool.remove(`summon-position-${i}`);
      }

      summonClickSubscription?.unsubscribe();
      summonPreviewSubscription?.unsubscribe();
      summonPreviewSprite?.destroy();
      summonPreviewSprite = undefined;
    }

    const shouldDrawSummonTile = (_isFinalPosition: boolean, position: Coord) => {
      const entitiesAtPosition = getEntitiesWithValue(Position, position);
      const blockingEntity = [...entitiesAtPosition].find((e) => hasComponent(Untraversable, e));
      if (!blockingEntity) return false;

      const isUnit = hasComponent(UnitType, blockingEntity);
      if (isUnit) return false;

      return true;
    };

    function renderSummonTiles(
      summoner: EntityIndex,
      summonId: EntityID,
      summonedPrototype: EntityIndex,
      sacrificedEntities: EntityID[]
    ) {
      clearSummonTiles();

      const position = getComponentValueStrict(LocalPosition, summoner);
      disableMapInput();
      removeComponent(Selected, summoner);

      const [summonPositions] = BFS(position, 2, () => 1, shouldDrawSummonTile);

      for (let i = 0; i < summonPositions.length; i++) {
        drawTileHighlight(`summon-position-${i}`, summonPositions[i], 0xffff00);
      }

      // TODO Without putting it in a timeout the subscription fires immediately.
      // Why.
      setTimeout(() => {
        summonClickSubscription = merge(input.click$, input.rightClick$).subscribe((pointer) => {
          const tile = pixelCoordToTileCoord(
            {
              x: pointer.worldX,
              y: pointer.worldY,
            },
            tileWidth,
            tileHeight
          );
          for (const summonPosition of summonPositions) {
            if (tile.x === summonPosition.x && tile.y === summonPosition.y) {
              summon(summoner, summonId, sacrificedEntities, summonPosition);
            }
          }

          clearSummonTiles();
          enableMapInteraction();
        });

        summonPreviewSubscription = input.pointermove$.subscribe(({ pointer }) => {
          const tile = pixelCoordToTileCoord(
            {
              x: pointer.worldX,
              y: pointer.worldY,
            },
            tileWidth,
            tileHeight
          );
          const previewPosition = tileCoordToPixelCoord(tile, tileWidth, tileHeight);
          const unitType = getComponentValueStrict(UnitType, summonedPrototype).value;
          const spriteAnimation = UnitTypeAnimations[unitType];

          if (!summonPreviewSprite) {
            summonPreviewSprite = phaserScene.add.sprite(tile.x, tile.y, "");
            summonPreviewSprite.play(spriteAnimation);
            summonPreviewSprite.setDepth(130);
            summonPreviewSprite.setOrigin(0, 0);
            summonPreviewSprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
            summonPreviewSprite.setPipelineData("hueTint", playerColor);
          }

          summonPreviewSprite.setPosition(previewPosition.x, previewPosition.y);
          if (!summonPositions.find((bp) => bp.x === tile.x && bp.y === tile.y)) {
            summonPreviewSprite.setTint(0x666666);
          } else {
            summonPreviewSprite.clearTint();
          }
        });
      }, 0);
    }

    let spriteGroup: Phaser.GameObjects.Group | undefined;
    const pointerSubs: Subscription[] = [];

    defineSystem(
      world,
      [Has(Selected), Has(Summoner), HasValue(OwnedBy, { value: playerId }), Has(LocalPosition)],
      ({ entity, type }) => {
        spriteGroup?.clear(true);
        pointerSubs.forEach((sub) => sub.unsubscribe());
        clearSummonTiles();

        if (type === UpdateType.Exit) {
          return;
        }

        spriteGroup = phaserScene.add.group();

        const position = getComponentValueStrict(LocalPosition, entity);
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

        const allSummonRecipes = getAllSummons();
        const sacrificableEntities = getSacrificableNeighbors(playerId, entity, LocalPosition);
        const possibleSummonRecipes: (NonNullable<ReturnType<typeof getSummonInputs>> & {
          summonedPrototypeId: EntityID;
        })[] = [];

        for (const recipe of allSummonRecipes) {
          const summonRecipe = getComponentValueStrict(SummonRecipe, recipe);
          const summonData = getSummonInputs(entity, recipe, sacrificableEntities);
          if (summonData?.sacrificedEntities.length === summonRecipe.sacrificedPrototypeIds.length)
            possibleSummonRecipes.push({
              ...summonData,
              summonedPrototypeId: summonRecipe.summonedPrototypeId as EntityID,
            });
        }

        for (let i = 0; i < possibleSummonRecipes.length; i++) {
          const summonRecipe = possibleSummonRecipes[i];
          const summonedPrototype = world.getEntityIndexStrict(summonRecipe.summonedPrototypeId);
          const unitType = getComponentValueStrict(UnitType, summonedPrototype);
          const spriteConfig = config.sprites[UnitTypeSprites[unitType.value]];
          const spriteX =
            pixelCoord.x + 16 + i * (tileWidth + 16) - (possibleSummonRecipes.length - 1) * ((tileWidth + 16) / 2);

          const sprite = phaserScene.add.image(spriteX, pixelCoord.y - 28, spriteConfig.assetKey, spriteConfig.frame);
          sprite.setDepth(130);
          sprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
          sprite.setPipelineData("hueTint", playerColor);

          const spriteBackground = phaserScene.add.rectangle(spriteX, pixelCoord.y - 28, 36, 36);
          spriteBackground.setFillStyle(0x24131a);
          spriteBackground.setStrokeStyle(3, 0x8a5e3b);
          spriteBackground.setDepth(90);
          spriteBackground.setInteractive();

          pointerSubs.push(
            merge(fromEvent(spriteBackground, Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN)).subscribe(() => {
              renderSummonTiles(entity, summonRecipe.summonId, summonedPrototype, summonRecipe.sacrificedEntities);
            })
          );
          pointerSubs.push(
            merge(fromEvent(spriteBackground, Phaser.Input.Events.GAMEOBJECT_POINTER_OVER)).subscribe(() => {
              sprite.setY(sprite.y - 5);
              spriteBackground.setY(spriteBackground.y - 5);
              disableMapInput();
            })
          );
          pointerSubs.push(
            merge(fromEvent(spriteBackground, Phaser.Input.Events.GAMEOBJECT_POINTER_OUT)).subscribe(() => {
              sprite.setY(sprite.y + 5);
              spriteBackground.setY(spriteBackground.y + 5);
              enableMapInteraction();
            })
          );

          fadeIn(spriteBackground, spriteBackground.y);
          fadeIn(sprite, sprite.y);

          spriteGroup.add(sprite, false);
          spriteGroup.add(spriteBackground);
        }
      }
    );
  });
}
