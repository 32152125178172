import { EntityIndex, getComponentValue, getComponentValueStrict, Has, Not, runQuery } from "@latticexyz/recs";
import { keys, reject } from "lodash";
import { NetworkLayer } from "../types";

export async function startGame(layer: NetworkLayer) {
  const {
    world,
    systems,
    components: { Player, Zone, SpawnPoint, Prototype, OwnedBy, Position },
    utils: { findClosest },
  } = layer;

  const startGameSystem = systems["mudwar.system.StartGame"];
  const allPlayers = [...runQuery([Has(Player)])];
  const zones = [...runQuery([Has(Zone)])];
  const availableSpawns = [...runQuery([Has(SpawnPoint), Has(Position), Not(OwnedBy), Not(Prototype)])];
  const ownedSpawns = [...runQuery([Has(SpawnPoint), Has(OwnedBy)])];
  const spawnedPlayers = ownedSpawns.map((i) => world.getEntityIndexStrict(getComponentValueStrict(OwnedBy, i).value));
  const players = reject(allPlayers, (p) => spawnedPlayers.includes(p));

  const zonesWithSpawns: Record<EntityIndex, EntityIndex[]> = {};
  for (const spawn of availableSpawns) {
    const { entityIndex: closestZone } = findClosest(spawn, zones);

    if (!closestZone) continue;

    if (!zonesWithSpawns[closestZone]) zonesWithSpawns[closestZone] = [];
    zonesWithSpawns[closestZone].push(spawn);
  }

  const numberOfPlayersToSpawn = players.length > availableSpawns.length ? availableSpawns.length : players.length;
  const playerSpawns: EntityIndex[] = [];

  let zoneIndex = 0;
  const nextZone = (i: number) => {
    i++;
    if (i > keys(zonesWithSpawns).length - 1) i = 0;
    return i;
  };

  function getZoneAtIndex(i: number) {
    return parseInt(keys(zonesWithSpawns)[i]) as EntityIndex;
  }

  for (let i = 0; i < numberOfPlayersToSpawn; i++) {
    while (!zonesWithSpawns[getZoneAtIndex(zoneIndex)] || zonesWithSpawns[getZoneAtIndex(zoneIndex)].length === 0) {
      zoneIndex = nextZone(zoneIndex);
      if (zonesWithSpawns[getZoneAtIndex(zoneIndex)]?.length === 0) delete zonesWithSpawns[getZoneAtIndex(zoneIndex)];
    }

    const spawn = zonesWithSpawns[getZoneAtIndex(zoneIndex)].pop();
    if (spawn == undefined) continue;

    playerSpawns[i] = spawn;
    zoneIndex = nextZone(zoneIndex);
  }

  const playerIds = players.slice(0, numberOfPlayersToSpawn).map((p) => world.entities[p]);
  const spawnIds = playerSpawns.map((s) => world.entities[s]);

  for (let i = 0; i < numberOfPlayersToSpawn; i++) {
    startGameSystem.executeTyped(playerIds.slice(i, i + 1), spawnIds.slice(i, i + 1), { gasLimit: 20_000_000 });
  }
}
