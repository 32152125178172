import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { Has, getComponentValueStrict, defineSystem, UpdateType, EntityIndex, Not } from "@latticexyz/recs";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawHealthSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      local: {
        components: { LocalPosition, LocalHealth, Path },
      },
    },
    scenes: {
      Main: {
        objectPool,
        config,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  const forEachHealthTick = (entity: EntityIndex, callback: (id: string, index: number) => void) => {
    for (let i = 0; i < 5; i++) {
      const id = `${entity}-health-tick-${i}`;
      callback(id, i);
    }
  };

  defineSystem(world, [Has(LocalPosition), Has(LocalHealth), Not(Path)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      objectPool.remove(`${entity}-health`);
      forEachHealthTick(entity, (id) => objectPool.remove(id));
    } else if ([UpdateType.Enter, UpdateType.Update].includes(type)) {
      const health = getComponentValueStrict(LocalHealth, entity).value;
      const position = getComponentValueStrict(LocalPosition, entity);
      const healthBarSprite = config.sprites[Sprites.HealthBar];
      const barHeightOffset = 10;

      const highlight = objectPool.get(`${entity}-health`, "Sprite");
      highlight.setComponent({
        id: "health-bar",
        once: (healthBar) => {
          const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
          healthBar.setTexture(healthBarSprite.assetKey, healthBarSprite.frame);
          healthBar.setPosition(pixelCoord.x + 3, pixelCoord.y - barHeightOffset);
          healthBar.setDepth(30);
        },
      });

      forEachHealthTick(entity, (id: string, i: number) => {
        const cutoff = i * 20_000;
        const isLastTick = health < cutoff + 20_000;

        if (health > cutoff) {
          const tick = objectPool.get(id, "Sprite");
          tick.setComponent({
            id: `tick-${i}`,
            once: (tick) => {
              let tickSprite = config.sprites[Sprites.GreenTick];
              if (isLastTick) {
                const tickHealthAmount = health - cutoff;
                if (tickHealthAmount < 8_000) {
                  tickSprite = config.sprites[Sprites.RedTick];
                } else if (tickHealthAmount < 20_000) {
                  tickSprite = config.sprites[Sprites.OrangeTick];
                }
              }

              const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
              tick.setTexture(tickSprite.assetKey, tickSprite.frame);
              tick.setPosition(pixelCoord.x + 4 + i * 5, pixelCoord.y - barHeightOffset + 1);
              tick.setDepth(36);
            },
          });
        } else {
          objectPool.remove(id);
        }
      });
    }
  });
}
