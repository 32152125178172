import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  getComponentValue,
  getComponentValueStrict,
  Has,
  isComponentUpdate,
  Not,
  UpdateType,
} from "@latticexyz/recs";
import { getOwningPlayer } from "@latticexyz/std-client";
import { compact, curry, zip } from "lodash";
import { Subscription } from "rxjs";
import { aStar } from "../../../../../utils/pathfinding";
import { PhaserLayer } from "../../types";

export function createDrawPotentialPathSystem(layer: PhaserLayer) {
  const {
    world,
    api: { drawTileHighlight },
    components: { HoverHighlight },
    parentLayers: {
      network: {
        components: { OwnedBy, Player },
      },
      local: {
        components: { PotentialPath, LocalPosition },
        api: { getOwnerColor, onPlayerLoaded },
      },
      headless: {
        api: { getZOIValue, getMovementDifficulty, isUntraversable },
      },
    },
    scenes: {
      Main: {
        objectPool,
        phaserScene,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  defineSystem(world, [Has(PotentialPath), Has(LocalPosition)], (update) => {
    if (isComponentUpdate(update, LocalPosition) && update.type === UpdateType.Exit) {
      const paths = getComponentValueStrict(PotentialPath, update.entity);
      for (let i = 0; i < paths.x.length; i++) {
        objectPool.remove(`${update.entity}-path-highlight-${i}`);
      }

      return;
    }

    if (!isComponentUpdate(update, PotentialPath)) return;
    const { value, entity } = update;

    const [potentialPaths, previousPaths] = value;

    if (previousPaths) {
      for (let i = 0; i < previousPaths.x.length; i++) {
        objectPool.remove(`${entity}-path-highlight-${i}`);
      }
    }

    if (potentialPaths) {
      // add current entity position to paths just to look good
      const position = getComponentValue(LocalPosition, entity);
      if (!position) return;
      potentialPaths.x.push(position.x);
      potentialPaths.y.push(position.y);

      const ownerColor = getOwnerColor(entity);
      for (let i = 0; i < potentialPaths.x.length; i++) {
        let color = ownerColor;

        // change color of ZOI spaces
        const playerEntity = getOwningPlayer(entity, world, Player, OwnedBy);
        if (playerEntity) {
          const currentPosition = { x: potentialPaths.x[i], y: potentialPaths.y[i] };
          const neighbors = [
            { x: currentPosition.x, y: currentPosition.y - 1 }, // Up
            { x: currentPosition.x + 1, y: currentPosition.y }, // Right
            { x: currentPosition.x, y: currentPosition.y + 1 }, // Down
            { x: currentPosition.x - 1, y: currentPosition.y }, // Left
          ];
          const ZOIValue = getZOIValue(LocalPosition, playerEntity, neighbors);
          if (ZOIValue > 0) color = 0xf00000;
        }

        const position = { x: potentialPaths.x[i], y: potentialPaths.y[i] };
        drawTileHighlight(`${entity}-path-highlight-${i}`, position, color);
      }
    }
  });

  onPlayerLoaded(({ player }) => {
    let pathLineDrawSub: Subscription | undefined;
    let lines: Phaser.GameObjects.Group | undefined;

    defineSystem(world, [Has(PotentialPath), Has(LocalPosition)], ({ entity, type }) => {
      pathLineDrawSub?.unsubscribe();
      pathLineDrawSub = undefined;
      lines?.clear(true);

      if (player !== getOwningPlayer(entity, world, Player, OwnedBy)) return;

      if (type === UpdateType.Exit) return;

      const potentialPath = getComponentValue(PotentialPath, entity);
      if (!potentialPath) return;

      const color = getOwnerColor(entity);

      lines = phaserScene.add.group();
      pathLineDrawSub = HoverHighlight.update$.subscribe((update) => {
        lines?.clear(true);
        const hoverHighlight = getComponentValue(HoverHighlight, update.entity);
        if (!hoverHighlight || hoverHighlight.x === undefined || hoverHighlight.y === undefined) return;

        const hoverCoord = { x: hoverHighlight.x, y: hoverHighlight.y };

        const potentialPathCoords = compact(
          zip(potentialPath.x, potentialPath.y).map(([x, y]) => {
            if (x === undefined || y === undefined) return null;
            return { x, y };
          })
        );
        const hoveringOverPotentialPath = potentialPathCoords.find(
          ({ x, y }) => x === hoverHighlight.x && y === hoverHighlight.y
        );
        if (!hoveringOverPotentialPath) return;

        const unitPosition = getComponentValueStrict(LocalPosition, entity);
        const unitPath = aStar(
          unitPosition,
          hoverCoord,
          100,
          curry(getMovementDifficulty)(LocalPosition, player),
          curry(isUntraversable)(LocalPosition, player)
        );
        unitPath.unshift(unitPosition);

        // paint lines between this point and the next
        for (let i = 0; i < unitPath.length - 1; i++) {
          const coord = tileCoordToPixelCoord(unitPath[i], tileWidth, tileHeight);
          const nextCoord = tileCoordToPixelCoord(unitPath[i + 1], tileWidth, tileHeight);

          const line = phaserScene.add.line();
          line.setOrigin(0, 0);
          line.setPosition(coord.x, coord.y);
          line.setTo(
            tileWidth / 2,
            tileHeight / 2,
            nextCoord.x - coord.x + tileWidth / 2,
            nextCoord.y - coord.y + tileHeight / 2
          );
          line.setStrokeStyle(4, color);
          line.setDepth(1);
          lines?.add(line, false);
        }
      });
    });
  });
}
