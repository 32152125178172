import {
  defineSystem,
  getComponentValueStrict,
  Has,
  HasValue,
  removeComponent,
  runQuery,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { PhaserLayer } from "../../types";
import { HueTintAndOutlineFXPipeline } from "@latticexyz/phaserx";
import { getStringColor } from "@latticexyz/std-client";
import { StructureTypes } from "../../../../Network";

/**
 * The HueTint system handles setting a "hueTint" pipeline data on game objects having a hue tint
 */
export function createHueTintSystem(layer: PhaserLayer) {
  const {
    world,
    components: { HueTint },
    scenes: {
      Main: { objectPool },
    },
    parentLayers: {
      network: {
        components: { OwnedBy, StructureType, Portal, Player, Name },
      },
      local: {
        components: { LocalPosition },
        api: { getOwnerColor },
      },
    },
  } = layer;

  defineSystem(world, [Has(Player), Has(Name)], ({ entity, type }) => {
    if (type === UpdateType.Exit) return;

    const ownedEntities = runQuery([HasValue(OwnedBy, { value: world.entities[entity] })]);

    const color = getOwnerColor(entity);
    for (const e of ownedEntities) {
      setComponent(HueTint, e, { value: color });
    }
  });

  defineSystem(world, [Has(OwnedBy)], ({ entity }) => {
    const color = getOwnerColor(entity);
    setComponent(HueTint, entity, { value: color });
  });

  defineSystem(world, [Has(HueTint), Has(LocalPosition)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      return objectPool.remove(entity);
    }

    const embodiedEntity = objectPool.get(entity, "Sprite");

    const hueTint = getComponentValueStrict(HueTint, entity).value;
    embodiedEntity.setComponent({
      id: HueTint.id,
      once: (gameObject) => {
        gameObject.setPipeline(HueTintAndOutlineFXPipeline.KEY);
        gameObject.setPipelineData("hueTint", hueTint);
      },
    });
  });

  defineSystem(world, [HasValue(StructureType, { value: StructureTypes.EscapePortal })], ({ type, entity }) => {
    if (type === UpdateType.Exit) {
      removeComponent(HueTint, entity);
      return;
    }

    setComponent(HueTint, entity, { value: 0xb00b1e });
  });

  // Color portals based on their exit location
  defineSystem(world, [Has(Portal)], ({ type, entity }) => {
    if (type === UpdateType.Exit) {
      removeComponent(HueTint, entity);
      return;
    }

    const portal = getComponentValueStrict(Portal, entity);
    const target = portal.targetIds[0];
    if (target == null) return;

    const color = getStringColor(target);
    setComponent(HueTint, entity, { value: color });
  });
}
