import { defineSystem, getComponentValue, Has, Not, removeComponent, setComponent, UpdateType } from "@latticexyz/recs";
import { getOwningPlayer } from "@latticexyz/std-client";
import { LocalLayer } from "../../types";

export function createAttackableEntitiesSystem(layer: LocalLayer) {
  const {
    world,
    components: { Selected, AttackableEntities, LocalPosition },
    parentLayers: {
      headless: {
        components: { LocalStamina, OnCooldown },
        actions: { withOptimisticUpdates },
        api: {
          getAttackableEntities,
          combat: { isPassive },
        },
      },
      network: {
        components: { Stamina, Player, OwnedBy, Combat, RangedCombat },
      },
    },
  } = layer;

  const OptimisticStamina = withOptimisticUpdates(Stamina);

  defineSystem(
    world,
    [Has(Selected), Has(LocalPosition), Has(LocalStamina), Has(OptimisticStamina), Has(Combat), Not(OnCooldown)],
    ({ type, entity }) => {
      const localStamina = getComponentValue(LocalStamina, entity);
      if (!localStamina) return;

      const stamina = getComponentValue(OptimisticStamina, entity);
      if (!stamina) return;

      const combat = getComponentValue(Combat, entity);
      if (!combat) return;
      const rangedCombat = getComponentValue(RangedCombat, entity);
      if (!rangedCombat && isPassive(layer.parentLayers.network, entity)) return;

      const currentStamina = localStamina.current + stamina.current;

      if (type === UpdateType.Exit || currentStamina < 1_000) {
        removeComponent(AttackableEntities, entity);
      } else if ([UpdateType.Enter, UpdateType.Update].includes(type)) {
        const localPosition = getComponentValue(LocalPosition, entity);
        if (!localPosition) return;

        const playerEntity = getOwningPlayer(entity, world, Player, OwnedBy);
        if (playerEntity == null) return;

        const attackableEntities = getAttackableEntities(entity);
        if (!attackableEntities) return;

        setComponent(AttackableEntities, entity, { value: attackableEntities });
      }
    }
  );
}
