import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  getComponentValue,
  getComponentValueStrict,
  Has,
  HasValue,
  Not,
  NotValue,
  ProxyRead,
  removeComponent,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { StructureTypes } from "../../../../Network";
import { ItemTypeAnimations } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawInventorySystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Inventory, ItemType, OwnedBy, StructureType },
      },
      local: {
        components: { LocalPosition },
      },
    },
    components: { SpriteAnimation },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  defineSystem(
    world,
    [
      Has(ItemType),
      ProxyRead(OwnedBy, 1),
      Has(Inventory),
      Has(LocalPosition),
      NotValue(StructureType, { value: StructureTypes.Container }),
    ],
    ({ entity, type }) => {
      const embodiedId = `${entity}-item-display`;
      if (type === UpdateType.Exit) return objectPool.remove(embodiedId);

      const owner = getComponentValue(OwnedBy, entity)?.value;
      if (!owner) return;
      const ownerIndex = world.entityToIndex.get(owner);
      if (ownerIndex == null) return;

      const position = getComponentValueStrict(LocalPosition, ownerIndex);
      const itemType = getComponentValueStrict(ItemType, entity).value;

      const embodiedObject = objectPool.get(embodiedId, "Sprite");
      embodiedObject.setComponent({
        id: "rectangle",
        once: (sprite) => {
          const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

          sprite.play(ItemTypeAnimations[itemType]);
          sprite.setScale(0.5);
          sprite.setPosition(pixelCoord.x + tileWidth - 14, pixelCoord.y + tileHeight - 14);
          sprite.setDepth(36);
        },
      });
    }
  );

  defineSystem(
    world,
    [
      Has(ItemType),
      ProxyRead(OwnedBy, 1),
      Has(Inventory),
      HasValue(StructureType, { value: StructureTypes.Container }),
      Has(LocalPosition),
    ],
    ({ entity, type }) => {
      const owner = getComponentValue(OwnedBy, entity)?.value;
      if (!owner) return;
      const ownerIndex = world.entityToIndex.get(owner);
      if (ownerIndex == null) return;

      if (type === UpdateType.Exit) return;

      const itemType = getComponentValueStrict(ItemType, entity).value;
      const itemAnimation = ItemTypeAnimations[itemType];

      if (itemAnimation) setComponent(SpriteAnimation, ownerIndex, { value: itemAnimation });
    }
  );
}
