import { defineComponent, Type, World } from "@latticexyz/recs";
import { defineDevHighlightComponent } from "@latticexyz/std-client";
import { defineAppearanceComponent } from "./Appearance";
import { defineHueTintComponent } from "./HueTint";
import { defineOutlineComponent } from "./Outline";
import { defineSpriteAnimationComponent } from "./SpriteAnimation";

export function createPhaserComponents(world: World) {
  const Appearance = defineAppearanceComponent(world);
  const SpriteAnimation = defineSpriteAnimationComponent(world);
  const Outline = defineOutlineComponent(world);
  const HueTint = defineHueTintComponent(world);
  const DevHighlight = defineDevHighlightComponent(world);
  const HoverHighlight = defineComponent(
    world,
    { color: Type.OptionalNumber, x: Type.OptionalNumber, y: Type.OptionalNumber },
    { id: "HoverHighlight" }
  );
  const HoverIcon = defineComponent(world, { icon: Type.String }, { id: "HoverIcon" });
  const Alpha = defineComponent(world, { icon: Type.Number }, { id: "Alpha" });
  const MapBounds = defineComponent(world, {
    top: Type.Number,
    right: Type.Number,
    bottom: Type.Number,
    left: Type.Number,
  });

  return { Appearance, SpriteAnimation, Outline, HueTint, DevHighlight, HoverHighlight, HoverIcon, Alpha, MapBounds };
}
