import { tileCoordToPixelCoord, tween } from "@latticexyz/phaserx";
import {
  Has,
  getComponentValueStrict,
  defineSystem,
  UpdateType,
  EntityIndex,
  getComponentValue,
  hasComponent,
  Not,
} from "@latticexyz/recs";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawStaminaSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Stamina, StaminaRegenerationCap },
      },
      local: {
        components: { LocalPosition, PendingStaminaSpend, Path },
      },
      headless: {
        components: { LocalStamina },
        actions: { withOptimisticUpdates },
        api: { getCurrentStamina },
      },
    },
    scenes: {
      Main: {
        objectPool,
        config,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  const forEachStaminaTick = (entity: EntityIndex, maxTicks: number, callback: (id: string, index: number) => void) => {
    for (let i = 0; i < maxTicks; i++) {
      const id = `${entity}-stamina-tick-${i}`;
      callback(id, i);
    }
  };

  const OptimisticStamina = withOptimisticUpdates(Stamina);

  defineSystem(
    world,
    [Has(LocalPosition), Has(LocalStamina), Has(OptimisticStamina), Not(Path)],
    ({ entity, type }) => {
      if (type === UpdateType.Exit) {
        return;
      }

      const stamina = getCurrentStamina(entity);
      const embodiedObject = objectPool.get(entity, "Sprite");

      if (stamina < 1000) {
        embodiedObject.setComponent({
          id: "no-stamina-tint",
          once: (sprite) => {
            sprite.setTint(0x696969);
          },
        });
      } else {
        embodiedObject.setComponent({
          id: "no-stamina-tint",
          once: (sprite) => {
            sprite.clearTint();
          },
        });
      }
    }
  );

  defineSystem(
    world,
    [Has(LocalPosition), Has(OptimisticStamina), Has(LocalStamina), Has(PendingStaminaSpend), Not(Path)],
    ({ entity, type }) => {
      forEachStaminaTick(entity, 5, (id, i) => {
        objectPool.remove(id);
        objectPool.remove(`tick-background-${entity}-${i}`);
      });

      if (type === UpdateType.Exit) {
        return;
      }

      const stamina = getComponentValueStrict(OptimisticStamina, entity);
      const currentStamina = getComponentValueStrict(LocalStamina, entity).current + stamina.current;
      const position = getComponentValueStrict(LocalPosition, entity);

      const tickSprite = config.sprites[Sprites.YellowTick];
      const tickBackgroundSprite = config.sprites[Sprites.ResourceBar];

      let maxStamina = stamina.max;
      // Cap the Stamina bar to how much could possibly be generated in the future
      if (hasComponent(StaminaRegenerationCap, entity)) {
        const { cap, totalRegenerated } = getComponentValueStrict(StaminaRegenerationCap, entity);
        maxStamina = Math.min(currentStamina + (cap - totalRegenerated), maxStamina);
      }

      forEachStaminaTick(entity, Math.floor(maxStamina / 1000), (id: string, i: number) => {
        const tickBackground = objectPool.get(`tick-background-${entity}-${i}`, "Sprite");
        tickBackground.setComponent({
          id: `tick-background-${i}`,
          once: (background) => {
            const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
            background.setTexture(tickBackgroundSprite.assetKey, tickBackgroundSprite.frame);
            background.setPosition(pixelCoord.x + 3 + i * 5, pixelCoord.y - 4);
            background.setDepth(40);
          },
        });

        if (currentStamina / 1000 >= i + 1) {
          const tick = objectPool.get(id, "Sprite");
          tick.setComponent({
            id: `tick-${i}`,
            once: async (tick) => {
              const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
              tick.setTexture(tickSprite.assetKey, tickSprite.frame);
              tick.setPosition(pixelCoord.x + 4 + i * 5, pixelCoord.y - 3);
              tick.setDepth(45);

              const isLastTick = currentStamina < i + 2;
              const pendingStaminaSpend = getComponentValue(PendingStaminaSpend, entity)?.value;
              if (pendingStaminaSpend && isLastTick) {
                await tween({
                  targets: tick,
                  props: {
                    alpha: {
                      value: 0.6,
                    },
                  },
                  repeat: -1,
                  yoyo: true,
                  duration: 250,
                  ease: Phaser.Math.Easing.Sine.InOut,
                });
              } else {
                await tween(
                  {
                    targets: tick,
                    props: {
                      alpha: {
                        value: 1,
                      },
                    },
                  },
                  { keepExistingTweens: false }
                );
              }
            },
          });
        }
      });
    }
  );
}
