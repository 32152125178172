import React, { useState } from "react";
import { registerUIComponent } from "../../engine";
import { EntityID, hasComponent } from "@latticexyz/recs";
import { map, merge } from "rxjs";
import { computedToStream, observableToStream } from "@latticexyz/utils";
import { Layers } from "../../engine/types";
import { useFilePicker } from "use-file-picker";
import { Button, Row, Section, SectionTitle } from "../Theme";
import { bulkUploadMap, ECSMap } from "../../../../Network/admin/bulkUploadMap";
import { startGame } from "../../../../Network/admin/startGame";
import { getGameConfig } from "@latticexyz/std-client";
import { observable } from "mobx";

const adminPanelState = observable({
  hide: false,
});

const adminPanelState$ = observableToStream(adminPanelState);

function UploadMap({ layers }: { layers: Layers }) {
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: ".json",
  });
  const [sendingTxs, setSendingTxs] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const {
    network: {
      world,
      components: { GameConfig },
    },
  } = layers;

  const gameConfig = getGameConfig(world, GameConfig);
  if (!gameConfig) return null;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Section>
      <h1>Admin Panel</h1>

      <Row>
        <SectionTitle>Upload Map</SectionTitle>
        <button onClick={() => openFileSelector()}>Select files </button>
        <br />
        {filesContent.map((file, index) => (
          <div>
            <h2>{file.name}</h2>
            <div key={index}>{file.content.slice(0, 100)}</div>
            <br />
          </div>
        ))}
        {!sendingTxs && (
          <Button
            onClick={async () => {
              setSendingTxs(true);
              try {
                await bulkUploadMap(layers.network, JSON.parse(filesContent[0].content) as ECSMap, (msg) => {
                  setMessages([...messages, msg]);
                });
              } finally {
                setSendingTxs(false);
              }
            }}
          >
            Upload!
          </Button>
        )}
        <Row>
          {messages.map((msg, i) => {
            return <p key={`msg-${i}`}>{msg}</p>;
          })}
        </Row>
      </Row>
      <Row style={{ height: "16px" }}></Row>

      {gameConfig.freeSpawn === 0 && (
        <Row>
          <SectionTitle>Launch Game</SectionTitle>{" "}
          {!sendingTxs && (
            <Button
              onClick={async () => {
                setSendingTxs(true);
                try {
                  await startGame(layers.network);
                } catch (e) {
                  console.error("Bulk upload tx failed:");
                  console.error(e);
                } finally {
                  setSendingTxs(false);
                }
              }}
            >
              Spawn Players
            </Button>
          )}
        </Row>
      )}

      <Row>
        <Button onClick={() => (adminPanelState.hide = true)}>Exit Admin Panel</Button>
      </Row>
    </Section>
  );
}

export function registerAdminPanel() {
  registerUIComponent(
    "Admin",
    {
      colStart: 5,
      colEnd: 9,
      rowStart: 4,
      rowEnd: 9,
    },
    (layers) => {
      const {
        network: {
          network: { connectedAddress },
          components: { Player, SpawnPoint, OwnedBy, Zone, Admin },
          world,
        },
        local: {
          components: { Selected },
        },
      } = layers;

      return merge(
        computedToStream(connectedAddress),
        Selected.update$,
        Player.update$,
        SpawnPoint.update$,
        OwnedBy.update$,
        Zone.update$,
        adminPanelState$
      ).pipe(
        map(() => connectedAddress.get()),
        map((address) => {
          if (adminPanelState.hide) return;

          if (!address) return;

          const playerEntity = world.entityToIndex.get(address as EntityID);

          if (!playerEntity) return;
          if (!hasComponent(Admin, playerEntity)) return;

          return {
            layers,
            admin: playerEntity,
          };
        })
      );
    },
    ({ layers }) => <UploadMap layers={layers} />
  );
}
