import { LocalLayer } from "../../Local";
import {
  createMapSystem,
  createLocalPositionSystem,
  createSetVisualsSystem,
  createAppearanceSystem,
  createSpriteAnimationSystem,
  createOutlineSystem,
  createHueTintSystem,
  createSelectionSystem,
  createDrawDevHighlightSystem,
  createInputSystem,
  createDrawStaminaSystem,
  createDrawHighlightCoordSystem,
  createDrawPotentialPathSystem,
  createPlayerSpawnSystem,
  createDrawHealthSystem,
  createDrawHoverIconSystem,
  createDrawActingEntitySystem,
  createDrawAttackableEntitiesSystem,
} from "./systems";
import { createPhaserEngine, HueTintAndOutlineFXPipeline, tileCoordToPixelCoord, tween } from "@latticexyz/phaserx";
import { createPhaserComponents } from "./components";
import { config } from "./gameConfig";
import { EntityIndex, getComponentValue, namespaceWorld } from "@latticexyz/recs";
import { highlightCoord } from "./api";
import { curry } from "lodash";
import { Coord } from "@latticexyz/utils";
import { createDrawInventorySystem } from "./systems/DrawInventorySystem";
import { createDrawChargingSystem } from "./systems/DrawChargingSystem";
import { createDrawMinimapSystem } from "./systems/DrawMinimap";
import { createConstrainCameraSystem } from "./systems/ConstrainCameraSystem";
import { createCycleUsableUnitsSystem } from "./systems/CycleUsableUnitsSystem";
import { createDrawUnitManager } from "./systems/DrawUnitManagerSystem";
import { Scenes } from "./phaserConstants";
import { createDrawCombatPreviewSystem } from "./systems/DrawCombatPreviewSystem/createDrawCombatPreviewSystem";
import { createCombatSystem } from "./systems/CombatSystem";
import { createSummonSystem } from "./systems/SummonSystem";
import { createAnimations } from "./animations";
import { createScreenFlashes } from "./systems/ScreenFlashes";
import { createDrawPortalTargetSystem } from "./systems/DrawPortalTargetSystem/createDrawPortalTargetSystem";
import { createDrawFactoryUISystem } from "./systems/DrawFactoryUISystem";
import { createDrawTeleportSystem } from "./systems/DrawTeleportSystem";
import { createDrawSummonUISystem } from "./systems/DrawSummonUISystem";
import { createCloudSystem } from "./systems/CloudSystem";

/**
 * The Phaser layer extends the Local layer.
 * Its purpose is to render the state of parent layers to a Phaser world.
 */
export async function createPhaserLayer(local: LocalLayer) {
  const {
    api: { selectEntity },
    components: { LocalPosition },
  } = local;

  // World
  const world = namespaceWorld(local.parentLayers.network.world, "phaser");

  const components = createPhaserComponents(world);

  // Create phaser engine
  const { game, scenes, dispose: disposePhaser } = await createPhaserEngine(config);
  world.registerDisposer(disposePhaser);

  scenes.Main.camera.setZoom(1.5);

  game.scene.start(Scenes.UI);
  // Disable zoom on UI
  scenes.UI.camera.zoom$.subscribe(() => {
    scenes.UI.camera.phaserCamera.setZoom(1);
  });

  function selectAndView(entity: EntityIndex) {
    const position = getComponentValue(LocalPosition, entity);
    if (!position) return;

    const {
      Main: {
        camera,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    } = scenes;

    camera.centerOnCoord(position, tileWidth, tileHeight);
    selectEntity(entity);
  }

  function drawTileHighlight(id: string, position: Coord, color: number) {
    const {
      objectPool,
      maps: {
        Main: { tileHeight, tileWidth },
      },
    } = scenes.Main;

    const object = objectPool.get(id, "Rectangle");
    object.setComponent({
      id: `tile-highlight`,
      once: async (box) => {
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
        box.setFillStyle(color);
        box.setAlpha(0.2);
        box.setSize(tileWidth - 2, tileHeight - 2);
        box.setPosition(pixelCoord.x + 1, pixelCoord.y + 1);
        box.setDepth(8);

        await tween({
          targets: box,
          props: {
            alpha: {
              value: 0.4,
            },
          },
          loop: -1,
          duration: 600,
          yoyo: true,
          ease: Phaser.Math.Easing.Sine.InOut,
        });
      },
    });
  }

  function createMapInteractionApi() {
    let enabled = true;

    return {
      disableMapInteraction: () => (enabled = false),
      enableMapInteraction: () => (enabled = true),
      mapInteractionEnabled: () => {
        return enabled;
      },
    };
  }

  function tintObject(obj: Phaser.GameObjects.Sprite, color: number) {
    obj.setPipeline(HueTintAndOutlineFXPipeline.KEY);
    obj.setPipelineData("hueTint", color);
  }

  const LARGE_SCREEN_CUTOFF = 1200;
  function isLargeScreen() {
    return window.innerWidth > LARGE_SCREEN_CUTOFF;
  }

  // Layer
  const layer = {
    world,
    components,
    parentLayers: {
      ...local.parentLayers,
      local,
    },
    game,
    scenes,
    api: {
      selectAndView,
      drawTileHighlight,
      mapInteraction: createMapInteractionApi(),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      highlightCoord: (coord: Coord, color: number) => {
        "no-op for types";
      },
      tintObject,
    },
    animations: createAnimations(scenes, local),
    ui: {
      isLargeScreen,
    },
  };
  layer.api.highlightCoord = curry(highlightCoord)(layer);

  // Systems
  createSetVisualsSystem(layer);
  createMapSystem(layer);
  createLocalPositionSystem(layer);
  createAppearanceSystem(layer);
  createSpriteAnimationSystem(layer);
  createOutlineSystem(layer);
  createHueTintSystem(layer);
  createSelectionSystem(layer);
  createDrawDevHighlightSystem(layer);
  createInputSystem(layer);
  createDrawStaminaSystem(layer);
  createDrawHighlightCoordSystem(layer);
  createDrawPotentialPathSystem(layer);
  createPlayerSpawnSystem(layer);
  createDrawHealthSystem(layer);
  createDrawInventorySystem(layer);
  createDrawHoverIconSystem(layer);
  createDrawActingEntitySystem(layer);
  createDrawAttackableEntitiesSystem(layer);
  createDrawChargingSystem(layer);
  createDrawMinimapSystem(layer);
  createConstrainCameraSystem(layer);
  createCycleUsableUnitsSystem(layer);
  createDrawUnitManager(layer);
  createDrawCombatPreviewSystem(layer);
  createCombatSystem(layer);
  createSummonSystem(layer);
  createScreenFlashes(layer);
  createDrawPortalTargetSystem(layer);
  createDrawFactoryUISystem(layer);
  createDrawTeleportSystem(layer);
  createDrawSummonUISystem(layer);
  createCloudSystem(layer);

  return layer;
}
