import {
  createEntity,
  setComponent,
  EntityIndex,
  getComponentValue,
  defineComponent,
  Type,
  namespaceWorld,
  runQuery,
  Has,
  removeComponent,
  getComponentValueStrict,
  EntityID,
  defineRxSystem,
  HasValue,
  defineSystem,
  ComponentValue,
  SchemaOf,
} from "@latticexyz/recs";
import { HeadlessLayer } from "../Headless";
import {
  defineLocalPositionComponent,
  defineLocalEntityTypeComponent,
  definePathComponent,
  defineSelectionComponent,
  defineSelectedComponent,
  defineSelectableComponent,
  defineRockWallComponent,
} from "./components";
import {
  createPathSystem,
  createSyncSystem,
  createPositionSystem,
  createSelectionSystem,
  createAttackableEntitiesSystem,
  createGenerateAlertsSystem,
} from "./systems";
import { DEFAULT_MOVE_SPEED, PLAYER_COLORS } from "./constants";
import { Area, computedToStream } from "@latticexyz/utils";
import { createPotentialPathSystem } from "./systems/PotentialPathSystem";
import { defineBoolComponent, getOwningPlayer, getStringColor } from "@latticexyz/std-client";
import { merge } from "rxjs";
import { ItemTypes } from "../Network";

/**
 * The Local layer is the thrid layer in the client architecture and extends the Headless layer.
 * Its purpose is to add components and systems for all client-only functionality, eg. strolling imps.
 */
export async function createLocalLayer(headless: HeadlessLayer) {
  const world = namespaceWorld(headless.parentLayers.network.world, "local");

  const {
    parentLayers: {
      network: {
        components: { Player, OwnedBy, Name, ItemType },
      },
    },
  } = headless;

  // Components
  const LocalPosition = defineLocalPositionComponent(world);
  const LocalEntityType = defineLocalEntityTypeComponent(world);
  const Path = definePathComponent(world);
  const Selection = defineSelectionComponent(world);
  const Selected = defineSelectedComponent(world);
  const Selectable = defineSelectableComponent(world);
  const RockWall = defineRockWallComponent(world);
  const PotentialPath = defineComponent(
    world,
    { x: Type.NumberArray, y: Type.NumberArray, costs: Type.NumberArray },
    { id: "PotentialPath" }
  );
  const AttackableEntities = defineComponent(world, { value: Type.NumberArray }, { id: "AttackableEntities" });
  const LocalName = defineComponent(world, { value: Type.String }, { id: "LocalName" });
  const LocalHealth = defineComponent(
    world,
    {
      value: Type.Number,
    },
    { id: "LocalHealth" }
  );
  const DevMode = defineBoolComponent(world, { id: "DevMode" });
  const Alert = defineComponent(world, { on: Type.Entity, type: Type.Number, message: Type.String }, { id: "Alert" });
  const PendingStaminaSpend = defineComponent(world, { value: Type.Boolean }, { id: "PendingStaminaSpend" });
  const ChoosingTeleportLocation = defineComponent(
    world,
    { teleportee: Type.Entity, entrance: Type.Entity },
    { id: "ChoosingTeleportLocation" }
  );
  const Preferences = defineComponent(
    world,
    {
      hideTutorial: Type.Boolean,
    },
    { id: "Preferences" }
  );

  const components = {
    Alert,
    AttackableEntities,
    ChoosingTeleportLocation,
    DevMode,
    LocalEntityType,
    LocalHealth,
    LocalName,
    LocalPosition,
    Path,
    PendingStaminaSpend,
    PotentialPath,
    Preferences,
    RockWall,
    Selectable,
    Selected,
    Selection,
  };

  // Constants
  const constants = { DEFAULT_MOVE_SPEED };

  // Singleton entity
  const singletonEntity = createEntity(world);
  setComponent(Selection, singletonEntity, { x: 0, y: 0, width: 0, height: 0 });
  setComponent(DevMode, singletonEntity, { value: false });

  // API
  function selectArea(area: Area | undefined) {
    setComponent(Selection, singletonEntity, area ?? { x: 0, y: 0, width: 0, height: 0 });
  }

  function resetSelection() {
    setComponent(Selection, singletonEntity, { x: 0, y: 0, width: 0, height: 0 });
  }

  function selectEntity(entity: EntityIndex) {
    const selectedEntities = runQuery([Has(Selected)]);
    for (const e of selectedEntities) {
      removeComponent(Selected, e);
    }

    if (getComponentValue(Selectable, entity)) setComponent(Selected, entity, { value: true });
  }

  function devModeEnabled() {
    const devMode = getComponentValueStrict(DevMode, singletonEntity);
    return devMode.value;
  }

  function persistPreferences(value: ComponentValue<SchemaOf<typeof Preferences>>) {
    setComponent(Preferences, singletonEntity, value);
    localStorage.setItem("preferences", JSON.stringify(value));
  }

  function getPreferences() {
    const existingPreferences = localStorage.getItem("preferences");
    if (existingPreferences) return JSON.parse(existingPreferences) as ComponentValue<SchemaOf<typeof Preferences>>;

    return getComponentValue(Preferences, singletonEntity);
  }

  // Make sure to load from localStorage on load
  const prefs = getPreferences();
  if (prefs) setComponent(Preferences, singletonEntity, prefs);

  function getOwnerColor(entity: EntityIndex) {
    const playerEntity = getOwningPlayer(entity, world, Player, OwnedBy);
    if (!playerEntity) return 0xffffff;

    const playerId = getComponentValue(Player, playerEntity)?.value;
    if (playerId == undefined) return 0xffffff;

    const playerIdColor = PLAYER_COLORS[playerId];
    if (playerIdColor) return playerIdColor;

    const name = getComponentValue(Name, playerEntity)?.value;
    if (!name) return 0xffffff;

    return getStringColor(name);
  }

  /**
   * @param callback Called once a Player and all of their Components are loaded into the game.
   */
  function onPlayerLoaded(
    callback: (data: { player: EntityIndex; name: string; playerId: EntityID; playerColor: number }) => void
  ) {
    const {
      parentLayers: {
        network: {
          network: { connectedAddress },
        },
      },
    } = layer;

    let playerLoaded = false;

    defineRxSystem(world, merge(computedToStream(connectedAddress), Player.update$, Name.update$), () => {
      if (playerLoaded) return;

      const address = connectedAddress.get();
      if (!address) return;

      const player = world.entityToIndex.get(address as EntityID);
      if (!player) return;

      const name = getComponentValue(Name, player)?.value;
      if (!name) return;

      const playerColor = getOwnerColor(player);

      playerLoaded = true;

      callback({
        player,
        playerId: address as EntityID,
        name,
        playerColor,
      });
    });
  }

  function onEmberCrownOwnerChanged(callback: (data: { emberCrown: EntityIndex; newOwner: EntityIndex }) => void) {
    defineSystem(
      world,
      [HasValue(ItemType, { value: ItemTypes.EmberCrown }), Has(OwnedBy)],
      ({ component, entity }) => {
        if (component.id !== OwnedBy.id) return;

        const newOwner = getOwningPlayer(entity, world, Player, OwnedBy);
        if (!newOwner) return;

        callback({
          emberCrown: entity,
          newOwner,
        });
      }
    );
  }

  function startTeleport(entrance: EntityIndex, exit: EntityIndex, teleportee: EntityIndex) {
    setComponent(ChoosingTeleportLocation, exit, {
      teleportee: world.entities[teleportee],
      entrance: world.entities[entrance],
    });
  }

  // Layer
  const layer = {
    world,
    components,
    parentLayers: { ...headless.parentLayers, headless },
    constants,
    api: {
      startTeleport,
      selectArea,
      selectEntity,
      resetSelection,
      devModeEnabled,
      getOwnerColor,
      onPlayerLoaded,
      onEmberCrownOwnerChanged,

      persistPreferences,
      getPreferences,
    },
    singletonEntity,
  };

  // Systems
  createSelectionSystem(layer);
  createSyncSystem(layer);
  createPositionSystem(layer);
  createPathSystem(layer);
  createPotentialPathSystem(layer);
  createAttackableEntitiesSystem(layer);
  createGenerateAlertsSystem(layer);

  return layer;
}
