import { Key } from "@latticexyz/phaserx";
import { EntityID, getComponentValue } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";
import { InputUtils } from "./createInputSystem";

export function registerHotkeys(
  layer: PhaserLayer,
  { getSelectedEntity, getHighlightedEntity, getHoverPosition }: InputUtils
) {
  const {
    parentLayers: {
      network: {
        components: { Factory },
      },
      headless: {
        api: { buildAt, attack, rest },
      },
    },
    scenes: {
      Main: { input },
    },
  } = layer;

  const NumberKeyNames = ["ONE", "TWO", "THREE", "FOUR", "FIVE"];
  for (let i = 0; i < 5; i++) {
    input.onKeyPress(
      (keys) => keys.has(NumberKeyNames[i] as Key),
      () => {
        const buildPosition = getHoverPosition();
        if (!buildPosition) return;

        const selectedEntity = getSelectedEntity();
        if (!selectedEntity) return;

        const factory = getComponentValue(Factory, selectedEntity);
        if (factory) {
          const prototypeId = factory.prototypeIds[i];
          if (!prototypeId) return;
          buildAt(selectedEntity, prototypeId, buildPosition);
        }
      }
    );
  }

  input.onKeyPress(
    (keys) => keys.has("A"),
    () => {
      const selectedEntity = getSelectedEntity();
      if (!selectedEntity) return;

      const highlightedEntity = getHighlightedEntity();
      if (!highlightedEntity) return;

      attack(selectedEntity, highlightedEntity);
    }
  );

  input.onKeyPress(
    (keys) => keys.has("R"),
    () => {
      const selectedEntity = getSelectedEntity();
      if (!selectedEntity) return;

      rest(selectedEntity);
    }
  );
}
