export enum Tileset {
  Grass = 0,
  Grass2 = 1,
  Forest = 2,
  Forest2 = 3,
  Forest3 = 4,
  Mountain = 5,
  Mountain2 = 6,
  Mountain3 = 7,
  Water = 8,
  Blank = 9,
}
export enum TileAnimationKey {}
export const TileAnimations: { [key in TileAnimationKey]: number[] } = {};
export enum WangSetKey {
  StoneWall = "StoneWall",
  Water = "Water",
}
export const WangSets: { [key in WangSetKey]: { [key: number]: number } } = {
  [WangSetKey.StoneWall]: {
    1: 17,
    4: 18,
    5: 19,
    16: 20,
    17: 21,
    20: 22,
    21: 23,
    64: 24,
    65: 25,
    68: 26,
    69: 27,
    80: 28,
    81: 29,
    84: 30,
    85: 31,
  },
  [WangSetKey.Water]: {
    1: 33,
    4: 34,
    5: 35,
    16: 36,
    17: 37,
    20: 38,
    21: 39,
    64: 40,
    65: 41,
    68: 42,
    69: 43,
    80: 44,
    81: 45,
    84: 46,
    85: 47,
  },
};
