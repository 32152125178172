import { GodID } from "@latticexyz/network";
import { getComponentValue } from "@latticexyz/recs";
import React from "react";
import { concat, map } from "rxjs";
import { registerUIComponent } from "../engine";

export function registerLoadingScreen() {
  registerUIComponent(
    "Loading",
    {
      colStart: 1,
      colEnd: 12,
      rowStart: 1,
      rowEnd: 12,
    },
    (layers) => {
      const {
        network: {
          world,
          components: { Loading },
        },
      } = layers;

      return concat([1], Loading.update$).pipe(
        map(() => {
          const god = world.entityToIndex.get(GodID);
          if (!god) return { layers };
          const loadingState = getComponentValue(Loading, god);
          if (loadingState && loadingState.percentage === 100) return;

          return { layers, loadingState };
        })
      );
    },
    ({ loadingState }) => {
      return (
        <div
          style={{
            backgroundImage: "url(assets/splash.jpg)",
            backgroundSize: "100%",
            backgroundPosition: "center",
            zIndex: "1000",
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "32px",
              textAlign: "center",
              alignSelf: "flex-start",
            }}
          >
            <img src="assets/logo.png" height={300} />
            <br />
            <br />
            <div
              style={{
                backgroundColor: "rgba(0,0,0, 0.5)",
                padding: "16px",
                maxWidth: "500px"
              }}
            >
              <h1>{loadingState?.msg}</h1>
              <h1>Loading... {loadingState?.percentage}%</h1>
            </div>
          </div>
        </div>
      );
    }
  );
}
