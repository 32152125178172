import { pixelCoordToTileCoord } from "@latticexyz/phaserx";
import {
  defineRxSystem,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  Not,
  runQuery,
} from "@latticexyz/recs";
import { Coord } from "@latticexyz/utils";
import { debounceTime, fromEvent, merge, Subscription } from "rxjs";
import { ItemTypes, StructureTypes, TerrainTypes } from "../../../../Network";
import { PhaserLayer } from "../../types";

export function createDrawMinimapSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { UnitType, OwnedBy, StructureType, TerrainType, ItemType, Prototype },
      },
      local: {
        components: { LocalPosition },
        api: { getOwnerColor, onPlayerLoaded },
      },
    },
    scenes: {
      Main: {
        camera,
        maps: {
          Main: { tileWidth, tileHeight },
        },
        input,
      },
      UI: { phaserScene },
    },
    ui: { isLargeScreen },
  } = layer;

  onPlayerLoaded(() => {
    const MINIMAP_TILE_SIZE = 5;

    let backgroundRectangle: Phaser.GameObjects.Rectangle | undefined;
    let emberCrownTween: Phaser.Tweens.Tween | undefined;
    let mapClickSubscription: Subscription | undefined;
    const spriteGroup = phaserScene.add.group();
    const mapUpdate$ = merge(
      camera.worldView$,
      LocalPosition.update$,
      fromEvent(window, "resize"),
      fromEvent(window, "fullscreenchange")
    ).pipe(debounceTime(50));

    const resetMap = () => {
      spriteGroup.clear(true);
      backgroundRectangle?.destroy();
      emberCrownTween?.stop();
      mapClickSubscription?.unsubscribe();
    };

    const createEmberCrownHighlightTween = (gameObject: Phaser.GameObjects.GameObject) => {
      emberCrownTween = phaserScene.add.tween({
        targets: gameObject,
        width: `+=${MINIMAP_TILE_SIZE * 2}`,
        height: `+=${MINIMAP_TILE_SIZE * 2}`,
        x: `-=${MINIMAP_TILE_SIZE}`,
        y: `-=${MINIMAP_TILE_SIZE}`,
        ease: "Linear",
        duration: 200,
        repeat: -1,
        yoyo: true,
      });
    };

    const getEmberCrownOwner = () => {
      const emberCrown = [...runQuery([HasValue(ItemType, { value: ItemTypes.EmberCrown }), Not(Prototype)])][0];
      const emberCrownOwnerId = getComponentValue(OwnedBy, emberCrown)?.value;
      let emberCrownOwner: EntityIndex | undefined;
      if (emberCrownOwnerId) {
        emberCrownOwner = world.entityToIndex.get(emberCrownOwnerId);
      }

      return emberCrownOwner;
    };

    const drawbackgroundRectangle = (position: Coord, radius: number) => {
      backgroundRectangle = phaserScene.add.rectangle(position.x, position.y, radius * 2, radius * 2);
      backgroundRectangle.setFillStyle(0x24131a);
      backgroundRectangle.setScrollFactor(0);
      backgroundRectangle.setDepth(70);
      backgroundRectangle.setStrokeStyle(3, 0x8a5e3b, 1);
    };

    const calculateColor = (entity: EntityIndex, emberCrownOwner: EntityIndex | undefined) => {
      let color = 0x000000;

      if (entity === emberCrownOwner) {
        return 0xffd700;
      }

      if (hasComponent(UnitType, entity)) {
        color = getOwnerColor(entity);
      }

      if (hasComponent(StructureType, entity)) {
        const type = getComponentValueStrict(StructureType, entity).value;

        if (type === StructureTypes.WoodenWall) {
          color = 0x804000;
        } else if (type === StructureTypes.EscapePortal) {
          color = 0xb00b1e;
        } else {
          color = getOwnerColor(entity);
        }
      }

      if (hasComponent(TerrainType, entity)) {
        const type = getComponentValueStrict(TerrainType, entity).value;
        if (type === TerrainTypes.StoneWall) {
          color = 0xbebebe;
        } else if (type === TerrainTypes.Grass) {
          color = 0x3cb043;
        } else if (type === TerrainTypes.Mountain) {
          color = 0x8c8b8b;
        } else if (type === TerrainTypes.Forest) {
          color = 0x27732c;
        } else if (type === TerrainTypes.Water) {
          color = 0x35559c;
        }
      }

      return color;
    };

    defineRxSystem(world, mapUpdate$, () => {
      resetMap();
      const emberCrownOwner = getEmberCrownOwner();

      const cameraCenter = pixelCoordToTileCoord(
        {
          x: camera.phaserCamera.worldView.centerX,
          y: camera.phaserCamera.worldView.centerY,
        },
        tileWidth,
        tileHeight
      );

      const gameCanvas = phaserScene.sys.game.canvas;
      const minimapRadius = isLargeScreen() ? 100 : 75;
      const horizontalOffset = isLargeScreen() ? 200 : 56;
      const minimapPosition = {
        x: gameCanvas.width / 2 - minimapRadius * 2 - horizontalOffset,
        y: gameCanvas.height - minimapRadius,
      };

      mapClickSubscription = input.pointerdown$.subscribe(({ pointer }) => {
        const distanceFromMinimapPosition = Phaser.Math.Distance.BetweenPoints(minimapPosition, pointer);
        if (distanceFromMinimapPosition > minimapRadius) return;

        const center = {
          x: (pointer.x - minimapPosition.x) / MINIMAP_TILE_SIZE + cameraCenter.x,
          y: (pointer.y - minimapPosition.y) / MINIMAP_TILE_SIZE + cameraCenter.y,
        };

        camera.centerOnCoord(center, tileWidth, tileHeight);
      });

      drawbackgroundRectangle(minimapPosition, minimapRadius);

      const entitiesWithPosition = runQuery([Has(LocalPosition)]);
      for (const entity of entitiesWithPosition) {
        const position = getComponentValueStrict(LocalPosition, entity);

        const xDistance = Math.abs(position.x - cameraCenter.x);
        const yDistance = Math.abs(position.y - cameraCenter.y);
        const maxDistanceFromCamerCenter = minimapRadius / MINIMAP_TILE_SIZE - 1;
        if (yDistance > maxDistanceFromCamerCenter || xDistance > maxDistanceFromCamerCenter) continue;

        const holdingEmberCrown = entity === emberCrownOwner;

        let depth = 72;
        let alpha = 1;
        const color = calculateColor(entity, emberCrownOwner);

        if (hasComponent(TerrainType, entity)) {
          depth = 70;
          alpha = 0.6;
        } else if (holdingEmberCrown) {
          depth = 80;
        }

        const rec = phaserScene.add.rectangle();
        spriteGroup.add(rec, false);

        const renderPosition = {
          x: position.x - cameraCenter.x,
          y: position.y - cameraCenter.y,
        };

        rec.setSize(MINIMAP_TILE_SIZE, MINIMAP_TILE_SIZE);
        rec.setFillStyle(color);
        rec.setPosition(
          minimapPosition.x + renderPosition.x * MINIMAP_TILE_SIZE,
          minimapPosition.y + renderPosition.y * MINIMAP_TILE_SIZE
        );
        rec.setDepth(depth);
        rec.setScrollFactor(0);
        rec.setAlpha(alpha);

        if (holdingEmberCrown) {
          createEmberCrownHighlightTween(rec);
        }
      }
    });
  });
}
