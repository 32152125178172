import {
  defineEnterSystem,
  defineExitSystem,
  defineUpdateSystem,
  getComponentValueStrict,
  Has,
  hasComponent,
  Not,
  removeComponent,
  setComponent,
} from "@latticexyz/recs";
import { LocalLayer } from "../../types";
import { aStar } from "../../../../utils/pathfinding";
import { worldCoordEq } from "../../../../utils/coords";
import { getOwningPlayer } from "@latticexyz/std-client";
import { curry } from "lodash";

/**
 * The Position system handles pathing locally for entities if their network layer Position changed.
 */
export function createPositionSystem(layer: LocalLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Position, Movable, Player, OwnedBy },
        utils: { getMoveSpeed },
      },
      headless: {
        actions: { withOptimisticUpdates },
        api: { isUntraversable, getMovementDifficulty },
      },
    },
    components: { LocalPosition, Path },
  } = layer;

  defineEnterSystem(world, [Has(Position)], ({ entity, value }) => {
    const [currentValue] = value;
    if (!hasComponent(LocalPosition, entity) && currentValue) {
      setComponent(LocalPosition, entity, currentValue);
    }
  });

  // This is a hack to make sure LocalPosition gets cleared
  // when things die outside of Combat/Summoning. The only time this
  // happens right now is when editing an Entity manually in the
  // Component Browser.
  defineExitSystem(world, [Has(Position)], ({ entity }) => {
    setTimeout(() => {
      removeComponent(LocalPosition, entity);
    }, 1_500);
  });

  defineUpdateSystem(world, [Has(Position), Not(Movable)], ({ entity, component, value }) => {
    if (component !== Position) return;
    const [targetPosition] = value;
    if (!targetPosition) return;

    setComponent(LocalPosition, entity, targetPosition);
  });

  const OptimisticPosition = withOptimisticUpdates(Position);
  defineUpdateSystem(world, [Has(OptimisticPosition), Has(Movable), Has(LocalPosition)], (update) => {
    const { entity } = update;
    // Remove any outstanding Paths before computing the new location
    // removeComponent(Path, entity);

    const moveSpeed = getMoveSpeed(entity);
    if (!moveSpeed) return;

    const targetPosition = getComponentValueStrict(OptimisticPosition, entity);
    const localPosition = getComponentValueStrict(LocalPosition, entity);
    if (worldCoordEq(targetPosition, localPosition)) return;

    const playerEntity = getOwningPlayer(entity, world, Player, OwnedBy);
    if (playerEntity == null) return;

    const path = aStar(
      localPosition,
      targetPosition,
      moveSpeed,
      curry(getMovementDifficulty)(LocalPosition, playerEntity),
      curry(isUntraversable)(LocalPosition, playerEntity)
    );

    if (path.length > 0) {
      const x: number[] = [];
      const y: number[] = [];
      for (const coord of path) {
        x.push(coord.x);
        y.push(coord.y);
      }

      setComponent(Path, entity, { x, y });
    } else {
      // If no Path to the target is found, we assume that the
      // Position change occurred outside of normal movement
      // and just set LocalPosition manually.
      setComponent(LocalPosition, entity, targetPosition);
    }
  });
}
