import { EntityIndex, EntityID, World } from "@latticexyz/recs";
import { ActionSystem } from "@latticexyz/std-client";
import { NetworkLayer } from "../../Network";

export function transferInventory(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    world: World;
  },
  inventoryOwnerEntity: EntityIndex,
  receiverEntity: EntityIndex
) {
  const {
    network: { api: networkApi },
    world,
    actions,
  } = context;

  const actionID = `transfer inventory ${Date.now()}` as EntityID; // Date.now to have the actions ordered in the component browser

  actions.add({
    id: actionID,
    components: {},
    on: inventoryOwnerEntity,
    requirement: () => true,
    updates: () => [],
    execute: async () => {
      return networkApi.transferInventory(world.entities[inventoryOwnerEntity], world.entities[receiverEntity]);
    },
  });
}
