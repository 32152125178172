import { WorldCoord } from "@latticexyz/phaserx/src/types";
import { EntityIndex, EntityID, World } from "@latticexyz/recs";
import { ActionSystem } from "@latticexyz/std-client";
import { NetworkLayer } from "../../Network";

export function buildAt(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    world: World;
  },
  builderId: EntityIndex,
  prototypeId: string,
  position: WorldCoord
) {
  const {
    network: { api: networkApi },
    world,
    actions,
  } = context;

  const actionID = `build ${Date.now()}` as EntityID; // Date.now to have the actions ordered in the component browser

  actions.add({
    id: actionID,
    components: {},
    on: builderId,
    requirement: () => true,
    updates: () => [],
    execute: async () => {
      return networkApi.buildAt(world.entities[builderId], prototypeId, position);
    },
  });
}
