import { registerComponentBrowser } from "./ComponentBrowser";
import { registerJoinGame } from "./JoinGame";
import { registerGameOutcome } from "./GameOutcome";
import { registerSelection } from "./Selection";
import { registerTurnTimer } from "./TurnTimer";
import { registerSummonerView } from "./Summoner";
import { registerLeaderboard } from "./Leaderboard";
import { registerFullscreenButton } from "./FullscreenButton";
import { registerAlerts } from "./Alerts";
import { registerLoadingScreen } from "./LoadingScreen";
import { registerAdminPanel } from "./AdminPanel";
import { registerTutorial } from "./Tutorial";

export function registerUIComponents() {
  registerTurnTimer();
  registerJoinGame();
  registerGameOutcome();
  registerComponentBrowser();
  // registerSummonerView();
  registerSelection();
  registerLeaderboard();
  registerFullscreenButton();
  // registerAlerts();
  registerTutorial();
  registerAdminPanel();

  // ALWAYS LAST
  registerLoadingScreen();
}
