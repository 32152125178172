import { WorldCoord } from "@latticexyz/phaserx/src/types";
import {
  EntityID,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  runQuery,
} from "@latticexyz/recs";
import { BigNumber } from "ethers";
import { solidityKeccak256 } from "ethers/lib/utils";
import { NetworkLayer } from "../../Network";
import { CombatTypes } from "../../Network/types";

// This file mirrors the functionality found in LibCombat.sol

export function calculateCombatResult(
  layer: NetworkLayer,
  attacker: EntityIndex,
  defender: EntityIndex,
  rangedAttack = false
) {
  const strengthDifference =
    isNeutralStructure(layer, defender) && !rangedAttack
      ? 100_000
      : calculateStrengthDifference(layer, attacker, defender, rangedAttack);
  const attackerDamage = calculateDamage(strengthDifference);
  const defenderDamage =
    isPassive(layer, defender) || rangedAttack
      ? 0
      : calculateDamage(-strengthDifference) * getCounterDamageModifier(layer, defender);

  return {
    attackerDamage: Math.min(Math.round(attackerDamage), 100),
    defenderDamage: Math.min(Math.round(defenderDamage), 100),
  };
}

export function calculateDamage(strengthDifference: number) {
  // Contract values are all multiplied by 1000
  const difference = strengthDifference / 1000;
  return 30 * Math.E ** (0.04 * difference);
}

export function calculateStrengthDifference(
  layer: NetworkLayer,
  attacker: EntityIndex,
  defender: EntityIndex,
  rangedAttack = false
) {
  const {
    world,
    components: { Position, Combat, CombatStrength, RangedCombat },
  } = layer;

  const attackerCombat = getComponentValueStrict(Combat, attacker);
  const defenderCombat = getComponentValueStrict(Combat, defender);

  const attackerPosition = getComponentValueStrict(Position, attacker);
  const attackerTerrainCombatModifer = getCombatStrengthModiferAtPosition(layer, attackerPosition);
  const attackerItemCombatModifier = getCombatStrengthModiferFromItems(layer, attacker);

  const attackerStrengthBonusId = solidityKeccak256(
    ["string", "uint32"],
    ["mudwar.CombatStrength", rangedAttack ? CombatTypes.Ranged : attackerCombat._type]
  );
  const attackerStrengthBonusIndex = world.entityToIndex.get(attackerStrengthBonusId as EntityID);
  if (!attackerStrengthBonusIndex) return 0;

  const attackerStrengthBonus = getComponentValueStrict(CombatStrength, attackerStrengthBonusIndex)
    .combatTypeStrengthBonuses[defenderCombat._type];
  const attackerStrength =
    calculateStrengthFromRemainingHealth(
      rangedAttack ? getComponentValueStrict(RangedCombat, attacker).strength : attackerCombat.strength,
      attackerCombat.health
    ) +
    attackerStrengthBonus +
    attackerTerrainCombatModifer +
    attackerItemCombatModifier;

  const defenderPosition = getComponentValueStrict(Position, defender);
  const defenderTerrainCombatModifer = getCombatStrengthModiferAtPosition(layer, defenderPosition);
  const defenderItemCombatModifier = getCombatStrengthModiferFromItems(layer, defender);

  const defenderStrengthBonusId = solidityKeccak256(
    ["string", "uint32"],
    ["mudwar.CombatStrength", defenderCombat._type]
  );
  const defenderStrengthBonusIndex = world.entityToIndex.get(defenderStrengthBonusId as EntityID);
  if (!defenderStrengthBonusIndex) return 0;

  const defenderStrengthBonus = getComponentValueStrict(CombatStrength, defenderStrengthBonusIndex)
    .combatTypeStrengthBonuses[attackerCombat._type];
  const defenderStrength =
    calculateStrengthFromRemainingHealth(defenderCombat.strength, defenderCombat.health) +
    defenderStrengthBonus +
    defenderTerrainCombatModifer +
    defenderItemCombatModifier;

  return attackerStrength - defenderStrength;
}

export function getCombatStrengthModiferAtPosition(layer: NetworkLayer, position: WorldCoord) {
  const {
    components: { Position, TerrainType, CombatStrengthModifier },
  } = layer;

  const entityWithModifier = [
    ...runQuery([HasValue(Position, position), Has(TerrainType), Has(CombatStrengthModifier)]),
  ][0];
  const modifier = getComponentValue(CombatStrengthModifier, entityWithModifier);
  if (!modifier) return 0;

  return modifier.value;
}

export function getCombatStrengthModiferFromItems(layer: NetworkLayer, entity: EntityIndex) {
  const {
    components: { CombatStrengthModifier },
    utils: { getItems },
  } = layer;
  const items = getItems(entity);

  let strengthModifier = 0;
  for (const item of items) {
    const mod = getComponentValue(CombatStrengthModifier, item)?.value;
    if (mod) strengthModifier += mod;
  }

  return strengthModifier;
}

export function calculateStrengthFromRemainingHealth(strength: number, health: number) {
  return strength - (100_000 - health) / 10;
}

export function isNeutralStructure(layer: NetworkLayer, entity: EntityIndex) {
  const {
    components: { StructureType, OwnedBy },
  } = layer;

  return (
    hasComponent(StructureType, entity) &&
    (!hasComponent(OwnedBy, entity) || BigNumber.from(getComponentValue(OwnedBy, entity)?.value) === BigNumber.from(0))
  );
}

export function isPassive(layer: NetworkLayer, entity: EntityIndex) {
  const {
    components: { Combat },
  } = layer;

  return getComponentValue(Combat, entity)?.counterStrength === 0 || isNeutralStructure(layer, entity);
}

export function canRetaliate(layer: NetworkLayer, entity: EntityIndex) {
  return !isPassive(layer, entity) && !isNeutralStructure(layer, entity);
}

function getCounterDamageModifier(layer: NetworkLayer, entity: EntityIndex) {
  const {
    components: { Combat },
  } = layer;

  return getComponentValueStrict(Combat, entity).counterStrength / 100;
}
