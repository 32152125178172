import { HueTintAndOutlineFXPipeline, pixelCoordToTileCoord, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineSystem,
  EntityID,
  EntityIndex,
  getComponentValueStrict,
  Has,
  HasValue,
  removeComponent,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { fromEvent, merge, Subscription } from "rxjs";
import { Sprites, UnitTypeAnimations, UnitTypeSprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawFactoryUISystem(layer: PhaserLayer) {
  const {
    world,
    components: { HoverHighlight },
    api: {
      drawTileHighlight,
      mapInteraction: { disableMapInteraction, enableMapInteraction },
    },
    parentLayers: {
      network: {
        components: { Factory, UnitType, Stamina, OwnedBy },
      },
      headless: {
        components: { LocalStamina },
        api: { getCurrentStamina, buildAt },
      },
      local: {
        singletonEntity,
        components: { Selected, LocalPosition, PendingStaminaSpend },
        api: { onPlayerLoaded },
      },
    },
    scenes: {
      Main: {
        phaserScene,
        config,
        objectPool,
        input,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  function disableMapInput() {
    disableMapInteraction();
    removeComponent(HoverHighlight, singletonEntity);
  }

  function fadeIn(gameObject: Phaser.GameObjects.GameObject, to: number, alpha = 1) {
    phaserScene.add.tween({
      targets: gameObject,
      alpha: {
        from: 0,
        to: alpha,
      },
      y: {
        from: to + 8,
        to,
      },
      repeat: 0,
      duration: 150,
      ease: Phaser.Math.Easing.Sine,
    });
  }

  onPlayerLoaded(({ playerColor, playerId }) => {
    let buildClickSubscription: Subscription | undefined;
    let buildPreviewSubscription: Subscription | undefined;
    let buildPreviewSprite: Phaser.GameObjects.Sprite | undefined;

    function clearBuildTiles() {
      for (let i = 0; i < 4; i++) {
        objectPool.remove(`build-position-${i}`);
      }

      buildClickSubscription?.unsubscribe();
      buildPreviewSubscription?.unsubscribe();
      buildPreviewSprite?.destroy();
      buildPreviewSprite = undefined;
    }

    function renderBuildTiles(factory: EntityIndex, prototypeId: EntityID) {
      clearBuildTiles();

      const position = getComponentValueStrict(LocalPosition, factory);
      disableMapInput();
      removeComponent(Selected, factory);
      setComponent(PendingStaminaSpend, factory, { value: true });

      const buildPositions = [
        { ...position, x: position.x + 1 },
        { ...position, x: position.x - 1 },
        { ...position, y: position.y + 1 },
        { ...position, y: position.y - 1 },
      ];

      for (let i = 0; i < buildPositions.length; i++) {
        drawTileHighlight(`build-position-${i}`, buildPositions[i], 0xffff00);
      }

      // TODO Without putting it in a timeout the subscription fires immediately.
      // Why.
      setTimeout(() => {
        buildClickSubscription = merge(input.click$, input.rightClick$).subscribe((pointer) => {
          const tile = pixelCoordToTileCoord(
            {
              x: pointer.worldX,
              y: pointer.worldY,
            },
            tileWidth,
            tileHeight
          );
          for (const buildPosition of buildPositions) {
            if (tile.x === buildPosition.x && tile.y === buildPosition.y) {
              buildAt(factory, prototypeId, buildPosition);
            }
          }

          clearBuildTiles();
          enableMapInteraction();
          setComponent(PendingStaminaSpend, factory, { value: false });
        });

        buildPreviewSubscription = input.pointermove$.subscribe(({ pointer }) => {
          const tile = pixelCoordToTileCoord(
            {
              x: pointer.worldX,
              y: pointer.worldY,
            },
            tileWidth,
            tileHeight
          );
          const previewPosition = tileCoordToPixelCoord(tile, tileWidth, tileHeight);
          const prototypeEntity = world.getEntityIndexStrict(prototypeId);
          const unitType = getComponentValueStrict(UnitType, prototypeEntity).value;
          const spriteAnimation = UnitTypeAnimations[unitType];

          if (!buildPreviewSprite) {
            buildPreviewSprite = phaserScene.add.sprite(tile.x, tile.y, "");
            buildPreviewSprite.play(spriteAnimation);
            buildPreviewSprite.setDepth(130);
            buildPreviewSprite.setOrigin(0, 0);
            buildPreviewSprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
            buildPreviewSprite.setPipelineData("hueTint", playerColor);
          }

          buildPreviewSprite.setPosition(previewPosition.x, previewPosition.y);
          if (!buildPositions.find((bp) => bp.x === tile.x && bp.y === tile.y)) {
            buildPreviewSprite.setTint(0x666666);
          } else {
            buildPreviewSprite.clearTint();
          }
        });
      }, 0);
    }

    let spriteGroup: Phaser.GameObjects.Group | undefined;
    const pointerSubs: Subscription[] = [];

    defineSystem(
      world,
      [
        Has(Selected),
        Has(Factory),
        HasValue(OwnedBy, { value: playerId }),
        Has(Stamina),
        Has(LocalStamina),
        Has(LocalPosition),
      ],
      ({ entity, type }) => {
        spriteGroup?.clear(true);
        pointerSubs.forEach((sub) => sub.unsubscribe());
        clearBuildTiles();

        if (type === UpdateType.Exit || getCurrentStamina(entity) < 1_000) {
          return;
        }

        spriteGroup = phaserScene.add.group();

        const position = getComponentValueStrict(LocalPosition, entity);
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

        const factory = getComponentValueStrict(Factory, entity);
        for (let i = 0; i < factory.prototypeIds.length; i++) {
          const spriteX =
            pixelCoord.x + 16 + i * (tileWidth + 12) - (factory.prototypeIds.length - 1) * ((tileWidth + 12) / 2);
          const prototypeId = factory.prototypeIds[i];
          const prototype = world.getEntityIndexStrict(prototypeId as EntityID);
          const unitType = getComponentValueStrict(UnitType, prototype);
          const spriteConfig = config.sprites[UnitTypeSprites[unitType.value]];

          const sprite = phaserScene.add.image(spriteX, pixelCoord.y - 28, spriteConfig.assetKey, spriteConfig.frame);
          sprite.setDepth(100);
          sprite.setPipeline(HueTintAndOutlineFXPipeline.KEY);
          sprite.setPipelineData("hueTint", playerColor);

          fadeIn(sprite, sprite.y);

          const background = phaserScene.add.rectangle(spriteX, pixelCoord.y - 28, 36, 36);
          background.setFillStyle(0x24131a);
          background.setStrokeStyle(3, 0x8a5e3b);
          background.setDepth(90);
          background.setInteractive();

          spriteGroup.add(background, false);

          fadeIn(background, pixelCoord.y - 28);

          pointerSubs.push(
            merge(fromEvent(background, Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN)).subscribe(() => {
              renderBuildTiles(entity, prototypeId as EntityID);
            })
          );
          pointerSubs.push(
            merge(fromEvent(background, Phaser.Input.Events.GAMEOBJECT_POINTER_OVER)).subscribe(() => {
              sprite.setY(sprite.y - 5);
              background.setY(background.y - 5);
              disableMapInput();
            })
          );
          pointerSubs.push(
            merge(fromEvent(background, Phaser.Input.Events.GAMEOBJECT_POINTER_OUT)).subscribe(() => {
              sprite.setY(sprite.y + 5);
              background.setY(background.y + 5);
              enableMapInteraction();
            })
          );

          fadeIn(background, background.y);

          spriteGroup.add(sprite, false);
          spriteGroup.add(background, false);
        }
      }
    );
  });
}
