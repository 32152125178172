import { defineRxSystem, removeComponent, SchemaOf, setComponent } from "@latticexyz/recs";
import { BigNumber } from "ethers";
import { findLast } from "lodash";
import { PhaserLayer } from "../../types";
import { ethersBnToEntityIndex } from "../../utils";

export function createSummonSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        systemCallStreams,
        components: { Combat },
      },
      local: {
        components: { LocalPosition, LocalHealth },
      },
    },
    animations: { playDeathAnimation },
  } = layer;

  defineRxSystem(world, systemCallStreams["mudwar.system.Summon"], ({ args, updates }) => {
    const { sacrificedEntities: sacrificedEntityBns } = args as {
      summonerEntity: BigNumber;
      sacrificedEntities: BigNumber[];
    };
    const sacrificedEntities = sacrificedEntityBns.map((bn) => ethersBnToEntityIndex(world, bn));

    const summonedEntityHealthUpdate = findLast(updates, (update) => {
      return !sacrificedEntities.includes(update.entity) && update.component.id === Combat.id;
    });
    if (summonedEntityHealthUpdate) {
      const combatUpdate = summonedEntityHealthUpdate.value as SchemaOf<typeof Combat>;
      setComponent(LocalHealth, summonedEntityHealthUpdate.entity, { value: combatUpdate.health });
    }

    for (const entity of sacrificedEntities) {
      playDeathAnimation(entity, () => removeComponent(LocalPosition, entity));
    }
  });
}
