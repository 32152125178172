import React from "react";
import { Browser } from "@latticexyz/ecs-browser";
import { registerUIComponent } from "../engine";
import { map } from "rxjs";

export function registerComponentBrowser() {
  registerUIComponent(
    "ComponentBrowser",
    {
      colStart: 10,
      colEnd: 13,
      rowStart: 1,
      rowEnd: 13,
    },
    (layers) => {
      const {
        local: {
          components: { DevMode },
        },
      } = layers;

      return DevMode.update$.pipe(
        map((update) => {
          if (!update.value[0]?.value) return null;

          return {
            layers,
            devHighlightComponent: layers.phaser.components.DevHighlight,
            hoverHighlightComponent: layers.phaser.components.HoverHighlight,
            world: layers.network.world,
          };
        })
      );
    },
    ({ layers, world, devHighlightComponent, hoverHighlightComponent }) => {
      return (
        <Browser
          world={world}
          entities={world.entities}
          layers={layers}
          devHighlightComponent={devHighlightComponent}
          hoverHighlightComponent={hoverHighlightComponent}
          setContractComponentValue={layers.network.api.dev.setContractComponentValue}
          spawnPrototypeAt={layers.network.api.dev.spawnPrototypeAt}
          prototypeComponent={layers.network.components.Prototype}
          nameComponent={layers.local.components.LocalName}
        />
      );
    }
  );
}
