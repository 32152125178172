import {
  defineSceneConfig,
  AssetType,
  defineScaleConfig,
  defineMapConfig,
  defineCameraConfig,
} from "@latticexyz/phaserx";
import { Sprites, Assets, Maps, Scenes, TILE_HEIGHT, TILE_WIDTH, Animations, WALK_ANIMATIONS } from "./phaserConstants";
import { Tileset as OverworldTileset, TileAnimations as OverworldTileAnimations } from "./tilesets/overworldTileset";
import { Tileset as TestTileset, TileAnimations as TestTileAnimations } from "./tilesets/32x32OverworldTileset";
import overworldTileset from "../assets/tilesets/upscale-tileset.png";
import { UnitTypes } from "../../Network";
import { toLower } from "lodash";
import { UnitTypeNames } from "../../Network/types";

const ANIMATION_INTERVAL = 200;

function createWalkAnimations() {
  const animationConfigs: {
    key: string;
    assetKey: Assets.MainAtlas;
    startFrame: number;
    endFrame: number;
    frameRate: number;
    repeat: number;
    prefix: string;
    suffix: string;
  }[] = [];

  for (const unitTypeString in UnitTypes) {
    const unitType = Number(unitTypeString) as UnitTypes;
    if (!WALK_ANIMATIONS[unitType]) continue;

    const walkAnimationKeys = WALK_ANIMATIONS[unitType];
    const directions = ["up", "down", "left", "right"];
    walkAnimationKeys.forEach((key, i) => {
      animationConfigs.push({
        key,
        assetKey: Assets.MainAtlas,
        startFrame: 0,
        endFrame: 3,
        frameRate: 4,
        repeat: -1,
        prefix: `sprites/units/${toLower(UnitTypeNames[unitType])}/walk/${directions[i]}/`,
        suffix: ".png",
      });
    });
  }

  return animationConfigs;
}

export const config = {
  sceneConfig: {
    [Scenes.Main]: defineSceneConfig({
      assets: {
        [Assets.OverworldTileset]: { type: AssetType.Image, key: Assets.OverworldTileset, path: overworldTileset },
        [Assets.MainAtlas]: {
          type: AssetType.MultiAtlas,
          key: Assets.MainAtlas,
          path: "/atlases/sprites/atlas.json",
          options: {
            imagePath: "/atlases/sprites/",
          },
        },
        [Assets.Background]: {
          type: AssetType.Image,
          key: Assets.Background,
          path: "/assets/background.png",
        },
        [Assets.CloudBackground]: {
          type: AssetType.Image,
          key: Assets.CloudBackground,
          path: "/assets/cloud-background.png",
        },
        [Assets.Cloud1]: {
          type: AssetType.Image,
          key: Assets.Cloud1,
          path: "/assets/cloud-1.png",
        },
        [Assets.Cloud2]: {
          type: AssetType.Image,
          key: Assets.Cloud2,
          path: "/assets/cloud-2.png",
        },
        [Assets.TiledBackground]: {
          type: AssetType.Image,
          key: Assets.TiledBackground,
          path: "/assets/tiled-background-4.png",
        },
        [Assets.IslandDepth]: {
          type: AssetType.Image,
          key: Assets.IslandDepth,
          path: "/assets/island-depth.png",
        },
        [Assets.Professor]: {
          type: AssetType.Image,
          key: Assets.Professor,
          path: "/assets/professor.png",
        },
        [Assets.Splash]: {
          type: AssetType.Image,
          key: Assets.Splash,
          path: "/assets/splash.png",
        },
      },
      maps: {
        [Maps.Main]: defineMapConfig({
          chunkSize: TILE_WIDTH * 64, // tile size * tile amount
          tileWidth: TILE_WIDTH,
          tileHeight: TILE_HEIGHT,
          backgroundTile: [TestTileset.Blank],
          animationInterval: ANIMATION_INTERVAL,
          tileAnimations: TestTileAnimations,
          layers: {
            layers: {
              Background: { tilesets: ["Default"], hasHueTintShader: true },
              Foreground: { tilesets: ["Default"], hasHueTintShader: true },
            },
            defaultLayer: "Background",
          },
        }),
        [Maps.Tactic]: defineMapConfig({
          chunkSize: TILE_WIDTH * 64, // tile size * tile amount
          tileWidth: TILE_WIDTH * 4,
          tileHeight: TILE_HEIGHT * 4,
          backgroundTile: [OverworldTileset.Brick1],
          animationInterval: ANIMATION_INTERVAL,
          tileAnimations: OverworldTileAnimations,
          layers: {
            layers: {
              Background: { tilesets: ["Default"] },
            },
            defaultLayer: "Background",
          },
        }),
        [Maps.Strategic]: defineMapConfig({
          chunkSize: TILE_WIDTH * 64 * 8, // tile size * tile amount
          tileWidth: TILE_WIDTH * 16,
          tileHeight: TILE_HEIGHT * 16,
          backgroundTile: [OverworldTileset.Brick1],
          animationInterval: ANIMATION_INTERVAL,
          tileAnimations: OverworldTileAnimations,
          layers: {
            layers: {
              Background: { tilesets: ["Default"] },
            },
            defaultLayer: "Background",
          },
        }),
      },
      sprites: {
        [Sprites.Settlement]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/settlement.png",
        },
        [Sprites.SpawnSettlement]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/settlement-flag.png",
        },
        [Sprites.Gold]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/items/gold.png",
        },
        [Sprites.Container]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/items/bag.png",
        },
        [Sprites.GoldShrine]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/small/gold-shrine.png",
        },
        [Sprites.Portal]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/small/portal-grayscale/0.png",
        },
        [Sprites.EmberCrown]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/items/wood.png",
        },
        [Sprites.SummoningAltar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/summoning-altar/0.png",
        },
        [Sprites.WoodenWall]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/wooden-barricade.png",
        },
        [Sprites.Donkey]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/workers/donkey.png",
        },
        [Sprites.Soldier]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/hero.png",
        },
        [Sprites.Spear]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/pikeman.png",
        },
        [Sprites.SelectUI]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/big-select.png",
        },
        [Sprites.Chain]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/chain.png",
        },
        [Sprites.Cog]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/cog.png",
        },
        [Sprites.Longbowman]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/archer.png",
        },
        [Sprites.Ballista]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/archer.png",
        },

        [Sprites.Crystal]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/structures/big/charging-crystal/0.png",
        },

        [Sprites.HealthBar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/bar-5.png",
        },
        [Sprites.StaminaBar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/bar-3.png",
        },
        [Sprites.ResourceBar]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/bar-1.png",
        },
        [Sprites.YellowTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/yellow-tick.png",
        },
        [Sprites.GreenTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/green-tick.png",
        },
        [Sprites.OrangeTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/orange-tick.png",
        },
        [Sprites.RedTick]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/red-tick.png",
        },

        [Sprites.Swordsman]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/swordsman/idle/0.png",
        },
        [Sprites.Pikeman]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/pikeman/idle/0.png",
        },
        [Sprites.Golem]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/golem/idle/0.png",
        },

        [Sprites.Archer]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/archer/idle/0.png",
        },
        [Sprites.Catapult]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/catapult/idle/0.png",
        },
        [Sprites.Wizard]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/big/wizardry/idle/0.png",
        },

        [Sprites.Rider]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/rider/idle/0.png",
        },
        [Sprites.Knight]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/units/knight/idle/0.png",
        },
        [Sprites.Dragon]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/warriors/big/dragon/idle/0.png",
        },

        [Sprites.ZeroCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/0.png",
        },
        [Sprites.OneCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/1.png",
        },
        [Sprites.TwoCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/2.png",
        },
        [Sprites.ThreeCircle]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/stamina/3.png",
        },

        [Sprites.Zero]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/0.png",
        },
        [Sprites.One]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/1.png",
        },
        [Sprites.Two]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/2.png",
        },
        [Sprites.Three]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/3.png",
        },
        [Sprites.Four]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/4.png",
        },
        [Sprites.Five]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/5.png",
        },
        [Sprites.Six]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/6.png",
        },
        [Sprites.Seven]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/7.png",
        },
        [Sprites.Eight]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/8.png",
        },
        [Sprites.Nine]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/digits/9.png",
        },

        [Sprites.CombatPreview]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/combat-preview.png",
        },
        [Sprites.BuildFrame]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/frames/build-frame.png",
        },
        [Sprites.UnitFrame]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/frames/unit-frame.png",
        },
        [Sprites.CrossedSwords]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/crossed-swords.png",
        },
        [Sprites.PercentGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/Percentage-green.png",
        },
        [Sprites.PercentRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/Percentage-Red.png",
        },

        [Sprites.ZeroRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/0-Red.png",
        },
        [Sprites.OneRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/1-Red.png",
        },
        [Sprites.TwoRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/2-Red.png",
        },
        [Sprites.ThreeRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/3-Red.png",
        },
        [Sprites.FourRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/4-Red.png",
        },
        [Sprites.FiveRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/5-Red.png",
        },
        [Sprites.SixRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/6-Red.png",
        },
        [Sprites.SevenRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/7-Red.png",
        },
        [Sprites.EightRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/8-Red.png",
        },
        [Sprites.NineRed]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/9-Red.png",
        },

        [Sprites.ZeroGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/0-Green.png",
        },
        [Sprites.OneGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/1-Green.png",
        },
        [Sprites.TwoGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/2-Green.png",
        },
        [Sprites.ThreeGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/3-Green.png",
        },
        [Sprites.FourGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/4-Green.png",
        },
        [Sprites.FiveGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/5-Green.png",
        },
        [Sprites.SixGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/6-Green.png",
        },
        [Sprites.SevenGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/7-Green.png",
        },
        [Sprites.EightGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/8-Green.png",
        },
        [Sprites.NineGreen]: {
          assetKey: Assets.MainAtlas,
          frame: "sprites/ui/icons/9-Green.png",
        },
      },
      animations: [
        //UI
        {
          key: Animations.ChainCarousel,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 7,
          frameRate: 12,
          repeat: -1,
          prefix: "sprites/ui/icons/chain-carousel/",
          suffix: ".png",
        },

        {
          key: Animations.Portal,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 59,
          frameRate: 22,
          repeat: -1,
          prefix: "sprites/structures/big/portal-greyscale/",
          suffix: ".png",
        },
        {
          key: Animations.ChargingCrystal,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 24,
          frameRate: 10,
          repeat: -1,
          prefix: "sprites/structures/big/charging-crystal/",
          suffix: ".png",
        },
        {
          key: Animations.SummoningAltar,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 24,
          frameRate: 10,
          repeat: -1,
          prefix: "sprites/structures/big/summoning-altar/",
          suffix: ".png",
        },
        {
          key: Animations.GoldShrine,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 59,
          frameRate: 15,
          repeat: -1,
          prefix: "sprites/structures/big/gold-shrine/",
          suffix: ".png",
        },

        // Units
        {
          key: Animations.SwordsmanIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/swordsman/idle/",
          suffix: ".png",
        },
        {
          key: Animations.SwordsmanAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/swordsman/attack/",
          suffix: ".png",
        },
        {
          key: Animations.SwordsmanDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/swordsman/death/",
          suffix: ".png",
        },

        {
          key: Animations.PikemanIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/pikeman/idle/",
          suffix: ".png",
        },
        {
          key: Animations.PikemanAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/pikeman/attack/",
          suffix: ".png",
        },
        {
          key: Animations.PikemanDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/pikeman/death/",
          suffix: ".png",
        },

        {
          key: Animations.GolemIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/golem/idle/",
          suffix: ".png",
        },
        {
          key: Animations.GolemAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/golem/attack/",
          suffix: ".png",
        },
        {
          key: Animations.GolemDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/warriors/big/golem/death/",
          suffix: ".png",
        },

        {
          key: Animations.ArcherIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/archer/idle/",
          suffix: ".png",
        },
        {
          key: Animations.ArcherAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/archer/attack/",
          suffix: ".png",
        },
        {
          key: Animations.ArcherDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/archer/death/",
          suffix: ".png",
        },

        {
          key: Animations.CatapultIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/catapult/idle/",
          suffix: ".png",
        },
        {
          key: Animations.CatapultAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/catapult/attack/",
          suffix: ".png",
        },
        {
          key: Animations.CatapultDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/warriors/big/catapult/death/",
          suffix: ".png",
        },

        {
          key: Animations.WizardIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/warriors/big/wizardry/idle/",
          suffix: ".png",
        },
        {
          key: Animations.WizardAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/warriors/big/wizardry/attack/",
          suffix: ".png",
        },
        {
          key: Animations.WizardDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/warriors/big/wizardry/death/",
          suffix: ".png",
        },

        {
          key: Animations.RiderIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/rider/idle/",
          suffix: ".png",
        },
        {
          key: Animations.RiderAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/rider/attack/",
          suffix: ".png",
        },
        {
          key: Animations.RiderDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/rider/death/",
          suffix: ".png",
        },

        {
          key: Animations.KnightIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/units/knight/idle/",
          suffix: ".png",
        },
        {
          key: Animations.KnightAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/units/knight/attack/",
          suffix: ".png",
        },
        {
          key: Animations.KnightDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/units/knight/death/",
          suffix: ".png",
        },

        {
          key: Animations.DragonIdle,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/warriors/big/dragon/idle/",
          suffix: ".png",
        },
        {
          key: Animations.DragonAttack,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 5,
          frameRate: 8,
          repeat: 0,
          prefix: "sprites/warriors/big/dragon/attack/",
          suffix: ".png",
        },
        {
          key: Animations.DragonDeath,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 2,
          frameRate: 4,
          repeat: 0,
          prefix: "sprites/warriors/big/dragon/death/",
          suffix: ".png",
        },

        // Items
        {
          key: Animations.EmberCrown,
          assetKey: Assets.MainAtlas,
          startFrame: 0,
          endFrame: 3,
          frameRate: 4,
          repeat: -1,
          prefix: "sprites/items/ember-crown/",
          suffix: ".png",
        },
        {
          key: Animations.BlazingHeart,
          assetKey: Assets.MainAtlas,
          startFrame: 15,
          endFrame: 18,
          frameRate: 6,
          repeat: -1,
          prefix: "sprites/items/blazing-heart/",
          suffix: ".png",
        },
        ...createWalkAnimations(),
      ],
      tilesets: {
        Default: { assetKey: Assets.OverworldTileset, tileWidth: TILE_WIDTH, tileHeight: TILE_HEIGHT },
      },
    }),
    [Scenes.UI]: defineSceneConfig({
      assets: {},
      maps: {},
      animations: [],
      tilesets: {},
      sprites: {},
    }),
  },
  scale: defineScaleConfig({
    parent: "phaser-game",
    zoom: 1,
    mode: Phaser.Scale.NONE,
  }),
  cameraConfig: defineCameraConfig({
    phaserSelector: "phaser-game",
    pinchSpeed: 1,
    wheelSpeed: 1,
    maxZoom: 3,
    minZoom: 1,
  }),
  cullingChunkSize: TILE_HEIGHT * 16,
};
