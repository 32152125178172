import { EntityIndex, EntityID, World } from "@latticexyz/recs";
import { ActionSystem } from "@latticexyz/std-client";
import { Coord } from "@latticexyz/utils";
import { NetworkLayer } from "../../Network";

export function teleport(
  context: {
    network: NetworkLayer;
    actions: ActionSystem;
    world: World;
  },
  portal: EntityIndex,
  target: EntityID,
  teleportee: EntityIndex,
  destination: Coord
) {
  const {
    network: { api: networkApi },
    world,
    actions,
  } = context;

  const actionID = `teleport ${Date.now()}` as EntityID; // Date.now to have the actions ordered in the component browser

  actions.add({
    id: actionID,
    components: {},
    on: portal,
    requirement: () => true,
    updates: () => [],
    execute: async () => {
      return networkApi.teleport(world.entities[portal], target, world.entities[teleportee], destination);
    },
  });
}
