import { HueTintAndOutlineFXPipeline, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineSystem, getComponentValueStrict, Has, Not, UpdateType } from "@latticexyz/recs";
import { filter, map } from "rxjs";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";

export function createSelectionSystem(layer: PhaserLayer) {
  const {
    world,
    scenes: {
      Main: { input, maps, objectPool, config },
    },
    parentLayers: {
      local: {
        components: { LocalPosition, Path, Selected },
        api: { selectArea, resetSelection, getOwnerColor },
      },
    },
    api: {
      mapInteraction: { mapInteractionEnabled },
    },
  } = layer;

  // Reset selected area on ECS click
  input.onKeyPress(
    (keys) => keys.has("ESC"),
    () => {
      resetSelection();
    }
  );

  // Select 1x1 area on click
  const clickSub = input.click$
    .pipe(
      filter(() => mapInteractionEnabled()),
      filter((pointer) => pointer.button === 0), // left click only
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })),
      map((pixel) => pixelToWorldCoord(maps.Main, pixel)),
      map((worldCoord) => ({ ...worldCoord, width: 1, height: 1 }))
    )
    .subscribe((area) => {
      resetSelection();
      selectArea(area);
    });

  world.registerDisposer(() => {
    clickSub?.unsubscribe();
  });

  defineSystem(world, [Has(Selected), Has(LocalPosition), Not(Path)], ({ entity, type }) => {
    if (type === UpdateType.Exit) {
      return objectPool.remove(`${entity}-select`);
    }

    const selection = objectPool.get(`${entity}-select`, "Sprite");
    const position = getComponentValueStrict(LocalPosition, entity);
    const sprite = config.sprites[Sprites.SelectUI];

    const color = getOwnerColor(entity);

    selection.setComponent({
      id: `selection`,
      once: async (box) => {
        const pixelCoord = tileCoordToPixelCoord(position, maps.Main.tileWidth, maps.Main.tileHeight);

        box.setTexture(sprite.assetKey, sprite.frame);
        box.setSize(maps.Main.tileWidth, maps.Main.tileHeight);
        box.setPosition(pixelCoord.x, pixelCoord.y);
        box.setDepth(13);

        box.setPipeline(HueTintAndOutlineFXPipeline.KEY);
        box.setPipelineData("hueTint", color);
      },
    });
  });
}
