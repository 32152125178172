import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineComponentSystem, defineEnterSystem, getComponentValueStrict, Has } from "@latticexyz/recs";
import { sample, times } from "lodash";
import { Assets } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createCloudSystem(layer: PhaserLayer) {
  const {
    world,
    components: { MapBounds },
    parentLayers: {
      network: {
        components: { Zone, Position },
      },
    },
    scenes: {
      Main: {
        phaserScene,
        maps: {
          Main: { tileHeight, tileWidth },
        },
      },
    },
  } = layer;

  const islandImages: Phaser.GameObjects.Image[] = [];
  let cloudImages: Phaser.GameObjects.Group | undefined;

  defineEnterSystem(world, [Has(Zone), Has(Position)], ({ entity }) => {
    const position = getComponentValueStrict(Position, entity);
    const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

    const island = phaserScene.add.image(pixelCoord.x + 12, pixelCoord.y + 48, Assets.IslandDepth, 0);
    island.setScale(1, 1.05);
    island.setDepth(-5);
    island.setScale(1.03);

    islandImages.push(island);
  });

  // TODO Reimplement in a more performant way

  // const getRandomCloudDelay = () => sample([0, 1000, 2000, 5000, 10000]);
  // const getRandomCloudAsset = () => sample([Assets.Cloud1, Assets.Cloud2]);

  // defineComponentSystem(world, MapBounds, ({ value }) => {
  //   const mapBounds = value[0];
  //   if (!mapBounds) return;

  //   cloudImages?.clear(true);
  //   cloudImages = phaserScene.add.group();

  //   islandImages.forEach((islandImage) => {
  //     times(3).forEach(() => {
  //       const cloudAsset = getRandomCloudAsset();
  //       if (!cloudAsset) return;

  //       const islandMask = islandImage.createBitmapMask();

  //       const topCloud = phaserScene.add.image(
  //         islandImage.x - islandImage.width / 2,
  //         islandImage.y - islandImage.height / 2,
  //         cloudAsset,
  //         0
  //       );
  //       cloudImages?.add(topCloud, false);

  //       topCloud.setMask(islandMask);
  //       topCloud.setDepth(100);
  //       topCloud.setAlpha(0.4);

  //       const createCloudMoveTween = () => {
  //         const verticalOffset = +Phaser.Math.RND.integerInRange(-800, 800);
  //         return {
  //           targets: topCloud,
  //           x: {
  //             from: islandImage.x - islandImage.width / 2 - topCloud.width / 2,
  //             to: islandImage.x + islandImage.width / 2 + topCloud.width / 2,
  //           },
  //           y: {
  //             from: islandImage.y - islandImage.height / 2 + verticalOffset,
  //             to: islandImage.y + islandImage.height / 2 + verticalOffset,
  //           },
  //           repeat: 0,
  //           yoyo: false,
  //           duration: 25_000,
  //           ease: Phaser.Math.Easing.Linear,
  //           onComplete: () => {
  //             setTimeout(() => {
  //               phaserScene.add.tween({
  //                 ...createCloudMoveTween(),
  //                 duration: Phaser.Math.RND.integerInRange(35_000, 60_000),
  //               });
  //             }, getRandomCloudDelay());
  //             topCloud.setScale(Phaser.Math.RND.realInRange(0.5, 1.5));
  //           },
  //         };
  //       };

  //       setTimeout(() => {
  //         phaserScene.add.tween(createCloudMoveTween());
  //         topCloud.setScale(Phaser.Math.RND.realInRange(0.5, 1.5));
  //       }, getRandomCloudDelay());
  //     });
  //   });
  // });
}
