import { Coord, tileCoordToPixelCoord } from "@latticexyz/phaserx";
import {
  defineComponentSystem,
  defineSystem,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  HasValue,
  Not,
  runQuery,
  UpdateType,
} from "@latticexyz/recs";
import { BFS } from "../../../../../utils/pathfinding";
import { Sprites } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawAttackableEntitiesSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { RangedCombat, Untraversable },
      },
      local: {
        components: { AttackableEntities, LocalPosition, Selected, Path },
        api: { getOwnerColor },
      },
    },
    scenes: {
      Main: {
        objectPool,
        config,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
    api: { tintObject },
  } = layer;

  defineSystem(world, [Has(Selected), Has(RangedCombat), Has(LocalPosition), Not(Path)], ({ type, entity }) => {
    if (type === UpdateType.Exit) {
      for (let i = 0; i < 50; i++) {
        objectPool.remove(`attack-range-${entity}-${i}`);
      }
      return;
    }

    const position = getComponentValueStrict(LocalPosition, entity);
    const rangedCombat = getComponentValueStrict(RangedCombat, entity);
    const { minRange, maxRange } = rangedCombat;
    const [maxCoords] = BFS(
      position,
      maxRange,
      () => 1,
      (_isFinalPosition: boolean, position: Coord) => {
        const anyEntityAtPosition = runQuery([HasValue(LocalPosition, position)]);
        return anyEntityAtPosition.size === 0;
      }
    );
    const [minCoords] = BFS(
      position,
      minRange - 1,
      () => 1,
      (_isFinalPosition: boolean, position: Coord) => {
        const anyEntityAtPosition = runQuery([HasValue(LocalPosition, position)]);
        return anyEntityAtPosition.size === 0;
      }
    );

    const color = getOwnerColor(entity);

    const attackableCoords = maxCoords.filter((coord) => {
      const entitiesAtPosition = runQuery([HasValue(LocalPosition, coord), Has(Untraversable)]);
      if (entitiesAtPosition.size > 0) return false;

      return !minCoords.find((minCoord) => minCoord.x === coord.x && minCoord.y === coord.y);
    });

    for (let i = 0; i < attackableCoords.length; i++) {
      const coord = attackableCoords[i];
      const sprite = config.sprites[Sprites.CrossedSwords];
      const highlightObject = objectPool.get(`attack-range-${entity}-${i}`, "Sprite");
      highlightObject.setComponent({
        id: `attackable-highlight`,
        once: async (attackSprite) => {
          const pixelCoord = tileCoordToPixelCoord(coord, tileWidth, tileHeight);
          attackSprite.setTexture(sprite.assetKey, sprite.frame);
          attackSprite.setSize(tileWidth, tileHeight);
          attackSprite.setPosition(pixelCoord.x, pixelCoord.y);
          attackSprite.setAlpha(0.9);
          attackSprite.setDepth(9);
          tintObject(attackSprite, color);
        },
      });
    }
  });

  defineComponentSystem(world, AttackableEntities, ({ value }) => {
    const [attackableEntities, previousAttackableEntities] = value;

    if (previousAttackableEntities) {
      for (let i = 0; i < previousAttackableEntities.value.length; i++) {
        objectPool.remove(`${previousAttackableEntities.value[i] as EntityIndex}-attackable-highlight`);
      }
    }

    const color = 0xff0000;
    const sprite = config.sprites[Sprites.SelectUI];

    if (attackableEntities) {
      for (let i = 0; i < attackableEntities.value.length; i++) {
        const attackableHighlight = objectPool.get(`${attackableEntities.value[i]}-attackable-highlight`, "Sprite");
        const position = getComponentValue(LocalPosition, attackableEntities.value[i] as EntityIndex);
        if (!position) continue;

        attackableHighlight.setComponent({
          id: `attackable-highlight`,
          once: async (box) => {
            const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);
            box.setTexture(sprite.assetKey, sprite.frame);
            box.setSize(tileWidth, tileHeight);
            box.setPosition(pixelCoord.x, pixelCoord.y);
            box.setDepth(12);

            tintObject(box, color);
          },
        });
      }
    }
  });
}
