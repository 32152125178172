import { EntityID, getComponentValueStrict } from "@latticexyz/recs";
import { getPlayerEntity, isOwnedByCaller } from "@latticexyz/std-client";
import { rest } from "lodash";
import { filter, map } from "rxjs";
import { WorldCoord } from "../../../../../types";
import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { InputUtils } from "./createInputSystem";

export function registerClicks(layer: PhaserLayer, { getSelectedEntity, getHighlightedEntity }: InputUtils) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Player, OwnedBy, Portal },
        network: { connectedAddress },
      },
      headless: {
        api: {
          canTeleport,
          canEscapePortal,
          canGatherResource,
          canTakeInventory,
          canRangedAttack,
          canAttack,
          canGiveInventory,
          canCharge,
          canRest,

          move,
          escapePortal,
          gatherResource,
          transferInventory,
          attack,
          charge,
          rest,
        },
      },
      local: {
        api: { startTeleport },
      },
    },
    api: {
      mapInteraction: { mapInteractionEnabled },
    },
    scenes: {
      Main: { input, maps },
    },
  } = layer;

  const onRightClick = function (clickedPosition: WorldCoord) {
    const playerEntity = getPlayerEntity(connectedAddress.get(), world, Player);
    if (playerEntity == null) return;

    const selectedEntity = getSelectedEntity();
    if (selectedEntity == null) return;
    if (!isOwnedByCaller(OwnedBy, selectedEntity, playerEntity, world.entityToIndex)) return;

    const highlightedEntity = getHighlightedEntity();

    if (highlightedEntity != null) {
      if (canTeleport(selectedEntity, highlightedEntity)) {
        const portal = getComponentValueStrict(Portal, highlightedEntity);
        startTeleport(highlightedEntity, world.getEntityIndexStrict(portal.targetIds[0] as EntityID), selectedEntity);
        return;
      } else if (canEscapePortal(selectedEntity, highlightedEntity)) {
        escapePortal(selectedEntity, highlightedEntity);
        return;
      } else if (canGatherResource(highlightedEntity, selectedEntity)) {
        gatherResource(highlightedEntity, selectedEntity);
        return;
      } else if (canTakeInventory(highlightedEntity, selectedEntity)) {
        transferInventory(highlightedEntity, selectedEntity);
        return;
      } else if (canRangedAttack(selectedEntity, highlightedEntity)) {
        attack(selectedEntity, highlightedEntity);
        return;
      } else if (canAttack(selectedEntity, highlightedEntity)) {
        attack(selectedEntity, highlightedEntity);
        return;
      } else if (canCharge(selectedEntity, highlightedEntity)) {
        charge(selectedEntity, highlightedEntity);
        return;
      } else if (canRest(selectedEntity, highlightedEntity)) {
        rest(selectedEntity);
        return;
      }

      if (isOwnedByCaller(OwnedBy, highlightedEntity, playerEntity, world.entityToIndex)) {
        if (canGiveInventory(selectedEntity, highlightedEntity)) {
          transferInventory(selectedEntity, highlightedEntity);
          return;
        }
      }
    }

    move(selectedEntity, clickedPosition);
  };

  input.rightClick$
    .pipe(
      filter(() => mapInteractionEnabled()),
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })),
      map((pixel) => pixelToWorldCoord(maps.Main, pixel))
    )
    .subscribe((coord) => {
      onRightClick(coord);
    });
}
