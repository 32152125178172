import {
  defineComponentSystem,
  defineQuery,
  defineRxSystem,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  setComponent,
  UpdateType,
} from "@latticexyz/recs";
import { getCurrentTurn } from "@latticexyz/std-client";
import { HeadlessLayer } from "../../types";

export function createCurrentStaminaSystem(layer: HeadlessLayer) {
  const {
    world,
    turn$,
    parentLayers: {
      network: {
        network: { clock },
        components: { Stamina, StaminaRegenerationCap, LastAction, GameConfig, ChargedBy, ChargedByStartedAt },
        utils: { getTurnAtTime },
      },
    },
    components: { LocalStamina },
    actions: { withOptimisticUpdates },
  } = layer;

  const OptimisticStamina = withOptimisticUpdates(Stamina);

  const setLocalStaminaToCurrentTurn = (entity: EntityIndex) => {
    const currentTurn = getCurrentTurn(layer.world, GameConfig, clock);
    const contractStamina = getComponentValueStrict(Stamina, entity);
    const lastAction = getComponentValue(LastAction, entity);
    if (!lastAction) return;

    const lastActionAt = parseInt(lastAction.value, 16);
    const turnsSinceLastAction = currentTurn - getTurnAtTime(lastActionAt);
    let localStamina = turnsSinceLastAction * contractStamina.regeneration;

    if (hasComponent(ChargedBy, entity)) {
      const chargingStartedAt = parseInt(getComponentValueStrict(ChargedByStartedAt, entity).value, 16);
      let extraStaminaTurns: number;
      if (chargingStartedAt > lastActionAt) {
        extraStaminaTurns = currentTurn - getTurnAtTime(chargingStartedAt);
      } else {
        extraStaminaTurns = turnsSinceLastAction;
      }

      localStamina += extraStaminaTurns * 500;
    }

    if (hasComponent(StaminaRegenerationCap, entity)) {
      const staminaCap = getComponentValueStrict(StaminaRegenerationCap, entity);
      if (staminaCap.totalRegenerated + localStamina > staminaCap.cap) {
        localStamina = staminaCap.cap - staminaCap.totalRegenerated;
      }
    }

    if (contractStamina.current + localStamina > contractStamina.max)
      localStamina = contractStamina.max - contractStamina.current;
    if (localStamina < 0) localStamina = 0;

    setComponent(LocalStamina, entity, { current: localStamina });
  };

  defineComponentSystem(world, OptimisticStamina, ({ entity, value }) => {
    const [newValue] = value;
    const newCurrentStamina = newValue?.current;
    if (newCurrentStamina == null) return;

    setComponent(LocalStamina, entity, { current: 0 });
  });

  const staminaQuery = defineQuery([Has(Stamina), Has(LastAction)]);

  defineRxSystem(world, staminaQuery.update$, ({ entity, type }) => {
    if (type === UpdateType.Enter) setLocalStaminaToCurrentTurn(entity);
  });

  defineRxSystem(world, turn$, () => {
    for (const entity of staminaQuery.matching) {
      setLocalStaminaToCurrentTurn(entity);
    }
  });
}
