import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineRxSystem, EntityID, getComponentValue } from "@latticexyz/recs";
import { ActionState } from "@latticexyz/std-client";
import { Animations } from "../../phaserConstants";
import { PhaserLayer } from "../../types";

export function createDrawActingEntitySystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      headless: {
        actions: { Action },
      },
      local: {
        components: { LocalPosition },
      },
    },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  defineRxSystem(world, Action.update$, ({ value, entity: actionIndex }) => {
    if (!value || !value[0] || !value[0].on || !value[0].state) return;

    const actionId = world.entities[actionIndex];
    if (actionId.includes("move")) return;

    const state = value[0].state;

    const entity = world.entityToIndex.get(value[0].on as EntityID);
    if (entity == null) return;

    const position = getComponentValue(LocalPosition, entity);
    if (!position) return;

    const spriteId = `${entity}-action`;

    // Reset
    if (![ActionState.Requested, ActionState.Executing, ActionState.WaitingForTxEvents].includes(state)) {
      return objectPool.remove(spriteId);
    }

    const object = objectPool.get(spriteId, "Sprite");

    object.setComponent({
      id: "acting-entity",
      once: async (sprite) => {
        const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

        sprite.play(Animations.ChainCarousel);
        sprite.setPosition(pixelCoord.x, pixelCoord.y + tileHeight / 2);
        sprite.setDepth(36);
        sprite.setScale(0.5);
      },
    });
  });
}
