export const DEFAULT_MOVE_SPEED = 750;
export const FAST_MOVE_SPEED = 250;

/**
 * 0 index should only be used when the Player ID is unknown (0)
 */
export const PLAYER_COLORS = [
  0xffffff, 0x3cb44b, 0x4363d8, 0xffe119, 0xe6194b, 0x911eb4, 0xf58231, 0xaaffc3, 0x9a6324, 0x808000, 0x008080,
  0xf032e6, 0xbcf60c, 0xfabebe, 0x46f0f0, 0x800000, 0xe6beff, 0xfffac8, 0x000075, 0xffd8b1, 0x808080, 0x000000,
];
